import { ApplicationTypeProperty } from '@/api/models/ApplicationModel';
import { ActiveTabEnum } from '@/domains/pd-admin/types/administration/apps-management/app-details/app-management/app-management';
import { defineComponent, ref } from '@vue/composition-api';
import BaseText from '../../../../../../components/text/BaseText.vue';
import Packages from './control/packages/Packages.vue';
import Partnership from './control/partnership/Partnership.vue';
import Visibility from './control/visibility/Visibility.vue';

export default defineComponent({
  components: {
    BaseText,
    Packages,
    Partnership,
    Visibility,
  },
  props: {
    appID: {
      type: Number,
      required: true,
    },
    appType: {
      type: Number,
      required: true,
    },
    partnerID: {
      type: Number,
    },
  },
  setup(props) {
    const activeTab = ref(
      props.appType === ApplicationTypeProperty.Shipping ? ActiveTabEnum.Partnership : ActiveTabEnum.Packages,
    );
    const onActiveTabChange = (newActiveTab: ActiveTabEnum) => {
      activeTab.value = newActiveTab;
    };
    return {
      activeTab,
      ActiveTabEnum,
      ApplicationTypeProperty,
      onActiveTabChange,
    };
  },
});
