import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import ShippingAppUpdateResponseModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/ShippingAppUpdateResponseModel';
import { ShippingAppOAuthDetailsType } from '@/domains/pd-admin/types/administration/apps-management/app-details/shipping-app-details.type';

const ENDPOINT_URL = '/market/app/create-shippings';

export const updateShippingAppOAuthDetails = (
  oauthDetails: ShippingAppOAuthDetailsType,
): Promise<ShippingAppUpdateResponseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}`, oauthDetails)
    .then((response) => new ShippingAppUpdateResponseModel(response))
    .catch((error) => error);
};
