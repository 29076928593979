import { render, staticRenderFns } from "./ShippingOptions.vue?vue&type=template&id=542f45a0&"
import script from "./ShippingOptions.ts?vue&type=script&lang=js&"
export * from "./ShippingOptions.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports