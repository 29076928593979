import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import CoverageCountriesResponseModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/CoverageCountriesResponseModel';
import CoverageCitiesResponseModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/CoverageCitiesResponseModel';
const ENDPOINT_URL = 'partners/shipping/couriers/';

export const getCoverageCountries = (app_id: number): Promise<CoverageCountriesResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}${app_id}/available-coverage-countries`)
    .then((response) => new CoverageCountriesResponseModel(response))
    .catch((error) => error);
};

export const getCoverageCities = (
  app_id: number,
  cityType: string,
  countries: (number | null)[],
): Promise<CoverageCitiesResponseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}${app_id}/available-coverage-cities`, {
      cityType: cityType,
      countries: countries,
    })
    .then((response) => new CoverageCitiesResponseModel(response))
    .catch((error) => error);
};
