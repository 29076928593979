import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import AppWebhooksResponseModel from '@/domains/pd-admin/models/administration/apps-management/app-webhooks/app-webhooks-response-model';
import DeleteAppWebhookResponseModel from '@/domains/pd-admin/models/administration/apps-management/app-webhooks/delete-app-webhook-response-model';
import { WebhookGroup } from '@/domains/pd-admin/types/administration/apps-management/app-details/app-webhooks';

const ENDPOINT_URL = '/market';

export const getAppWebhooks = (appID: number): Promise<AppWebhooksResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/show/${appID}/webhook`)
    .then((response) => new AppWebhooksResponseModel(response))
    .catch((error) => error);
};

export const updateAppWebhook = (
  appID: number,
  webhookGroupInfo: WebhookGroup,
): Promise<AppWebhooksResponseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/update/multiple/${appID}/webhook`, webhookGroupInfo)
    .then((response) => new AppWebhooksResponseModel(response))
    .catch((error) => error);
};

export const deleteAppWebhook = (
  appID: number,
  webhookGroupID: string,
): Promise<DeleteAppWebhookResponseModel | ErrorModel> => {
  return httpClient
    .delete(`${ENDPOINT_URL}/delete/${appID}/webhook/group`, {
      data: {
        webhook: {
          group_id: webhookGroupID,
        },
      },
    })
    .then((response) => new DeleteAppWebhookResponseModel(response))
    .catch((error) => error);
};
