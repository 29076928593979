import Vue from 'vue';
import { defineComponent, ref, reactive } from '@vue/composition-api';
import VueEasymde from 'vue-easymde';
import {
  ZidBreadcrumb,
  ZidBreadcrumbItem,
  ZidInput,
  ZidTextarea,
  ZidButton,
  ZidFileSelector,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidLoader,
  ZidModal,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import router from '@/router/index';
import ErrorModel from '@/api/models/ErrorModel';
import { RoutingRouteEnum } from '@/router/routes.enum';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import DeleteIcon from '@/assets/icons/applications/DeleteIcon.vue';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import { ActiveTabEnum } from '../../../../../types/administration/apps-management/app-details/js-app-details.type';
import { descriptionMarkdownConfigs } from '../../../../../helpers/administration/apps-management/app-details/app-details-common';
import useJSAppDetails from '../../../../../helpers/administration/apps-management/app-details/js-app-details/details/useJSAppDetails';
import AppManagement from '../../app-management/AppManagement.vue';
import AppPlanModal from '../app-plans/AppPlanModal.vue';
import { getAppDetails } from '../../../../../api/administration/apps-management/app-details-js/app-details-js';
import { deletePlan } from '../../../../../api/administration/apps-management/app-plan/app-plan';
import {
  updateJSAppDetails,
  updateJSAppHeadscripts,
} from '../../../../../api/administration/apps-management/app-details-js/app-details-js';

export default defineComponent({
  components: {
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    BaseText,
    TabMultiLanguage,
    ZidInput,
    VueEasymde,
    ZidTextarea,
    ZidButton,
    ZidFileSelector,
    ZidSelect,
    ZidSelectBody,
    ZidSelectHeader,
    ZidSelectOption,
    ZidLoader,
    BaseIcon,
    EditIcon,
    DeleteIcon,
    AppManagement,
    AppPlanModal,
    ZidModal,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  setup(_, ctx) {
    const appID = Number(ctx.root.$route.params.id);
    const showPlanModal = ref(false);

    let planToEdit = reactive({ plan: null });
    let planToDelete = reactive({ plan: null });

    const activeTab = ref(ActiveTabEnum.AppDetails);

    const toggleShowPlanModal = () => (showPlanModal.value = !showPlanModal.value);

    const onActiveTabChange = (newActiveTab) => {
      activeTab.value = newActiveTab;
    };

    const {
      jsAppState,
      appLanguages,
      v$,
      appPlans,
      showMessageForArabicEnglishField,
      JSAppInputPropertiesEnum,
      JSAppSelectPropertiesEnum,
      appTypeProprety,
      appCategories,
      appCustomLocationScripts,
      showMessageForDescriptionArEnField,
      showMessageDeveloperNameField,
      areArabicFilesValid,
      isSaveDisabled,
      isInitialArabicScreenshotsLoading,
      isInitialEnglishScreenshotsLoading,
      isArScreenshotsInvalid,
      isEnScreenshotsInvalid,
      initialArabicScreenshots,
      initialEnglishScreenshots,
      isIconSpecsValid,
      isValidCoverPhoto,
      iconFileNameFromAPI,
      coverPhotoFileNameFromAPI,
      isSubmitScriptsDisabled,
      showDeletePlanModal,
      loadingDeletePlan,
      originalArabicScreenshots,
      originalEnglishScreenshots,
      headScripts,
      webhookSubscribtionList,
      onArabicScreenshotsChange,
      onArabicScreenshotsRemove,
      onEnglishScreenshotsChange,
      onEnglishScreenshotsRemove,
      planDuration,
      onInputDataChange,
      onSelectionDataChange,
      onDescriptionChange,
      onDescriptionArabicChange,
      onIconSelection,
      onCoverPhotoSelection,
      onRemoveIconClicked,
      onRemoveCoverPhotoClicked,
      toggleDeletePlanModal,
    } = useJSAppDetails(appID);

    const onSubmitAppInformation = async () => {
      const updateAppRes = await updateJSAppDetails(
        appID,
        jsAppState,
        originalArabicScreenshots,
        originalEnglishScreenshots,
      );
      if (updateAppRes.status !== 'success') {
        Vue.$toast.error('Could not update application information');
        return;
      }
      Vue.$toast.success('Application information have been updated');
    };

    const onSubmitScriptsInformation = async () => {
      const response = await updateJSAppHeadscripts(appID, jsAppState.customLocation.value, jsAppState.updateCode);
      if (response.status !== 'success') {
        Vue.$toast.error('Could not update application headscripts information');
        return;
      }
      Vue.$toast.success('Application headscripts information have been updated');

      const prevIndex = headScripts.list.findIndex((script) => {
        if (script.location === null) {
          return jsAppState.customLocation.value === '';
        }
        return script.location === jsAppState.customLocation.value;
      });

      if (prevIndex !== -1) {
        headScripts.list.splice(prevIndex, 1, {
          head_scripts_code: jsAppState.updateCode,
          location: jsAppState.customLocation.value === '' ? null : jsAppState.customLocation.value,
        });
      } else {
        headScripts.list.push({
          head_scripts_code: jsAppState.updateCode,
          location: jsAppState.customLocation.value === '' ? null : jsAppState.customLocation.value,
        });
      }
    };

    const onWebhookEditClicked = (subscription) => {
      router
        .push({
          name: RoutingRouteEnum.Admin_AppDetails_Webhooks,
          params: {
            appId: appID,
            groupId: subscription.group_id,
          },
        })
        .catch((er) => {
          //
        });
    };

    const onGoToWebhookManagementClicked = () => {
      router
        .push({
          name: RoutingRouteEnum.Admin_AppDetails_Webhooks,
          params: {
            appId: appID,
          },
        })
        .catch((er) => {
          //
        });
    };

    const onPlanDelete = (plan) => {
      planToDelete.plan = { ...plan };
      toggleDeletePlanModal();
    };

    const onPlanEdit = (plan) => {
      planToEdit.plan = { ...plan };
      toggleShowPlanModal();
    };

    const onClosePlanModal = () => {
      planToEdit.plan = null;
      showPlanModal.value = false;
    };

    const onPlanEdited = async () => {
      planToEdit.plan = null;
      showPlanModal.value = false;
      const appDetailsRes = await getAppDetails(appID);
      if (!appDetailsRes.payload) {
        Vue.$toast.error('Failed to load app plans');
      } else {
        appPlans.list = appDetailsRes.payload.plans ?? [];
      }
    };

    const onDeleteConfirmationClicked = async () => {
      loadingDeletePlan.value = true;

      const response = await deletePlan(planToDelete.plan.purchasable_id, appID);
      if (response instanceof ErrorModel || !response.deleted) {
        loadingDeletePlan.value = false;
        planToDelete.plan = null;
        Vue.$toast.error('Failed to delete plan');
        toggleDeletePlanModal();
        return;
      }

      const appDetailsRes = await getAppDetails(appID);
      if (!appDetailsRes.payload) {
        Vue.$toast.error('Failed to load app plans');
      } else {
        appPlans.list = appDetailsRes.payload.plans ?? [];
      }
      loadingDeletePlan.value = false;
      planToDelete.plan = null;
      Vue.$toast.success('Plan has been deleted');
      toggleDeletePlanModal();
    };

    return {
      appID,
      RoutingRouteEnum,
      activeTab,
      ActiveTabEnum,
      descriptionMarkdownConfigs,
      jsAppState,
      appLanguages,
      v$,
      appPlans,
      showMessageForArabicEnglishField,
      JSAppInputPropertiesEnum,
      JSAppSelectPropertiesEnum,
      appTypeProprety,
      appCategories,
      appCustomLocationScripts,
      showMessageForDescriptionArEnField,
      showMessageDeveloperNameField,
      areArabicFilesValid,
      isSaveDisabled,
      isInitialArabicScreenshotsLoading,
      isInitialEnglishScreenshotsLoading,
      isArScreenshotsInvalid,
      isEnScreenshotsInvalid,
      initialArabicScreenshots,
      initialEnglishScreenshots,
      isIconSpecsValid,
      isValidCoverPhoto,
      iconFileNameFromAPI,
      coverPhotoFileNameFromAPI,
      isSubmitScriptsDisabled,
      planToEdit,
      showPlanModal,
      showDeletePlanModal,
      loadingDeletePlan,
      webhookSubscribtionList,
      onArabicScreenshotsChange,
      onArabicScreenshotsRemove,
      onEnglishScreenshotsChange,
      onEnglishScreenshotsRemove,
      planDuration,
      onInputDataChange,
      onSelectionDataChange,
      onActiveTabChange,
      onDescriptionChange,
      onDescriptionArabicChange,
      onIconSelection,
      onCoverPhotoSelection,
      onRemoveIconClicked,
      onRemoveCoverPhotoClicked,
      onSubmitAppInformation,
      onSubmitScriptsInformation,
      onWebhookEditClicked,
      onPlanDelete,
      onPlanEdit,
      toggleShowPlanModal,
      onClosePlanModal,
      onPlanEdited,
      onDeleteConfirmationClicked,
      toggleDeletePlanModal,
      onGoToWebhookManagementClicked,
    };
  },
});
