import BaseModel from '@/api/models/BaseModel';
import ShippingApplicationModel from '@/api/models/ShippingApplicationModel';

class ShippingAppUpdateResponseModel extends BaseModel {
  public code: string;
  public payload: {
    app: ShippingApplicationModel;
    isAppInDevelopmentStore: boolean;
    development: string | null;
    hasCompletedPartnershipForm: boolean;
    mdLink: string;
  };

  constructor(arg: any) {
    super(arg);
    this.code = arg.code;
    this.payload = arg.payload;
  }
}

export default ShippingAppUpdateResponseModel;
