import { defineComponent, onBeforeMount } from '@vue/composition-api';
import router from '@/router';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import store from '@/store';
import ErrorModel from '@/api/models/ErrorModel';
import {
  ZidInput,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidLoader,
  ZidButton,
  ZidPagination,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import ExcelIcon from '@/assets/icons/domains/partnership-admin/ExcelIcon.vue';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import useAppsManagement from '../../../helpers/administration/apps-management/useAppsManagement';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { ApplicationListingStatusProperty, ApplicationTypeProperty } from '@/api/models/ApplicationModel';
import { getAllAdminApps } from '../../../api/administration/apps-management/apps-management';
import { ApplicationDataRowInterface } from '@/domains/pd-admin/types/administration/apps-management/apps-management.type';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ExcelIcon,
    SearchIcon,
    EditIcon,
    ZidLoader,
    ZidButton,
    ZidPagination,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  setup() {
    const {
      appsManagementState,
      sortingState,
      resultPerPage,
      v$,
      originalApps,
      filteredApps,
      displayedApps,
      appStatus,
      appType,
      currentFilterRange,
      isFilterApplied,
      isNextButtonDisabled,
      AppsManagementInputProperty,
      AppsManagementSelectProperties,
      SortingStateProperties,
      isLoading,
      onInputDataUpdate,
      onSelectionDataChange,
      onSort,
      onPreviousClick,
      onNextClick,
    } = useAppsManagement();

    onBeforeMount(async () => {
      isLoading.value = true;
      const response = await getAllAdminApps();
      if (response instanceof ErrorModel || !response.payload) {
        isLoading.value = false;
        store.dispatch('setIsAppLoading', false);
        return;
      }

      if (response.payload && response.payload instanceof Array) {
        originalApps.list = response.payload;
        const initialTableRecordCount = response.payload.length >= 10 ? 10 : response.payload.length;
        displayedApps.list = response.payload.slice(0, initialTableRecordCount);
        currentFilterRange.startIndex = 0;
        currentFilterRange.endIndex = initialTableRecordCount;
      }

      isLoading.value = false;
      store.dispatch('setIsAppLoading', false);
    });

    const extractApplicationStatus = (status: number) => {
      switch (status) {
        case ApplicationListingStatusProperty.Published:
          return 'published';
        case ApplicationListingStatusProperty.Unpublished:
          return 'unpublished';
        case ApplicationListingStatusProperty.Rejected:
          return 'rejected';
        case ApplicationListingStatusProperty.RequestToPublish:
          return 'in review';
        case null:
          return 'draft';
        default:
          return 'draft';
      }
    };

    const onEditClicked = (application: ApplicationDataRowInterface) => {
      if (application.type === ApplicationTypeProperty.Shipping) {
        router
          .push({
            name: RoutingRouteEnum.Admin_AppDetails_Shipping,
            params: {
              id: `${application.id}`,
            },
          })
          .catch((err) => {
            /**/
          });
      } else if (application.type === ApplicationTypeProperty.OAuth) {
        router
          .push({
            name: RoutingRouteEnum.Admin_AppDetails_OAuth,
            params: {
              id: `${application.id}`,
            },
          })
          .catch((err) => {
            /**/
          });
      } else if (application.type === ApplicationTypeProperty.Javascript) {
        router
          .push({
            name: RoutingRouteEnum.Admin_AppDetails_JS,
            params: {
              id: `${application.id}`,
            },
          })
          .catch((err) => {
            /**/
          });
      }
    };

    return {
      appsManagementState,
      sortingState,
      resultPerPage,
      v$,
      originalApps,
      filteredApps,
      displayedApps,
      appStatus,
      appType,
      currentFilterRange,
      isFilterApplied,
      isNextButtonDisabled,
      AppsManagementInputProperty,
      AppsManagementSelectProperties,
      ApplicationListingStatusProperty,
      SortingStateProperties,
      ApplicationTypeProperty,
      isLoading,
      onInputDataUpdate,
      onSelectionDataChange,
      onSort,
      onPreviousClick,
      onNextClick,
      extractApplicationStatus,
      onEditClicked,
    };
  },
});
