import Vue from 'vue';
import { reactive, ref, onBeforeMount, watch, computed, onMounted } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { required, helpers, minLength, minValue } from '@vuelidate/validators';
import { isNumberOrFloatingNumber } from '@/helpers/regex/numbers';
import {
  AppInformationToUpdateType,
  AppType,
  ContractStatusEnum,
  ControlPartnershipsSelectPropertiesEnum,
  ControlPartnershipState,
  EmptyValueEnum,
  InputPropertiesEnum,
  RevenueModelEnum,
  RevenueModelFieldsToShowEnum,
  RevenueModelValuesEnum,
  SlapDetails,
  SlapModelDetails,
  VATEnum,
  VATValuesEnum,
} from '@/domains/pd-admin/types/administration/apps-management/app-management/partnership';
import { REVENUE_MODEL, VAT } from './dropdownInfo';
import {
  getPartnershipDetails,
  updatePartnershipDetails,
} from '@/domains/pd-admin/api/administration/apps-management/app-management/partnership';

const usePartnershipControl = (partnerID: number, appID: number) => {
  const controlPartnershipState = reactive<ControlPartnershipState>({
    isContractSigned: false,
    contractFile: undefined,
    revenueModel: {
      label: '',
      value: '',
    },
    priceValue: '',
    activationFee: '',
    zidPercentage: '',
    partnerPercentage: '',
    slaps_details: [defaultSlapData()],
    vat: {
      label: '',
      value: '',
    },
    vatPercentage: '',
  });

  const rules = {
    isContractSigned: {},
    contractFile: {},
    revenueModel: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    priceValue: {
      priceValue: helpers.withMessage('Price is not valid', (value: string) => {
        return isNumberOrFloatingNumber(value);
      }),
    },
    activationFee: {
      activationFee: helpers.withMessage('Activation fee is not valid', (value: string) => {
        return isNumberOrFloatingNumber(value);
      }),
    },
    zidPercentage: {
      zidPercentage: helpers.withMessage('Percentage is not valid', (value: string) => {
        return isNumberOrFloatingNumber(value) && parseFloat(value) <= 100;
      }),
    },
    partnerPercentage: {
      partnerPercentage: helpers.withMessage('Percentage is not valid', (value: string) => {
        return isNumberOrFloatingNumber(value) && parseFloat(value) <= 100;
      }),
    },
    slaps_details: {
      minLength: minLength(1),
      $each: {
        price: {
          required,
          minValue: minValue(1),
        },
        orders: {
          required,
          minValue: minValue(1),
        },
      },
    },
    vat: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    vatPercentage: {
      vatPercentage: helpers.withMessage('Percentage is not valid', (value: string) => {
        return isNumberOrFloatingNumber(value) && parseFloat(value) <= 100;
      }),
    },
  };

  const revenueModels = reactive({
    options: REVENUE_MODEL,
  });

  const vatSelect = reactive({
    options: VAT,
  });

  const isLoadingData = ref(false);
  const areSlapsInvalid = ref(false);

  const isContractFileInvalid = ref(false);
  const contractFileLink = ref('');
  const selectedAppName = ref('');

  const revenueModelFieldsToShow = ref(RevenueModelFieldsToShowEnum.Empty);
  const showVATPercentageField = ref(false);

  const v$ = useVuelidate(rules, controlPartnershipState);

  onBeforeMount(async () => {
    await getDataFromApi();
  });

  watch(
    () => controlPartnershipState,
    (newPartnerDetailsState) => {
      switch (newPartnerDetailsState.revenueModel.label) {
        case RevenueModelEnum.FixedPrice:
          revenueModelFieldsToShow.value = RevenueModelFieldsToShowEnum.FixedPrice;
          if (newPartnerDetailsState.zidPercentage === '') controlPartnershipState.zidPercentage = EmptyValueEnum.Empty;
          if (newPartnerDetailsState.partnerPercentage === '')
            controlPartnershipState.partnerPercentage = EmptyValueEnum.Empty;
          break;
        case RevenueModelEnum.PercentageOfRevenue:
          revenueModelFieldsToShow.value = RevenueModelFieldsToShowEnum.PercentageOfRevenue;
          if (newPartnerDetailsState.priceValue === '') controlPartnershipState.priceValue = EmptyValueEnum.Empty;
          if (newPartnerDetailsState.activationFee === '') controlPartnershipState.activationFee = EmptyValueEnum.Empty;
          break;
        case RevenueModelEnum.Slap:
          revenueModelFieldsToShow.value = RevenueModelFieldsToShowEnum.Slap;
          break;

        default:
          revenueModelFieldsToShow.value = RevenueModelFieldsToShowEnum.Empty;
          break;
      }
      switch (newPartnerDetailsState.vat.label) {
        case VATEnum.Include:
          showVATPercentageField.value = true;
          break;
        case VATEnum.Exclude:
          controlPartnershipState.vatPercentage = EmptyValueEnum.Empty;
          showVATPercentageField.value = false;
          break;
        default:
          showVATPercentageField.value = false;
          break;
      }
    },
    { deep: true },
  );

  watch(
    () => controlPartnershipState.zidPercentage,
    (newZidPercentage) => {
      if (v$.value.zidPercentage.$dirty) {
        if (isNaN(parseFloat(newZidPercentage))) controlPartnershipState.zidPercentage = '';
        else if (100 - parseFloat(newZidPercentage) !== parseFloat(controlPartnershipState.partnerPercentage))
          controlPartnershipState.partnerPercentage = `${100 - parseFloat(newZidPercentage)}`;
      }
    },
    { deep: true },
  );

  watch(
    () => controlPartnershipState.partnerPercentage,
    (newPartnerPercentage) => {
      if (v$.value.partnerPercentage.$dirty) {
        if (isNaN(parseFloat(newPartnerPercentage))) controlPartnershipState.partnerPercentage = '';
        else if (100 - parseFloat(newPartnerPercentage) !== parseFloat(controlPartnershipState.zidPercentage))
          controlPartnershipState.zidPercentage = `${100 - parseFloat(newPartnerPercentage)}`;
      }
    },
    { deep: true },
  );

  watch(
    () => controlPartnershipState.isContractSigned,
    (newContractStatus) => {
      if (newContractStatus) {
        if (controlPartnershipState.contractFile || contractFileLink.value) isContractFileInvalid.value = false;
        else isContractFileInvalid.value = true;
      }
    },
    { deep: true },
  );

  const getDataFromApi = async () => {
    if (partnerID && partnerID != -1) {
      isLoadingData.value = true;
      const response = await getPartnershipDetails(partnerID, appID);

      if (!response.partner_contract) {
        isLoadingData.value = false;
        return;
      }
      if (response.partner_contract.apps && response.partner_contract.apps.length)
        updateContractSectionData(response.partner_contract.apps[0] ?? null);
      else updateContractSectionData(null);

      isLoadingData.value = false;
    }
  };

  const updateContractSectionData = (app: AppType | null) => {
    if (!app) return;

    if (app.contract_doc !== null) contractFileLink.value = app.contract_doc;
    else contractFileLink.value = '';

    controlPartnershipState.isContractSigned = app.contract_doc !== null;
    isContractFileInvalid.value = controlPartnershipState.isContractSigned && app.contract_doc === null;
    switch (app.pricing_model) {
      case Number(RevenueModelValuesEnum.FixedPrice):
        controlPartnershipState.revenueModel.label = RevenueModelEnum.FixedPrice;
        controlPartnershipState.revenueModel.value = RevenueModelValuesEnum.FixedPrice;
        controlPartnershipState.priceValue = app.fixed_price;
        controlPartnershipState.activationFee = app.activation_fees;
        controlPartnershipState.slaps_details = [defaultSlapData()];
        controlPartnershipState.zidPercentage = '0.00';
        controlPartnershipState.partnerPercentage = '0.00';
        break;
      case Number(RevenueModelValuesEnum.PercentageOfRevenue):
        controlPartnershipState.revenueModel.label = RevenueModelEnum.PercentageOfRevenue;
        controlPartnershipState.revenueModel.value = RevenueModelValuesEnum.PercentageOfRevenue;
        controlPartnershipState.zidPercentage = app.zid_percentage;
        controlPartnershipState.partnerPercentage = app.partner_percentage;
        controlPartnershipState.slaps_details = [defaultSlapData()];
        controlPartnershipState.priceValue = '0.00';
        controlPartnershipState.activationFee = '0.00';
        break;
      case Number(RevenueModelValuesEnum.Slap):
        controlPartnershipState.revenueModel.label = RevenueModelEnum.Slap;
        controlPartnershipState.revenueModel.value = RevenueModelValuesEnum.Slap;
        controlPartnershipState.slaps_details =
          app.slaps_details && app.slaps_details.length > 0
            ? JSON.parse(JSON.stringify(app.slaps_details))
            : [defaultSlapData()];
        controlPartnershipState.activationFee = app.activation_fees;
        controlPartnershipState.zidPercentage = '0.00';
        controlPartnershipState.partnerPercentage = '0.00';
        controlPartnershipState.priceValue = '0.00';
        break;
      default:
        controlPartnershipState.revenueModel.label = '';
        controlPartnershipState.revenueModel.value = '';
        break;
    }

    if (app.vat_percentage === EmptyValueEnum.Empty) {
      controlPartnershipState.vat.label = VATEnum.Exclude;
      controlPartnershipState.vat.value = VATValuesEnum.Exclude;
      controlPartnershipState.vatPercentage = EmptyValueEnum.Empty;
    } else {
      controlPartnershipState.vat.label = VATEnum.Include;
      controlPartnershipState.vat.value = VATValuesEnum.Include;
      controlPartnershipState.vatPercentage = app.vat_percentage;
    }
  };

  const onInputDataChange = (changedValue: Record<string, string>, dataProperty: InputPropertiesEnum) => {
    v$.value[dataProperty].$touch();
    controlPartnershipState[dataProperty] = changedValue.value;
  };

  const slapValidation = (changedValue: Record<string, string>, type: string, slapNumber: number) => {
    const slapObject = controlPartnershipState.slaps_details[slapNumber - 1];
    if (type === SlapModelDetails.Orders) {
      slapObject.orders = Number(changedValue.value) ?? 0;
    } else {
      slapObject.price = Number(changedValue.value) ?? 0;
    }
    if (changedValue == undefined || isNaN(Number(changedValue.value)) || Number(changedValue.value) <= 0) {
      areSlapsInvalid.value = true;
    } else {
      if (controlPartnershipState.slaps_details && controlPartnershipState.slaps_details.length > 0) {
        areSlapsInvalid.value =
          controlPartnershipState.slaps_details.filter((slap) => slap.price === 0 || slap.orders === 0).length > 0;
      } else {
        areSlapsInvalid.value = false;
      }
    }
  };

  const onSelectionDataChange = (
    changedValue: Record<string, string>,
    property: ControlPartnershipsSelectPropertiesEnum,
  ) => {
    v$.value[property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    controlPartnershipState[property].label = selectedValue.label;
    controlPartnershipState[property].value = selectedValue.value;
  };

  const onContractSignedCheckboxChange = (value: Record<string, boolean>) => {
    controlPartnershipState.isContractSigned = value.isChecked;
  };

  const onFileSelection = (fileInputEvent: Event, property: 'contractFile') => {
    const input = fileInputEvent.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }
    if (/\.(png|pdf)$/i.test(input.files[0].name.trim()) === false) {
      isContractFileInvalid.value = true;
    } else {
      controlPartnershipState[property] = input.files[0];
      isContractFileInvalid.value = false;
    }
  };

  const onRemoveFileClicked = (property: 'contractFile') => {
    controlPartnershipState[property] = undefined;
    isContractFileInvalid.value = true;
    (document.getElementById(property) as HTMLInputElement).value = '';
  };

  const disableSubmitUpdate = computed(() => {
    let hasInvalidValue = false;
    switch (controlPartnershipState.revenueModel.label) {
      case RevenueModelEnum.FixedPrice:
        if (controlPartnershipState.priceValue === '') {
          hasInvalidValue = true;
        }
        break;
      case RevenueModelEnum.PercentageOfRevenue:
        if (controlPartnershipState.zidPercentage === '' || controlPartnershipState.partnerPercentage === '') {
          hasInvalidValue = true;
        }
        break;
      case RevenueModelEnum.Slap:
        hasInvalidValue =
          controlPartnershipState.slaps_details.filter((slap) => slap.price <= 0 || slap.orders <= 0).length > 0;
        break;
      default:
        break;
    }
    switch (controlPartnershipState.vat.label) {
      case VATEnum.Include:
        if (controlPartnershipState.vatPercentage === '') {
          hasInvalidValue = true;
        }
        break;
      default:
        break;
    }

    if (controlPartnershipState.isContractSigned && isContractFileInvalid.value) {
      hasInvalidValue = true;
    }

    return hasInvalidValue;
  });

  function updateSlapsData() {
    if (controlPartnershipState.revenueModel.value === RevenueModelValuesEnum.Slap) {
      const slapData: SlapDetails[] = [];
      controlPartnershipState.slaps_details.map((slap, index) => {
        slapData.push(getSlapData(SlapModelDetails.Orders, slap.orders, slap, index));
        slapData.push(getSlapData(SlapModelDetails.Price, slap.price, slap, index));
      });
      return slapData;
    }
  }

  function getSlapData(key: string, value: number, slap: SlapDetails, index: number) {
    const slabObject = {} as SlapDetails;
    slabObject.app_id = appID;
    slabObject.slap_number = index + 1;
    slabObject.slap_key = key;
    slabObject.slap_value = value.toString();
    return slabObject;
  }

  const onUpdatePartnershipData = async (isDisabled: boolean) => {
    // Temporarily disabled feature until refactoring it!!
    if (isDisabled) {
      Vue.$toast.error('This Feature is currently disabled!');
      return;
    }
    const appInfo: AppInformationToUpdateType = {
      app_id: `${appID}`,
      contract_doc: controlPartnershipState.isContractSigned ? controlPartnershipState.contractFile : undefined,
      contract_status: controlPartnershipState.contractFile ? ContractStatusEnum.Signed : ContractStatusEnum.Unsigned,
      pricing_model: controlPartnershipState.revenueModel.value,
      fixed_price: controlPartnershipState.priceValue,
      activation_fees: controlPartnershipState.activationFee,
      zid_percentage: controlPartnershipState.zidPercentage,
      partner_percentage: controlPartnershipState.partnerPercentage,
      vat_percentage:
        controlPartnershipState.vat.label === VATEnum.Include ? controlPartnershipState.vatPercentage : '0.00',
      slaps_details: updateSlapsData(),
    };
    const response = await updatePartnershipDetails(appInfo);

    if (response.status === 'success') {
      Vue.$toast.success('Updated partnership information successfully');
      resetEntry();
      getDataFromApi();
    } else {
      Vue.$toast.error('Error updating partnership information');
    }
  };
  const onCancelClicked = () => {
    resetEntry();
  };

  const onAddSlapDetails = () => {
    if (controlPartnershipState.slaps_details && controlPartnershipState.slaps_details.length < 6) {
      controlPartnershipState.slaps_details?.push({
        app_id: appID,
        slap_number: controlPartnershipState.slaps_details.length + 1,
        orders: 0,
        price: 0,
      });
    } else {
      Vue.$toast.error('Maximum of 6 slaps are allowed');
    }
  };

  const onDeleteSlapDetails = (slapNumber: number) => {
    if (controlPartnershipState.slaps_details && controlPartnershipState.slaps_details.length > 1) {
      controlPartnershipState.slaps_details?.splice(slapNumber - 1, 1);
    } else {
      Vue.$toast.error('Minimum of 1 slap is required');
    }
  };

  function defaultSlapData() {
    const slabObject = {} as SlapDetails;
    slabObject.app_id = -1;
    slabObject.slap_number = 1;
    slabObject.orders = 0;
    slabObject.price = 0;
    return slabObject;
  }

  const resetEntry = () => {
    controlPartnershipState.isContractSigned = false;
    controlPartnershipState.contractFile = undefined;
    controlPartnershipState.revenueModel = {
      label: '',
      value: '',
    };
    controlPartnershipState.priceValue = '';
    controlPartnershipState.activationFee = '';
    controlPartnershipState.zidPercentage = '';
    controlPartnershipState.partnerPercentage = '';
    controlPartnershipState.slaps_details = [defaultSlapData()];
    controlPartnershipState.vat = {
      label: '',
      value: '',
    };
    controlPartnershipState.vatPercentage = '';
  };

  return {
    isLoadingData,
    controlPartnershipState,
    v$,
    isContractFileInvalid,
    selectedAppName,
    contractFileLink,
    revenueModels,
    vatSelect,
    revenueModelFieldsToShow,
    RevenueModelFieldsToShowEnum,
    showVATPercentageField,
    disableSubmitUpdate,
    areSlapsInvalid,
    SlapModelDetails,
    onSelectionDataChange,
    onContractSignedCheckboxChange,
    onFileSelection,
    onInputDataChange,
    onRemoveFileClicked,
    onUpdatePartnershipData,
    onCancelClicked,
    onAddSlapDetails,
    onDeleteSlapDetails,
    slapValidation,
  };
};

export default usePartnershipControl;
