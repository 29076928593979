import BaseModel from '@/api/models/BaseModel';
import { WebhookResponse } from '@/domains/pd-admin/types/administration/apps-management/app-details/oauth-app-details.type';

class DeleteAppWebhookResponseModel extends BaseModel {
  public deleted: Record<string, WebhookResponse[]>;

  constructor(arg: any) {
    super(arg);
    this.deleted = arg.deleted;
  }
}

export default DeleteAppWebhookResponseModel;
