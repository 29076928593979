import Vue from 'vue';
import { defineComponent, reactive, ref } from '@vue/composition-api';
import VueEasymde from 'vue-easymde';
import {
  ZidBreadcrumb,
  ZidBreadcrumbItem,
  ZidInput,
  ZidTextarea,
  ZidButton,
  ZidFileSelector,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidLoader,
  ZidModal,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
  ZidSwitch,
} from '@zidsa/ui';
import { RoutingRouteEnum } from '@/router/routes.enum';
import BaseText from '@/components/text/BaseText.vue';
import router from '@/router/index';
import ErrorModel from '@/api/models/ErrorModel';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import DeleteIcon from '@/assets/icons/applications/DeleteIcon.vue';
import AppScope from '@/domains/applications/components/create-app/AppScope.vue';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import { ActiveTabEnum } from '../../../../../types/administration/apps-management/app-details/js-app-details.type';
import { descriptionMarkdownConfigs } from '../../../../../helpers/administration/apps-management/app-details/app-details-common';
import useOAuthAppDetails from '../../../../../helpers/administration/apps-management/app-details/oauth-app-details/details/useOAuthAppDetails';
import AppManagement from '../../app-management/AppManagement.vue';
import AppPlanModal from '../app-plans/AppPlanModal.vue';
import { getAppDetails } from '../../../../../api/administration/apps-management/app-details-js/app-details-js';
import {
  updateOAuthAppDetails,
  updateScopesInformation,
} from '../../../../../api/administration/apps-management/app-details-oauth/app-details-oauth';
import { deletePlan } from '../../../../../api/administration/apps-management/app-plan/app-plan';
import { API_DOCUMENTATION_REDIRECT_URL, API_DOCUMENTATION_CALLBACK_URL } from '@/helpers/constantLinks';

export default defineComponent({
  components: {
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    BaseText,
    TabMultiLanguage,
    ZidInput,
    VueEasymde,
    ZidTextarea,
    ZidButton,
    ZidFileSelector,
    ZidSelect,
    ZidSelectBody,
    ZidSelectHeader,
    ZidSelectOption,
    ZidLoader,
    BaseIcon,
    EditIcon,
    DeleteIcon,
    AppScope,
    AppManagement,
    AppPlanModal,
    ZidModal,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
    ZidSwitch,
  },
  setup(_, ctx) {
    const appID = Number(ctx.root.$route.params.id);
    const activeTab = ref(ActiveTabEnum.AppDetails);
    const showPlanModal = ref(false);

    let planToEdit = reactive({ plan: null });
    let planToDelete = reactive({ plan: null });

    const onActiveTabChange = (newActiveTab) => {
      activeTab.value = newActiveTab;
    };

    const toggleShowPlanModal = () => (showPlanModal.value = !showPlanModal.value);

    const {
      oauthAppState,
      appLanguages,
      v$,
      appPlans,
      showMessageForArabicEnglishField,
      OAuthAppInputPropertiesEnum,
      OAuthSelectPropertiesEnum,
      appTypeProprety,
      appCategories,
      showMessageForDescriptionArEnField,
      showMessageDeveloperNameField,
      isSaveDisabled,
      isInitialArabicScreenshotsLoading,
      isInitialEnglishScreenshotsLoading,
      isArScreenshotsInvalid,
      isEnScreenshotsInvalid,
      initialArabicScreenshots,
      initialEnglishScreenshots,
      originalArabicScreenshots,
      originalEnglishScreenshots,
      isIconSpecsValid,
      isValidCoverPhoto,
      iconFileNameFromAPI,
      coverPhotoFileNameFromAPI,
      isSubmitScopesInfoDisabled,
      appScopesList,
      selectedScopes,
      showDeletePlanModal,
      loadingDeletePlan,
      areArabicFilesValid,
      webhookSubscribtionList,
      appPartnerID,
      onArabicScreenshotsChange,
      onArabicScreenshotsRemove,
      onEnglishScreenshotsChange,
      onEnglishScreenshotsRemove,
      planDuration,
      onInputDataChange,
      onSelectionDataChange,
      onDescriptionChange,
      onDescriptionArabicChange,
      onIconSelection,
      onCoverPhotoSelection,
      onRemoveIconClicked,
      onRemoveCoverPhotoClicked,
      onReadRadioButtonSelected,
      onReadRadioDoubleSelect,
      onReadWriteRadioButtonSelected,
      onReadWriteRadioDoubleSelect,
      toggleDeletePlanModal,
      toggleIsEmbeddedOption,
    } = useOAuthAppDetails(appID);

    const onSubmitAppInformation = async () => {
      const updateAppRes = await updateOAuthAppDetails(
        appID,
        oauthAppState,
        originalArabicScreenshots,
        originalEnglishScreenshots,
      );
      if (updateAppRes.status !== 'success') {
        Vue.$toast.error('Could not update application information');
      }
      Vue.$toast.success('Application information have been updated');
    };

    const onSubmitScopesInformation = async () => {
      const res = await updateScopesInformation(appID, {
        scopes: selectedScopes.list,
        scopes_note: oauthAppState.scopesDescription,
      });
      if (res.status !== 'success') {
        Vue.$toast.error('Could not update application scopes information');
      }
      Vue.$toast.success('Application scopes information have been updated');
    };

    const onWebhookEditClicked = (subscription) => {
      router
        .push({
          name: RoutingRouteEnum.Admin_AppDetails_Webhooks,
          params: {
            appId: appID,
            groupId: subscription.group_id,
          },
        })
        .catch((er) => {
          //
        });
    };

    const onGoToWebhookManagementClicked = () => {
      router
        .push({
          name: RoutingRouteEnum.Admin_AppDetails_Webhooks,
          params: {
            appId: appID,
          },
        })
        .catch((er) => {
          //
        });
    };

    const onPlanDelete = (plan) => {
      planToDelete.plan = { ...plan };
      toggleDeletePlanModal();
    };

    const onPlanEdit = (plan) => {
      planToEdit.plan = { ...plan };
      toggleShowPlanModal();
    };

    const onClosePlanModal = () => {
      planToEdit.plan = null;
      showPlanModal.value = false;
    };

    const onPlanEdited = async () => {
      planToEdit.plan = null;
      showPlanModal.value = false;
      const appDetailsRes = await getAppDetails(appID);
      if (!appDetailsRes.payload) {
        Vue.$toast.error('Failed to load app plans');
      } else {
        appPlans.list = appDetailsRes.payload.plans ?? [];
      }
    };

    const onDeleteConfirmationClicked = async () => {
      loadingDeletePlan.value = true;

      const response = await deletePlan(planToDelete.plan.purchasable_id, appID);
      if (response instanceof ErrorModel || !response.deleted) {
        loadingDeletePlan.value = false;
        planToDelete.plan = null;
        Vue.$toast.error('Failed to delete plan');
        toggleDeletePlanModal();
        return;
      }

      const appDetailsRes = await getAppDetails(appID);
      if (!appDetailsRes.payload) {
        Vue.$toast.error('Failed to load app plans');
      } else {
        appPlans.list = appDetailsRes.payload.plans ?? [];
      }
      loadingDeletePlan.value = false;
      planToDelete.plan = null;
      Vue.$toast.success('Plan has been deleted');
      toggleDeletePlanModal();
    };

    return {
      appID,
      RoutingRouteEnum,
      activeTab,
      ActiveTabEnum,
      descriptionMarkdownConfigs,
      oauthAppState,
      appLanguages,
      v$,
      appPlans,
      showMessageForArabicEnglishField,
      OAuthAppInputPropertiesEnum,
      OAuthSelectPropertiesEnum,
      appTypeProprety,
      appCategories,
      showMessageForDescriptionArEnField,
      showMessageDeveloperNameField,
      isSaveDisabled,
      isInitialArabicScreenshotsLoading,
      isInitialEnglishScreenshotsLoading,
      isArScreenshotsInvalid,
      isEnScreenshotsInvalid,
      initialArabicScreenshots,
      initialEnglishScreenshots,
      isIconSpecsValid,
      isValidCoverPhoto,
      iconFileNameFromAPI,
      coverPhotoFileNameFromAPI,
      isSubmitScopesInfoDisabled,
      appScopesList,
      selectedScopes,
      planToEdit,
      showPlanModal,
      showDeletePlanModal,
      loadingDeletePlan,
      areArabicFilesValid,
      webhookSubscribtionList,
      appPartnerID,
      API_DOCUMENTATION_REDIRECT_URL,
      API_DOCUMENTATION_CALLBACK_URL,
      onActiveTabChange,
      onSubmitAppInformation,
      onWebhookEditClicked,
      onPlanDelete,
      onPlanEdit,
      onArabicScreenshotsChange,
      onArabicScreenshotsRemove,
      onEnglishScreenshotsChange,
      onEnglishScreenshotsRemove,
      planDuration,
      onInputDataChange,
      onSelectionDataChange,
      onDescriptionChange,
      onDescriptionArabicChange,
      onIconSelection,
      onCoverPhotoSelection,
      onRemoveIconClicked,
      onRemoveCoverPhotoClicked,
      onReadRadioButtonSelected,
      onReadRadioDoubleSelect,
      onReadWriteRadioButtonSelected,
      onReadWriteRadioDoubleSelect,
      onSubmitScopesInformation,
      toggleShowPlanModal,
      onClosePlanModal,
      onPlanEdited,
      toggleDeletePlanModal,
      onDeleteConfirmationClicked,
      onGoToWebhookManagementClicked,
      toggleIsEmbeddedOption,
    };
  },
});
