import Vue from 'vue';
import { reactive, ref, onBeforeUnmount, onBeforeMount } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import {
  SubscribtionType,
  WebhookGroup,
  WebhookResponse,
  WebhookType,
} from '@/domains/pd-admin/types/administration/apps-management/app-details/app-webhooks';
import { URL_REGEX } from '@/helpers/regex/URLs';
import {
  deleteAppWebhook,
  getAppWebhooks,
  updateAppWebhook,
} from '@/domains/pd-admin/api/administration/apps-management/app-webhooks/app-webhooks';
import { getAppDetails } from '@/domains/pd-admin/api/administration/apps-management/app-details-js/app-details-js';
import ApplicationModel from '@/api/models/ApplicationModel';
import ErrorModel from '@/api/models/ErrorModel';

const useAppWebhooks = (appID: number, preSelectedGroupID: string) => {
  const isLoading = ref(false);
  const showAddWebhook = ref(false);
  const loadingAddOrEditWebhook = ref(false);
  const showDeleteWebhookModal = ref(false);
  const isDeletingWebhookGroup = ref(false);
  const selectedWebhookGroupID = ref('');
  const applicationId: number | null = appID;
  const application: Record<string, ApplicationModel | null> = reactive({ data: null });
  const webhooksOptions: Record<string, string[]> = reactive({ options: [] });
  let existingWebhookGroups: Record<string, WebhookResponse[]> | null = null;
  const createAppWebhooksState = reactive<SubscribtionType>({
    id: -1,
    webhookSelection: [],
    targetURL: '',
    headers: '',
  });

  const webhookSubscribtionList: Record<string, WebhookGroup[]> = reactive({ subscirbtionList: [] });

  const isValidJsonString = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const rules = {
    webhookSelection: {
      required,
      webhookSelection: helpers.withMessage('Please select application webhooks', required),
    },
    targetURL: {
      required,
      targetURL: helpers.withMessage('URL is not valid', (value: string) => URL_REGEX.test(value)),
    },
    headers: {
      headers: helpers.withMessage(
        'JSON format is not valid',
        (value: string) => isValidJsonString(value) || value === '',
      ),
    },
  };

  const v$ = useVuelidate(rules, createAppWebhooksState);

  onBeforeMount(async () => {
    isLoading.value = true;

    const webhooksRes = await getAppWebhooks(appID);
    if (webhooksRes instanceof ErrorModel) {
      Vue.$toast.error('Could not load application webhooks');
      return;
    }
    existingWebhookGroups = webhooksRes.webhooks ?? null;
    if (existingWebhookGroups) {
      webhookSubscribtionList.subscirbtionList = [];
      updateCurrentSupscriptionList();
    }
    if (webhooksRes.webhooksAvailable) webhooksOptions.options = webhooksRes.webhooksAvailable;

    const appRes = await getAppDetails(appID);
    if (!(appRes instanceof ErrorModel) && appRes.payload) application.data = appRes.payload.app;

    isLoading.value = false;

    if (preSelectedGroupID) {
      onWebhookGroupEdit(preSelectedGroupID);
    }
  });

  const toggleAddwebhook = () => (showAddWebhook.value = !showAddWebhook.value);
  const toggleDeleteWebhookModal = () => (showDeleteWebhookModal.value = !showDeleteWebhookModal.value);

  const updateCurrentSupscriptionList = () => {
    if (!existingWebhookGroups) return;
    for (const [key, value] of Object.entries(existingWebhookGroups)) {
      webhookSubscribtionList.subscirbtionList.push({
        group_id: key,
        webhooks: value,
      });
    }
  };

  if (existingWebhookGroups) {
    webhookSubscribtionList.subscirbtionList = [];
    updateCurrentSupscriptionList();
  }

  const onWebhookGroupDelete = async (webhookGroupID: string) => {
    selectedWebhookGroupID.value = webhookGroupID;
    showDeleteWebhookModal.value = true;
  };

  const onDeleteConfirmationClicked = async () => {
    if (!applicationId || !selectedWebhookGroupID) {
      return;
    }
    isDeletingWebhookGroup.value = true;
    const response = await deleteAppWebhook(applicationId, selectedWebhookGroupID.value);
    if (response instanceof ErrorModel || !response.deleted) {
      isDeletingWebhookGroup.value = false;
      Vue.$toast.error('Failed to delete webhook group');
      toggleDeleteWebhookModal();
      return;
    }
    const deletedWebhookOptionsList = response.deleted[Object.keys(response.deleted)[0]].map(
      (deletedWebhook: WebhookResponse) => deletedWebhook.name,
    );
    if (existingWebhookGroups) delete existingWebhookGroups[Object.keys(response.deleted)[0]];

    const deletedSubscriptionIndex = webhookSubscribtionList.subscirbtionList.findIndex(
      (sub) => sub.group_id === Object.keys(response.deleted)[0],
    );
    if (deletedSubscriptionIndex !== -1) webhookSubscribtionList.subscirbtionList.splice(deletedSubscriptionIndex, 1);
    webhooksOptions.options = [...webhooksOptions.options, ...deletedWebhookOptionsList];
    isDeletingWebhookGroup.value = false;
    selectedWebhookGroupID.value = '';
    Vue.$toast.success('Webhook group has been deleted');
    toggleDeleteWebhookModal();
  };

  const onSubscribeWebhookClicked = () => {
    if (!showAddWebhook.value) toggleAddwebhook();
  };

  const prepareWebhookGroupData = () => {
    const webhooks: WebhookType[] = [];
    let webhookGroupID = null;
    let selectedWebhookGroup: WebhookGroup | null = null;
    if (selectedWebhookGroupID.value) {
      webhookGroupID = selectedWebhookGroupID.value;
      selectedWebhookGroup =
        webhookSubscribtionList.subscirbtionList.find(
          (webhhook) => webhhook.group_id === selectedWebhookGroupID.value,
        ) || null;
    }
    createAppWebhooksState.webhookSelection.forEach((selection) => {
      webhooks.push({
        id: selectedWebhookGroup?.webhooks.find((webhook: WebhookType) => webhook.name === selection)?.id || null,
        name: selection,
        target_url: createAppWebhooksState.targetURL,
        headers: createAppWebhooksState.headers ? JSON.parse(createAppWebhooksState.headers) : null,
      });
    });

    const newWebhookGroup: WebhookGroup = {
      group_id: webhookGroupID,
      webhooks,
    };
    return newWebhookGroup;
  };

  const onSaveOrEditClicked = async () => {
    if (applicationId) {
      loadingAddOrEditWebhook.value = true;
      const newWebhookGroup = prepareWebhookGroupData();
      const response = await updateAppWebhook(applicationId, newWebhookGroup);
      if (response instanceof ErrorModel || !response.webhooks) {
        loadingAddOrEditWebhook.value = false;
        if (selectedWebhookGroupID.value !== '') Vue.$toast.error('Failed to update webhook group');
        else Vue.$toast.error('Failed to Add new webhook group');
        return;
      }

      existingWebhookGroups = response.webhooks;
      if (existingWebhookGroups) {
        webhookSubscribtionList.subscirbtionList = [];
        updateCurrentSupscriptionList();
      }
      if (selectedWebhookGroupID.value !== '') Vue.$toast.success('Updated webhook group successfully');
      else Vue.$toast.success('Added new webhook group successfully');
    }
    loadingAddOrEditWebhook.value = false;
    selectedWebhookGroupID.value = '';
    createAppWebhooksState.webhookSelection = [];
    createAppWebhooksState.targetURL = '';
    createAppWebhooksState.headers = '';
    v$.value.$reset();
    toggleAddwebhook();
  };

  const onCancelClicked = async () => {
    createAppWebhooksState.webhookSelection = [];
    createAppWebhooksState.targetURL = '';
    createAppWebhooksState.headers = '';
    const webhooksRes = await getAppWebhooks(appID);
    if (webhooksRes instanceof ErrorModel) {
      toggleAddwebhook();
      v$.value.$reset();
      return;
    }
    if (webhooksRes.webhooksAvailable) webhooksOptions.options = webhooksRes.webhooksAvailable;
    v$.value.$reset();
    toggleAddwebhook();
  };

  const onWebhookGroupEdit = (webhookGroupID: string) => {
    if (!showAddWebhook.value) toggleAddwebhook();
    if (webhookGroupID !== selectedWebhookGroupID.value) {
      selectedWebhookGroupID.value = webhookGroupID;
      createAppWebhooksState.webhookSelection = [];
      createAppWebhooksState.targetURL = '';
      createAppWebhooksState.headers = '';
      v$.value.$reset();
    }
    const webhooks = webhookSubscribtionList.subscirbtionList.find(
      (webhookGroup: WebhookGroup) => webhookGroup.group_id === webhookGroupID,
    )?.webhooks;
    if (webhooks?.length) {
      createAppWebhooksState.webhookSelection = webhooks?.map((webhook: WebhookType) => webhook.name);
      createAppWebhooksState.targetURL = webhooks[0].target_url;
      createAppWebhooksState.headers = webhooks[0].headers ?? '{}';
    }
  };

  const onInputDataChange = (changedValue: Record<string, string>, dataProperty: 'targetURL' | 'headers') => {
    v$.value[dataProperty].$touch();
    createAppWebhooksState[dataProperty] = changedValue.value;
  };

  const updateWebhookSelectionList = (selectedList: string[]) => {
    createAppWebhooksState.webhookSelection = [...selectedList];
    if (webhooksOptions.options.length) {
      webhooksOptions.options = webhooksOptions.options.filter(
        (webhook) => !createAppWebhooksState.webhookSelection.includes(webhook),
      );
    }
  };

  const onWebhookSelectionRemoved = (removedWebhook: string) => {
    const newAllowedWebhooksAfterRemoving = [...webhooksOptions.options, removedWebhook];
    webhooksOptions.options = [...newAllowedWebhooksAfterRemoving];
  };

  onBeforeUnmount(() => {
    if (selectedWebhookGroupID.value !== '' || !createAppWebhooksState.webhookSelection.length) return;
    const newAllowedWebhooksAfterRemoving = [...webhooksOptions.options, ...createAppWebhooksState.webhookSelection];
    webhooksOptions.options = [...newAllowedWebhooksAfterRemoving];
  });

  return {
    isLoading,
    createAppWebhooksState,
    webhooksOptions,
    webhookSubscribtionList,
    showAddWebhook,
    loadingAddOrEditWebhook,
    showDeleteWebhookModal,
    isDeletingWebhookGroup,
    selectedWebhookGroupID,
    application,
    v$,
    onInputDataChange,
    onWebhookGroupDelete,
    onSubscribeWebhookClicked,
    onSaveOrEditClicked,
    onCancelClicked,
    onWebhookGroupEdit,
    toggleDeleteWebhookModal,
    onDeleteConfirmationClicked,
    updateWebhookSelectionList,
    onWebhookSelectionRemoved,
  };
};

export default useAppWebhooks;
