import httpClient from '@/api/httpClient';
import BaseModel from '@/api/models/BaseModel';
import ErrorModel from '@/api/models/ErrorModel';

const ENDPOINT_URL = '/partners/shipping/courier';
export const deleteSingleShippingCustomization = (
  appId: number,
  customizationId: number,
): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .delete(`${ENDPOINT_URL}/${appId}/customizations/${customizationId}`)
    .then((response) => response)
    .catch((error) => error);
};
