import { reactive, watch, ref, computed } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import {
  AppsManagementState,
  SortingStateType,
  AppsManagementInputProperty,
  AppsManagementSelectProperties,
  SortingStateProperties,
  ApplicationDataRowInterface,
  AppStatusDropdownValuesEnum,
  AppTypeDropdownValuesEnum,
} from '../../../types/administration/apps-management/apps-management.type';
import { APPLICATION_STATUS, APPLICATION_TYPE } from './dropdownInfo';
import { InputKeyUpKeysEnum } from '@/types/components/input/inputTypes';
import { filterAdminApps } from '../../../api/administration/apps-management/apps-management';
import ErrorModel from '@/api/models/ErrorModel';

const useAppsManagement = () => {
  const appsManagementState = reactive<AppsManagementState>({
    selectedResultPerPage: {
      label: '10',
      value: '10',
    },
    searchValue: '',
    appStatus: {
      label: '',
      value: AppStatusDropdownValuesEnum.AllStatus,
    },
    appType: {
      label: '',
      value: AppTypeDropdownValuesEnum.AllTypes,
    },
  });

  const rules = {
    selectedResultPerPage: {
      label: {},
      value: {},
    },
    searchValue: {},
    appStatus: {
      label: {},
      value: {},
    },
    appType: {
      label: {},
      value: {},
    },
  };

  const RESULT_PER_PAGE = [];

  for (let i = 10; i <= 100; i += 10) {
    RESULT_PER_PAGE.push({
      label: `${i}`,
      value: `${i}`,
    });
  }

  const resultPerPage = reactive({
    options: RESULT_PER_PAGE,
  });

  const appStatus = reactive({
    options: APPLICATION_STATUS,
  });

  const appType = reactive({
    options: APPLICATION_TYPE,
  });

  const sortingState = reactive<SortingStateType>({
    installs: null,
    rating: null,
  });

  const originalApps = reactive<Record<string, ApplicationDataRowInterface[]>>({
    list: [],
  });

  const filteredApps = reactive<Record<string, ApplicationDataRowInterface[]>>({
    list: [],
  });

  const displayedApps = reactive<Record<string, ApplicationDataRowInterface[]>>({
    list: [],
  });

  const currentFilterRange = reactive<any>({
    startIndex: -1,
    endIndex: -1,
  });

  const isFilterByAppStatus = ref(false);

  const isFilterByAppType = ref(false);

  const isFilterBySearch = ref(false);

  const isFilterApplied = ref(false);

  const isLoading = ref(false);

  const v$ = useVuelidate(rules, appsManagementState);

  const onInputDataUpdate = (keyUp: KeyboardEvent, dataProperty: AppsManagementInputProperty) => {
    const target = keyUp.target as HTMLTextAreaElement;
    v$.value[dataProperty].$touch();
    appsManagementState[dataProperty] = target.value;
    if (keyUp.key === InputKeyUpKeysEnum.Enter) {
      fetchResultsBySearch(target.value);
    }
  };

  const fetchResultsBySearch = async (searchValue: string) => {
    isLoading.value = true;
    const selectedAppStatus =
      appsManagementState.appStatus.value === AppStatusDropdownValuesEnum.AllStatus
        ? null
        : appsManagementState.appStatus.value;

    const selectedAppType =
      appsManagementState.appType.value === AppTypeDropdownValuesEnum.AllTypes
        ? null
        : appsManagementState.appType.value;

    const params = {
      appStatus: selectedAppStatus,
      appType: selectedAppType,
      searchValue: searchValue,
    };

    const filterRes = await filterAdminApps(params);
    if (filterRes instanceof ErrorModel || !filterRes.payload) {
      isLoading.value = false;
      return;
    }
    originalApps.list = filterRes.payload;
    const initialTableRecordCount = filterRes.payload.length >= 10 ? 10 : filterRes.payload.length;
    displayedApps.list = filterRes.payload.slice(0, initialTableRecordCount);
    currentFilterRange.startIndex = 0;
    currentFilterRange.endIndex = initialTableRecordCount;
    isLoading.value = false;
  };

  const onSelectionDataChange = (changedValue: Record<string, string>, property: AppsManagementSelectProperties) => {
    v$.value[property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    appsManagementState[property].label = selectedValue.label;
    appsManagementState[property].value = selectedValue.value;
  };

  watch(
    () => filteredApps,
    () => {
      applyPageRangeFilter();
    },
    { deep: true },
  );

  watch(
    [isFilterByAppStatus, isFilterByAppType, isFilterBySearch],
    ([newFilterByAppStatus, newFilterByAppType, newFilterBySearch]) => {
      isFilterApplied.value = newFilterByAppStatus || newFilterByAppType || newFilterBySearch;
      applyPageRangeFilter();
    },
  );

  watch(
    () => appsManagementState.selectedResultPerPage,
    (newCountOfResultPerPage) => {
      if (originalApps.list.length >= Number(newCountOfResultPerPage.value)) {
        displayedApps.list = !isFilterApplied.value
          ? originalApps.list.slice(0, Number(newCountOfResultPerPage.value))
          : filteredApps.list.slice(0, Number(newCountOfResultPerPage.value));
        currentFilterRange.startIndex = 0;
        currentFilterRange.endIndex = Number(newCountOfResultPerPage.value);
      } else {
        displayedApps.list = !isFilterApplied.value
          ? originalApps.list.slice(0, originalApps.list.length)
          : filteredApps.list.slice(0, originalApps.list.length);
        currentFilterRange.startIndex = 0;
        currentFilterRange.endIndex = originalApps.list.length;
      }
    },
    { deep: true },
  );

  watch(
    () => appsManagementState.appStatus,
    () => {
      fetchByFilters();
    },
    { deep: true },
  );

  watch(
    () => appsManagementState.appType,
    () => {
      fetchByFilters();
    },
    { deep: true },
  );

  const fetchByFilters = async () => {
    isLoading.value = true;

    const selectedAppStatus =
      appsManagementState.appStatus.value === AppStatusDropdownValuesEnum.AllStatus
        ? null
        : appsManagementState.appStatus.value;

    const selectedAppType =
      appsManagementState.appType.value === AppTypeDropdownValuesEnum.AllTypes
        ? null
        : appsManagementState.appType.value;
    const params = {
      appStatus: selectedAppStatus,
      appType: selectedAppType,
      searchValue: appsManagementState.searchValue,
    };
    const filterRes = await filterAdminApps(params);

    if (filterRes instanceof ErrorModel || !filterRes.payload) {
      isLoading.value = false;
      return;
    }
    originalApps.list = filterRes.payload;
    const initialTableRecordCount = filterRes.payload.length >= 10 ? 10 : filterRes.payload.length;
    displayedApps.list = filterRes.payload.slice(0, initialTableRecordCount);
    currentFilterRange.startIndex = 0;
    currentFilterRange.endIndex = initialTableRecordCount;
    isLoading.value = false;
  };

  const applyPageRangeFilter = () => {
    let newAppsListEndIndex;

    if (!isFilterApplied.value) {
      newAppsListEndIndex =
        originalApps.list.length >= currentFilterRange.endIndex
          ? currentFilterRange.endIndex
          : originalApps.list.length;
      displayedApps.list = originalApps.list.slice(currentFilterRange.startIndex, newAppsListEndIndex);
    } else {
      newAppsListEndIndex =
        filteredApps.list.length >= currentFilterRange.endIndex
          ? currentFilterRange.endIndex
          : filteredApps.list.length;
      displayedApps.list = filteredApps.list.slice(currentFilterRange.startIndex, newAppsListEndIndex);
    }
  };

  const onSort = (sortingProperty: SortingStateProperties) => {
    const isAscending = !sortingState[sortingProperty];

    const newAppsListEndIndex =
      originalApps.list.length >= currentFilterRange.endIndex ? currentFilterRange.endIndex : originalApps.list.length;

    if (sortingProperty === SortingStateProperties.Installs) {
      filteredApps.list = [
        ...originalApps.list.sort((a, b) => {
          const sortValue = isAscending ? b.installs_count - a.installs_count : a.installs_count - b.installs_count;
          return sortValue;
        }),
      ];
      sortingState.installs = isAscending;
      sortingState.rating = null;
    } else if (sortingProperty === SortingStateProperties.Rating) {
      filteredApps.list = [
        ...originalApps.list.sort((a, b) => {
          const sortValue = isAscending ? b.rate - a.rate : a.rate - b.rate;
          return sortValue;
        }),
      ];
      sortingState.rating = isAscending;
      sortingState.installs = null;
    }

    displayedApps.list = filteredApps.list.slice(currentFilterRange.startIndex, newAppsListEndIndex);
    isFilterApplied.value = true;
    sortingState[sortingProperty] = isAscending;
  };

  const onPreviousClick = () => {
    currentFilterRange.startIndex -= Number(appsManagementState.selectedResultPerPage.value);
    currentFilterRange.endIndex -= Number(appsManagementState.selectedResultPerPage.value);
    applyPageRangeFilter();
  };

  const onNextClick = () => {
    currentFilterRange.startIndex += Number(appsManagementState.selectedResultPerPage.value);
    currentFilterRange.endIndex += Number(appsManagementState.selectedResultPerPage.value);
    applyPageRangeFilter();
  };

  const isNextButtonDisabled = computed(() => {
    if (isFilterApplied.value) {
      return currentFilterRange.endIndex >= filteredApps.list.length;
    } else {
      return currentFilterRange.endIndex >= originalApps.list.length;
    }
  });

  return {
    appsManagementState,
    sortingState,
    resultPerPage,
    v$,
    originalApps,
    filteredApps,
    displayedApps,
    appStatus,
    appType,
    currentFilterRange,
    isFilterApplied,
    isNextButtonDisabled,
    AppsManagementInputProperty,
    AppsManagementSelectProperties,
    SortingStateProperties,
    isLoading,
    onInputDataUpdate,
    onSelectionDataChange,
    onSort,
    onPreviousClick,
    onNextClick,
  };
};

export default useAppsManagement;
