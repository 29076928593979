import { defineComponent } from '@vue/composition-api';
import {
  ZidInput,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidTextarea,
  ZidButton,
  ZidCheckbox,
  ZidLoader,
} from '@zidsa/ui';
import BaseText from '@/components/text/BaseText.vue';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import useGeneralData from '@/domains/pd-admin/helpers/administration/apps-management/app-details/shipping-app-details/useGeneralData';
export default defineComponent({
  components: {
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidTextarea,
    ZidButton,
    ZidCheckbox,
    ZidLoader,
    BaseText,
    TabMultiLanguage,
  },
  props: {
    appId: {
      type: Number,
      required: true,
    },
    shippingAppGeneralData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return {
      ...useGeneralData(props.appId, props.shippingAppGeneralData),
    };
  },
});
