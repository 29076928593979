import { defineComponent, ref } from '@vue/composition-api';
import { RoutingRouteEnum } from '@/router/routes.enum';
import BaseText from '@/components/text/BaseText.vue';
import {
  ZidButton,
  ZidIcon,
  ZidInput,
  ZidTextarea,
  ZidModal,
  ZidLoader,
  ZidBreadcrumb,
  ZidBreadcrumbItem,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import Multiselect from 'vue-multiselect';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import DeleteIcon from '@/assets/icons/applications/DeleteIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import useAppWebhooks from '@/domains/pd-admin/helpers/administration/apps-management/app-details/app-webhooks/useAppWebhooks';
import { ApplicationTypeProperty } from '@/api/models/ApplicationModel.ts';

export default defineComponent({
  components: {
    BaseText,
    ZidButton,
    ZidIcon,
    ZidInput,
    ZidTextarea,
    Multiselect,
    BaseIcon,
    DeleteIcon,
    EditIcon,
    ZidModal,
    ZidLoader,
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  setup(_, ctx) {
    const appID = Number(ctx.root.$route.params.appId);
    const preSelectedGroupID = ref(ctx.root.$route.params.groupId);
    return {
      appID,
      RoutingRouteEnum,
      ApplicationTypeProperty,
      ...useAppWebhooks(appID, preSelectedGroupID.value),
    };
  },
});
