import httpClient from '@/api/httpClient';
import BaseModel from '@/api/models/BaseModel';
import AppPackagesResponseModel from '@/domains/pd-admin/models/administration/apps-management/app-management/packages-control/app-packages-response-model';

const ENDPOINT_URL = '/market';

export const getAppPackages = (appID: number) => {
  return httpClient
    .get(`${ENDPOINT_URL}/admin/${appID}/packages`)
    .then((response) => new AppPackagesResponseModel(response))
    .catch((error) => error);
};

export const updateAppPackages = (appID: number, selectedPackages: string[]) => {
  return httpClient
    .post(`${ENDPOINT_URL}/admin/update/${appID}/updateAppPackages`, {
      packages: selectedPackages,
    })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
