import { RoutingRouteEnum } from '@/router/routes.enum';

export enum ShippingAppDetailsRoutesEnum {
  AppsManagement = RoutingRouteEnum.Admin_AppsManagement,
  AppDetails = RoutingRouteEnum.Admin_AppDetails_Shipping,
}
export enum ShippingAppGeneraDataInputsEnum {
  AppCompanyNameEnglish = 'appCompanyNameEnglish',
  AppCompanyNameArabic = 'appCompanyNameArabic',
  CompanyURL = 'companyURL',
  AboutCompanyEnglish = 'aboutCompanyEnglish',
  AboutCompanyArabic = 'aboutCompanyArabic',
  MobileNumber = 'mobileNumber',
  TechnicalSupportEmail = 'technicalSupportEmail',
}

export enum CreateShippingAppSelectPropertyEnum {
  TypeOfCompany = 'typeOfCompany',
}

export enum TermsAndCoveragePropertiesEnum {
  PaymentDueDateEnglish = 'paymentDueDateEnglish',
  PaymentDueDateArabic = 'paymentDueDateArabic',
  DeveloperNameEnglish = 'developerNameEnglish',
  DeveloperNameArabic = 'developerNameArabic',
  ShortDescriptionEnglish = 'shortDescriptionEnglish',
  ShortDescriptionArabic = 'shortDescriptionArabic',
  ShippingWeightEnglish = 'shippingWeightEnglish',
  ShippingWeightArabic = 'shippingWeightArabic',
  ReceivingOrdersTimesEnglish = 'receivingOrdersTimesEnglish',
  ReceivingOrdersTimesArabic = 'receivingOrdersTimesArabic',
  OrderDeliveryTimesEnglish = 'orderDeliveryTimesEnglish',
  OrderDeliveryTimesArabic = 'orderDeliveryTimesArabic',
}

export enum TermsAndCoverageLocationTypeEnum {
  Pickup = 'pickup',
  Delivery = 'delivery',
}

export enum ShippingPricingModelEnum {
  RequestActivation = 'requestActivation',
  ActivateImmediately = 'activateImmediately',
}

export enum SelectedPriceEnum {
  Free = 'free',
  Paid = 'paid',
}

export enum PricingTypesEnum {
  Fixed = 'fixed',
  ByWeight = 'weight_based',
}

export enum ShippingOptionsInputPropertiesEnum {
  ActivationURL = 'activationURL',
  CustomizationNameEn = 'customizationNameEn',
  CustomizationNameAr = 'customizationNameAr',
  CodCost = 'CodCost',
  ShippingCost = 'shippingCost',
  BaseWeight = 'baseWeight',
  BaseWeightCost = 'baseWeightCost',
  AdditionalWeight = 'additionalWeight',
  AdditionalWeightCost = 'additionalWeightCost',
}

export enum CustomizationOptionLabelsEnum {
  Activated = 'Activated',
  NotActivated = 'Not Activated',
  Fixed = 'fixed',
  FixedPrice = 'Fixed Price',
  ByWeight = 'By Weight',
}

export enum SubscriptionTypesEnum {
  Orders = 'orders',
  Subscription = 'subscription',
  Products = 'products',
  Sync = 'sync',
}

export enum WebhooksManagementInputPropertiesEnum {
  TargetURL = 'targetURL',
  Headers = 'headers',
}

export enum LocaleEnum {
  AR = 'ar',
  EN = 'en',
}

export enum OAuthDetailsInputPropertiesEnum {
  RedirectUrl = 'redirectUrl',
  CallbackUrl = 'callbackUrl',
}

export type servicesListType = {
  services: string[];
};

export type shippingSelectionCountryType = {
  label: string;
  value: number | null;
};

export type shippingSelectionCityType = {
  label: string;
  value: number | null;
  country_id: number | null;
};

export type ShippingCountriesSelectionType = {
  selectAll?: string;
  options: shippingSelectionCountryType[];
};

export type ShippingCitiesSelectionType = {
  selectAll?: string;
  options: shippingSelectionCityType[];
};

export type CustomizationDetailsType = {
  id: number | null;
  name: {
    ar: string;
    en: string;
  };
  cities: (number | null)[];
  is_cod_allowed: boolean;
  pricing_type: string;
  rate: {
    cost?: string;
    cod_cost: string;
    base_weight?: string;
    base_weight_cost?: string;
    additional_weight?: string;
    additional_weight_cost?: string;
  };
};

export type WebhookType = {
  id: number | null;
  name: string;
  target_url: string;
  headers: string;
  conditions?: Record<string, string>;
  group_type?: SubscriptionTypesEnum;
};

export type WebhookResponse = WebhookType & {
  app_id: number;
  group_type: string;
};

export type AvailableWebhooksType = {
  orders: string[];
  subscription: string[];
  products: string[];
  sync: string[];
};

export type AvailableWebhooksOptionsType = {
  options: AvailableWebhooksType;
};

export type WebhookGroup = {
  group_id: string | null;
  webhooks: WebhookType[];
};

export type SubscriptionDetailsType = {
  id: number;
  webhookSelection: string[];
  targetURL: string;
  headers: string;
};

export type SubscriptionType = {
  orders: SubscriptionDetailsType;
  subscription: SubscriptionDetailsType;
  products: SubscriptionDetailsType;
  sync: SubscriptionDetailsType;
};

export type WebhooksFromSaveBtnType = {
  orders: boolean;
  subscription: boolean;
  products: boolean;
  sync: boolean;
};

export type ShippingAppOAuthDetailsType = {
  step: number;
  app_id: number | null;
  callback_url: string;
  redirect_url: string;
};

export interface ShippingAppGeneralDataInterface {
  appCompanyNameArabic: string;
  appCompanyNameEnglish: string;
  aboutCompanyEnglish: string;
  aboutCompanyArabic: string;
  technicalSupportEmail: string;
  companyURL: string;
  companyLogo: File | string | null;
  coverPhoto: File | string | null;
  mobileNumber: string;
  typeOfCompany: {
    label: string;
    value: string;
  };
  services: string;
}

export interface apiKeysInterface {
  clientId: string;
  clientSecret: string;
  callbackUrl: string;
  redirectUrl: string;
}

export interface TermsAndCoverageDataInterface {
  pickup: {
    countries: shippingSelectionCountryType[] | null;
    cities: shippingSelectionCityType[] | null;
  };
  delivery: {
    countries: shippingSelectionCountryType[] | null;
    cities: shippingSelectionCityType[] | null;
  };
  paymentDueDateEnglish: string;
  paymentDueDateArabic: string;
  developerNameEnglish: string;
  developerNameArabic: string;
  shortDescriptionEnglish: string;
  shortDescriptionArabic: string;
  receivingOrdersTimesEnglish: string;
  receivingOrdersTimesArabic: string;
  orderDeliveryTimesEnglish: string;
  orderDeliveryTimesArabic: string;
  shippingWeightEnglish: string;
  shippingWeightArabic: string;
  appScreenshotsAr: (string | File)[];
  appScreenshotsEn: (string | File)[];
}

export interface ShippingOptionDetailsInterface {
  activationURL: string;
  customizationNameEn: string;
  customizationNameAr: string;
  locations: {
    countries:
      | {
          label: string;
          value: number | null;
        }[]
      | null;
    cities:
      | {
          label: string;
          value: number | null;
          country_id: number | null;
        }[]
      | null;
  };
  cashOnDelivery: boolean;
  shippingPriceType: string;
  CodCost: string;
  shippingCost: string;
  baseWeight: string;
  baseWeightCost: string;
  additionalWeight: string;
  additionalWeightCost: string;
}
