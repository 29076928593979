export type ControlPartnershipState = {
  isContractSigned: boolean;
  contractFile?: File;
  revenueModel: Record<string, string>;
  priceValue: string;
  activationFee: string;
  zidPercentage: string;
  partnerPercentage: string;
  slaps_details: SlapDetails[];
  vat: Record<string, string>;
  vatPercentage: string;
};

export type SlapDetails = {
  app_id: number;
  slap_number: number;
  orders: number;
  price: number;
  slap_key?: string;
  slap_value?: string;
};

export type AppType = {
  app_id: number;
  partner_id: number;
  app_name: string;
  contract_status: string;
  contract_doc: string | null;
  pricing_model: number;
  fixed_price: string;
  zid_percentage: string;
  partner_percentage: string;
  vat_percentage: string;
  activation_fees: string;
  slaps_details?: SlapDetails[];
};

export enum RevenueModelFieldsToShowEnum {
  Empty = 0,
  FixedPrice = 1,
  PercentageOfRevenue = 2,
  Slap = 3,
}

export enum RevenueModelEnum {
  FixedPrice = 'Fixed price',
  PercentageOfRevenue = 'Percentage of revenue',
  Slap = 'Slap Model',
}

export enum RevenueModelValuesEnum {
  FixedPrice = '1',
  PercentageOfRevenue = '2',
  Slap = '3',
}

export enum EmptyValueEnum {
  Empty = '0.00',
}

export enum VATEnum {
  Include = 'include',
  Exclude = 'no VAT',
}

export enum VATValuesEnum {
  Include = '1',
  Exclude = '2',
}

export enum InputPropertiesEnum {
  priceValue = 'priceValue',
  activationFee = 'activationFee',
  zidPercentage = 'zidPercentage',
  partnerPercentage = 'partnerPercentage',
  vatPercentage = 'vatPercentage',
}

export enum SlapModelDetails {
  Orders = 'ORDERS',
  Price = 'PRICE',
}

export enum ControlPartnershipsSelectPropertiesEnum {
  revenueModel = 'revenueModel',
  vat = 'vat',
}

export type AppInformationToUpdateType = {
  app_id: string;
  contract_status: string;
  pricing_model: string;
  fixed_price?: string;
  zid_percentage?: string;
  partner_percentage?: string;
  vat_percentage?: string;
  contract_doc?: File;
  activation_fees?: string;
  slaps_details?: SlapDetails[];
};

export enum ContractStatusEnum {
  Signed = 'SIGNED',
  Unsigned = 'UNSIGNED',
}
