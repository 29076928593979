import { helpers, maxLength, required } from '@vuelidate/validators';
import {
  ARABIC_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS,
  ARABIC_TEXT_WITH_SPECIAL_CHARS,
  ENGLISH_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS,
  ENGLISH_TEXT_WITH_SPECIAL_CHARS,
} from '@/helpers/regex/text';
import { computed, onBeforeMount, onMounted, reactive, ref, watch } from '@vue/composition-api';
import {
  LocaleEnum,
  ShippingCitiesSelectionType,
  ShippingCountriesSelectionType,
  shippingSelectionCityType,
  shippingSelectionCountryType,
  TermsAndCoverageDataInterface,
  TermsAndCoverageLocationTypeEnum,
  TermsAndCoveragePropertiesEnum,
} from '@/domains/pd-admin/types/administration/apps-management/app-details/shipping-app-details.type';
import useVuelidate from '@vuelidate/core';
import {
  getCoverageCities,
  getCoverageCountries,
} from '@/domains/pd-admin/api/administration/apps-management/app-details-shipping/get-coverage-locations';
import ErrorModel from '@/api/models/ErrorModel';
import Vue from 'vue';
import CoverageCountryModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/CoverageCountryModel';
import CoverageCityModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/CoverageCityModel';
import { getFileFromURL } from '@/api/top-level-apis/helpers/fileFromURL';
import { updateAppTermsAndCoverage } from '@/domains/pd-admin/api/administration/apps-management/app-details-shipping/update-terms-and-coverage';

const useTermsAndCoverage = (appId: number, shippingAppModel: any) => {
  const validateOptionalField = (validationRule: RegExp) => (value: string) => {
    return !helpers.req(value) || validationRule.test(value);
  };
  const createFileObject = (screenshot: string | File) => {
    if (typeof screenshot !== 'string') {
      return screenshot;
    }
    return new File([], screenshot, {
      type: 'image/jpg',
    });
  };
  const MIN_SCREENSHOTS_NUMBER = 1;
  const MAX_SCREENSHOTS_NUMBER = 10;
  const SHORT_DESCRIPTION_MAX_LENGTH = 100;
  const rules = {
    pickup: {
      countries: {
        required,
      },
      cities: {
        required,
      },
    },
    delivery: {
      countries: {
        required,
      },
      cities: {
        required,
      },
    },
    paymentDueDateEnglish: {
      paymentDueDateEnglish: helpers.withMessage(
        'English field can only contain English letters.',
        validateOptionalField(ENGLISH_TEXT_WITH_SPECIAL_CHARS),
      ),
    },
    paymentDueDateArabic: {
      paymentDueDateArabic: helpers.withMessage(
        'Arabic field can only contain Arabic letters.',
        validateOptionalField(ARABIC_TEXT_WITH_SPECIAL_CHARS),
      ),
    },
    receivingOrdersTimesEnglish: {
      receivingOrdersTimesEnglish: helpers.withMessage(
        'English field can only contain English letters.',
        validateOptionalField(ENGLISH_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS),
      ),
    },
    receivingOrdersTimesArabic: {
      receivingOrdersTimesArabic: helpers.withMessage(
        'Arabic field can only contain Arabic letters.',
        validateOptionalField(ARABIC_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS),
      ),
    },
    orderDeliveryTimesEnglish: {
      orderDeliveryTimesEnglish: helpers.withMessage(
        'English field can only contain English letters.',
        validateOptionalField(ENGLISH_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS),
      ),
    },
    orderDeliveryTimesArabic: {
      orderDeliveryTimesArabic: helpers.withMessage(
        'Arabic field can only contain Arabic letters.',
        validateOptionalField(ARABIC_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS),
      ),
    },
    shippingWeightEnglish: {
      shippingWeightEnglish: helpers.withMessage(
        'English field can only contain English letters.',
        validateOptionalField(ENGLISH_TEXT_WITH_SPECIAL_CHARS),
      ),
    },
    shippingWeightArabic: {
      shippingWeightArabic: helpers.withMessage(
        'Arabic field can only contain Arabic letters.',
        validateOptionalField(ARABIC_TEXT_WITH_SPECIAL_CHARS),
      ),
    },
    developerNameEnglish: {
      required,
      developerNameEnglish: helpers.withMessage('English field can only contain English letters.', (value: string) => {
        return ENGLISH_TEXT_WITH_SPECIAL_CHARS.test(value) || ARABIC_TEXT_WITH_SPECIAL_CHARS.test(value);
      }),
    },
    developerNameArabic: {
      required,
      developerNameArabic: helpers.withMessage('Arabic field can only contain Arabic letters.', (value: string) => {
        return ENGLISH_TEXT_WITH_SPECIAL_CHARS.test(value) || ARABIC_TEXT_WITH_SPECIAL_CHARS.test(value);
      }),
    },
    shortDescriptionEnglish: {
      required,
      maxLength: maxLength(SHORT_DESCRIPTION_MAX_LENGTH),
      shortDescriptionEnglish: helpers.withMessage(
        'English field is required and can only contain English letters.',
        (value: string) => {
          return ENGLISH_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS.test(value);
        },
      ),
    },
    shortDescriptionArabic: {
      required,
      maxLength: maxLength(SHORT_DESCRIPTION_MAX_LENGTH),
      shortDescriptionArabic: helpers.withMessage(
        'Arabic field is required and can only contain Arabic letters.',
        (value: string) => {
          return ARABIC_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS.test(value);
        },
      ),
    },
  };
  const isLoadingCoverageCountries = ref(false);
  const isLoadingPickupCities = ref(false);
  const isLoadingDeliveryCities = ref(false);
  const isLoadingInitialScreenshotsAr = ref(true);
  const isLoadingInitialScreenshotsEn = ref(true);
  const isArScreenshotsInvalid = ref(false);
  const isEnScreenshotsInvalid = ref(false);
  const isUpdatingTermsAndCoverage = ref(false);
  const shippingAppTermsAndCoverageState = reactive<TermsAndCoverageDataInterface>({
    pickup: {
      countries: shippingAppModel.pickup.countries,
      cities: shippingAppModel.pickup.cities,
    },
    delivery: {
      countries: shippingAppModel.delivery.countries,
      cities: shippingAppModel.delivery.cities,
    },
    paymentDueDateEnglish: shippingAppModel.paymentDueDateEnglish,
    paymentDueDateArabic: shippingAppModel.paymentDueDateArabic,
    developerNameEnglish: shippingAppModel.developerNameEnglish,
    developerNameArabic: shippingAppModel.developerNameArabic,
    shortDescriptionEnglish: shippingAppModel.shortDescriptionEnglish,
    shortDescriptionArabic: shippingAppModel.shortDescriptionArabic,
    receivingOrdersTimesEnglish: shippingAppModel.receivingOrdersTimesEnglish,
    receivingOrdersTimesArabic: shippingAppModel.receivingOrdersTimesArabic,
    orderDeliveryTimesEnglish: shippingAppModel.orderDeliveryTimesEnglish,
    orderDeliveryTimesArabic: shippingAppModel.orderDeliveryTimesArabic,
    shippingWeightEnglish: shippingAppModel.shippingWeightEnglish,
    shippingWeightArabic: shippingAppModel.shippingWeightArabic,
    appScreenshotsAr: shippingAppModel.appScreenshotsAr,
    appScreenshotsEn: shippingAppModel.appScreenshotsEn,
  });
  const coverageDeliveryCountries = reactive<ShippingCountriesSelectionType>({
    selectAll: 'Select All',
    options: [],
  });
  const coveragePickupCountries = reactive<ShippingCountriesSelectionType>({
    selectAll: 'Select All',
    options: [],
  });

  const coveragePickupCities = reactive<ShippingCitiesSelectionType>({
    selectAll: 'Select All',
    options: [],
  });

  const coverageDeliveryCities = reactive<ShippingCitiesSelectionType>({
    selectAll: 'Select All',
    options: [],
  });
  const originalEnglishScreenshots = reactive<Record<string, File[]>>({ list: [] });
  const originalArabicScreenshots = reactive<Record<string, File[]>>({ list: [] });
  const initialArabicScreenshots = reactive({
    list: [...shippingAppTermsAndCoverageState.appScreenshotsAr.map(createFileObject)],
  });

  const initialEnglishScreenshots = reactive({
    list: [...shippingAppTermsAndCoverageState.appScreenshotsEn.map(createFileObject)],
  });

  onBeforeMount(async () => {
    await loadCoverageCountries();
    originalArabicScreenshots.list = await getArabicScreenshotsFiles();
    if (!originalArabicScreenshots.list.length) {
      isArScreenshotsInvalid.value = true;
    }
    initialArabicScreenshots.list = [
      ...shippingAppTermsAndCoverageState.appScreenshotsAr.map((screen: string | File) => {
        if (typeof screen === 'string') {
          return new File([], screen, {
            type: 'image/jpg',
          });
        } else return screen;
      }),
    ];
    disableScreenshotsLoadingState(LocaleEnum.AR);
  });

  onMounted(async () => {
    await loadCoverageCities(TermsAndCoverageLocationTypeEnum.Pickup);
    await loadCoverageCities(TermsAndCoverageLocationTypeEnum.Delivery);
    originalEnglishScreenshots.list = await getEnglishScreenshotsFiles();
    initialEnglishScreenshots.list = [
      ...shippingAppTermsAndCoverageState.appScreenshotsEn.map((screen: string | File) => {
        if (typeof screen === 'string') {
          return new File([], screen, {
            type: 'image/jpg',
          });
        } else return screen;
      }),
    ];
    disableScreenshotsLoadingState(LocaleEnum.EN);
  });

  watch(
    () => shippingAppTermsAndCoverageState.pickup.countries,
    (pickupCountries) => {
      clearCitiesForDeselectedCountries(TermsAndCoverageLocationTypeEnum.Pickup);
      if (pickupCountries?.length !== 0) {
        loadCoverageCities(TermsAndCoverageLocationTypeEnum.Pickup);
      }
    },
    { deep: true },
  );

  watch(
    () => shippingAppTermsAndCoverageState.delivery.countries,
    (deliveryCountries) => {
      clearCitiesForDeselectedCountries(TermsAndCoverageLocationTypeEnum.Pickup);
      if (deliveryCountries?.length !== 0) {
        loadCoverageCities(TermsAndCoverageLocationTypeEnum.Delivery);
      }
    },
    { deep: true },
  );

  const validator = useVuelidate(rules, shippingAppTermsAndCoverageState);

  const onInputDataChange = (changedValue: Record<string, string>, dataProperty: TermsAndCoveragePropertiesEnum) => {
    validator.value[dataProperty].$touch();
    shippingAppTermsAndCoverageState[dataProperty] = changedValue.value;
  };

  const loadCoverageCountries = async () => {
    isLoadingCoverageCountries.value = true;
    const response = await getCoverageCountries(appId);
    if (response instanceof ErrorModel || !response.countries) {
      Vue.$toast.error('Failed to load Countries For Coverage');
      isLoadingCoverageCountries.value = false;
      return;
    }
    const { countries } = response;
    if (countries.length > 0) {
      countries.forEach((country: CoverageCountryModel) => {
        coverageDeliveryCountries.options.push({
          label: `${country.name.en} - ${country.name.ar}`,
          value: country.id,
        });
        coveragePickupCountries.options.push({
          label: `${country.name.en} - ${country.name.ar}`,
          value: country.id,
        });
      });
    } else {
      Vue.$toast.error('No Country is available For Coverage');
    }
    isLoadingCoverageCountries.value = false;
  };

  const loadCoverageCities = async (locationType: TermsAndCoverageLocationTypeEnum) => {
    if (!shippingAppTermsAndCoverageState[locationType].countries?.length) {
      return;
    }
    enableCitiesLoadingStatus(locationType);
    const selectedCountries = shippingAppTermsAndCoverageState[locationType].countries?.map(({ value }) => value) ?? [];
    const response = await getCoverageCities(appId, locationType, selectedCountries);
    if (response instanceof ErrorModel || !response.cities) {
      Vue.$toast.error('Failed to load Cities For Selected Countries');
      disableCitiesLoadingStatus(locationType);
      return;
    }

    const { cities } = response;

    if (locationType === TermsAndCoverageLocationTypeEnum.Pickup) {
      fillCoverageCitiesOptions(coveragePickupCities, cities);
    } else {
      fillCoverageCitiesOptions(coverageDeliveryCities, cities);
    }

    disableCitiesLoadingStatus(locationType);
  };

  const disableCitiesLoadingStatus = (locationType: TermsAndCoverageLocationTypeEnum) => {
    if (locationType === TermsAndCoverageLocationTypeEnum.Pickup) {
      isLoadingPickupCities.value = false;
      return;
    }
    isLoadingDeliveryCities.value = false;
  };

  const enableCitiesLoadingStatus = (locationType: TermsAndCoverageLocationTypeEnum) => {
    if (locationType === TermsAndCoverageLocationTypeEnum.Pickup) {
      isLoadingPickupCities.value = true;
      return;
    }
    isLoadingDeliveryCities.value = true;
  };

  const fillCoverageCitiesOptions = (reactive: ShippingCitiesSelectionType, cities: CoverageCityModel[]) => {
    reactive.options = []; // We have to first reset the options list to avoid duplicates
    cities.forEach((city) => {
      reactive.options.push({
        label: `${city.name.en} - ${city.name.ar}`,
        value: city.id,
        country_id: city.country_id,
      });
    });
  };

  const clearCitiesForDeselectedCountries = (locationType: TermsAndCoverageLocationTypeEnum) => {
    //1- When there are no selected countries, we directly remove all the selected and all the available coverage cities.
    if (!shippingAppTermsAndCoverageState[locationType].countries?.length) {
      shippingAppTermsAndCoverageState[locationType].cities = [];
      if (locationType === TermsAndCoverageLocationTypeEnum.Pickup) {
        coveragePickupCities.options = [];
      } else {
        coverageDeliveryCities.options = [];
      }
      return;
    }

    // 2- When there are some selected countries, we filter all the selected and the available cities lists to
    // Keep only the cities of the selected countries
    const selectedCountries = shippingAppTermsAndCoverageState[locationType].countries ?? [];
    const selectedCountriesIds: (number | null)[] = selectedCountries.map(
      (country: shippingSelectionCountryType) => country.value,
    );
    // a- Clean the selected cities list
    shippingAppTermsAndCoverageState[locationType].cities = getCitiesForSelectedCountriesOnly(
      selectedCountriesIds,
      shippingAppTermsAndCoverageState[locationType].cities,
    );
    // b- Clean the available coverage cities
    const filteredCities = getCitiesForSelectedCountriesOnly(
      selectedCountriesIds,
      locationType === TermsAndCoverageLocationTypeEnum.Pickup
        ? coveragePickupCities.options
        : coverageDeliveryCities.options,
    );
    if (locationType === TermsAndCoverageLocationTypeEnum.Pickup) {
      coveragePickupCities.options = filteredCities ?? [];
      return;
    }
    coverageDeliveryCities.options = filteredCities ?? [];
  };

  const getCitiesForSelectedCountriesOnly = (
    selectedCountriesIds: (number | null)[],
    cities: shippingSelectionCityType[] | null,
  ): shippingSelectionCityType[] | null => {
    if (cities === null) {
      return cities;
    }
    return cities.filter((city) => {
      return selectedCountriesIds.indexOf(city.country_id) !== -1;
    });
  };

  const getArabicScreenshotsFiles = async () => {
    return Promise.all(
      shippingAppTermsAndCoverageState.appScreenshotsAr.map(async (screen) => {
        if (typeof screen === 'string') {
          const sc = await getFileFromURL(screen, screen, 'image/jpg');
          return sc;
        } else return screen;
      }),
    )
      .then((values) => {
        return values;
      })
      .catch(() => {
        initialArabicScreenshots.list = [];
        return [];
      });
  };

  const getEnglishScreenshotsFiles = async () => {
    return Promise.all(
      shippingAppTermsAndCoverageState.appScreenshotsEn.map(async (screen) => {
        if (typeof screen === 'string') {
          const sc = await getFileFromURL(screen, screen, 'image/jpg');
          return sc;
        } else return screen;
      }),
    )
      .then((values) => {
        return values;
      })
      .catch(() => {
        initialEnglishScreenshots.list = [];
        return [];
      });
  };

  const disableScreenshotsLoadingState = (type: LocaleEnum) => {
    if (type === LocaleEnum.AR) {
      isLoadingInitialScreenshotsAr.value = false;
      return;
    }
    isLoadingInitialScreenshotsEn.value = false;
  };

  const onScreenshotsChange = (images: File[], type: LocaleEnum) => {
    let screenshots = type === LocaleEnum.AR ? originalArabicScreenshots.list : originalEnglishScreenshots.list;
    const newImages = images.filter(
      (screen) =>
        screenshots.findIndex((originalScreen) => {
          return originalScreen.name === screen.name;
        }) == -1,
    );
    screenshots = [...screenshots, ...newImages];
    if (type === LocaleEnum.AR) {
      originalArabicScreenshots.list = screenshots;
    } else {
      originalEnglishScreenshots.list = screenshots;
    }
    const areValidFiles = areValidImageFiles(screenshots);
    if (!isValidImagesCount(type, screenshots.length) || !areValidFiles) {
      setInvalidScreenshots(type);
    } else {
      setValidScreenshots(type);
    }
  };
  const onScreenshotRemove = (image: File, type: LocaleEnum) => {
    const screenshots = type === LocaleEnum.AR ? originalArabicScreenshots.list : originalEnglishScreenshots.list;
    const screenIndex = screenshots.findIndex((screen) => {
      return screen.name === image.name;
    });

    if (screenIndex !== -1) {
      screenshots.splice(screenIndex, 1);
      const areFiles = areValidImageFiles(screenshots);

      if (!isValidImagesCount(type, screenshots.length) || !areFiles) {
        setInvalidScreenshots(type);
      } else {
        setValidScreenshots(type);
      }
    }
  };

  const isValidImagesCount = (type: LocaleEnum, count: number) => {
    if (type === LocaleEnum.AR) {
      return count <= MAX_SCREENSHOTS_NUMBER && count >= MIN_SCREENSHOTS_NUMBER;
    }
    return count <= MAX_SCREENSHOTS_NUMBER;
  };

  const areValidImageFiles = (images: (File | string)[]) => {
    return images.every((file) => {
      if (typeof file === 'string') {
        return false;
      }
      return file.type.includes('image/');
    });
  };

  const setInvalidScreenshots = (type: LocaleEnum) => {
    if (type === LocaleEnum.AR) {
      isArScreenshotsInvalid.value = true;
      return;
    }
    isEnScreenshotsInvalid.value = true;
  };

  const setValidScreenshots = (type: LocaleEnum) => {
    if (type === LocaleEnum.AR) {
      isArScreenshotsInvalid.value = false;
      return;
    }
    isEnScreenshotsInvalid.value = false;
  };

  const onUpdateClick = async () => {
    isUpdatingTermsAndCoverage.value = true;
    const shippingApplicationDetails = {
      payment_due_en: shippingAppTermsAndCoverageState.paymentDueDateEnglish ?? '',
      payment_due_ar: shippingAppTermsAndCoverageState.paymentDueDateArabic ?? '',
      locations_from:
        shippingAppTermsAndCoverageState.pickup.cities?.map((c) => {
          return c.value;
        }) ?? [],
      locations_to:
        shippingAppTermsAndCoverageState.delivery.cities?.map((c) => {
          return c.value;
        }) ?? [],
      times_to_pickup_en: shippingAppTermsAndCoverageState.orderDeliveryTimesEnglish ?? '',
      times_to_pickup_ar: shippingAppTermsAndCoverageState.orderDeliveryTimesArabic ?? '',
      times_to_deliver_en: shippingAppTermsAndCoverageState.receivingOrdersTimesEnglish ?? '',
      times_to_deliver_ar: shippingAppTermsAndCoverageState.receivingOrdersTimesArabic ?? '',
      weight_details_en: shippingAppTermsAndCoverageState.shippingWeightEnglish ?? '',
      weight_details_ar: shippingAppTermsAndCoverageState.shippingWeightArabic ?? '',
      developer_name_en: shippingAppTermsAndCoverageState.developerNameEnglish ?? '',
      developer_name_ar: shippingAppTermsAndCoverageState.developerNameArabic ?? '',
      short_description_en: shippingAppTermsAndCoverageState.shortDescriptionEnglish ?? '',
      short_description_ar: shippingAppTermsAndCoverageState.shortDescriptionArabic ?? '',
      screen_shots_en: [...originalEnglishScreenshots.list],
      screen_shots_ar: [...originalArabicScreenshots.list],
    };
    const response = await updateAppTermsAndCoverage(shippingApplicationDetails, appId);
    if (response instanceof ErrorModel || !response.payload) {
      Vue.$toast.error('Failed to update application details');
      isUpdatingTermsAndCoverage.value = false;
      return;
    }
    Vue.$toast.success('App Terms and coverage details updated successfully.');
    isUpdatingTermsAndCoverage.value = false;
  };

  const showMessageForPaymentDueDateArEnField = computed(() => {
    return (
      (validator.value.paymentDueDateArabic.$dirty || validator.value.paymentDueDateEnglish.$dirty) &&
      (validator.value.paymentDueDateArabic.$invalid || validator.value.paymentDueDateEnglish.$invalid)
    );
  });

  const showMessageForReceivingOrdersTimesArEnField = computed(() => {
    return (
      (validator.value.receivingOrdersTimesEnglish.$dirty || validator.value.receivingOrdersTimesArabic.$dirty) &&
      (validator.value.receivingOrdersTimesArabic.$invalid || validator.value.receivingOrdersTimesEnglish.$invalid)
    );
  });

  const showMessageForOrderDeliveryTimesArEnField = computed(() => {
    return (
      (validator.value.orderDeliveryTimesEnglish.$dirty || validator.value.orderDeliveryTimesArabic.$dirty) &&
      (validator.value.orderDeliveryTimesArabic.$invalid || validator.value.orderDeliveryTimesEnglish.$invalid)
    );
  });

  const showMessageForShippingWeightArEnField = computed(() => {
    return (
      (validator.value.shippingWeightArabic.$dirty || validator.value.shippingWeightEnglish.$dirty) &&
      (validator.value.shippingWeightArabic.$invalid || validator.value.shippingWeightEnglish.$invalid)
    );
  });

  const showMessageForDeveloperNameArEnField = computed(() => {
    return (
      (validator.value.developerNameArabic.$dirty || validator.value.developerNameEnglish.$dirty) &&
      (validator.value.developerNameArabic.$invalid || validator.value.developerNameEnglish.$invalid)
    );
  });

  const showMessageForShortDescriptionArEnField = computed(() => {
    return (
      (validator.value.shortDescriptionArabic.$dirty || validator.value.shortDescriptionEnglish.$dirty) &&
      (validator.value.shortDescriptionArabic.$invalid || validator.value.shortDescriptionEnglish.$invalid)
    );
  });

  return {
    TermsAndCoveragePropertiesEnum,
    TermsAndCoverageLocationTypeEnum,
    LocaleEnum,
    validator,
    shippingAppTermsAndCoverageState,
    coveragePickupCountries,
    coveragePickupCities,
    coverageDeliveryCountries,
    coverageDeliveryCities,
    isLoadingPickupCities,
    isLoadingDeliveryCities,
    isLoadingCoverageCountries,
    initialArabicScreenshots,
    initialEnglishScreenshots,
    isLoadingInitialScreenshotsAr,
    isLoadingInitialScreenshotsEn,
    isArScreenshotsInvalid,
    isEnScreenshotsInvalid,
    isUpdatingTermsAndCoverage,
    showMessageForPaymentDueDateArEnField,
    showMessageForReceivingOrdersTimesArEnField,
    showMessageForOrderDeliveryTimesArEnField,
    showMessageForShippingWeightArEnField,
    showMessageForDeveloperNameArEnField,
    showMessageForShortDescriptionArEnField,
    onInputDataChange,
    onScreenshotsChange,
    onScreenshotRemove,
    onUpdateClick,
  };
};
export default useTermsAndCoverage;
