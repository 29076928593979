import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import ShippingAppGeneralDataModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/ShippingAppGeneralDataModel';
import ShippingAppUpdateResponseModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/ShippingAppUpdateResponseModel';

const ENDPOINT_URL = '/market/app/create-shippings';

export const updateShippingAppGeneralData = (
  shippingAppInfo: ShippingAppGeneralDataModel,
): Promise<ShippingAppUpdateResponseModel | ErrorModel> => {
  const appData = new FormData();
  appData.append('app_id', `${shippingAppInfo.app_id}`);
  appData.append('step', '1');
  appData.append('name_en', shippingAppInfo.name_en);
  appData.append('name_ar', shippingAppInfo.name_ar);
  appData.append('url', shippingAppInfo.url);
  appData.append('email', shippingAppInfo.email);
  appData.append('mobile', shippingAppInfo.mobile);
  appData.append('shipping_type', shippingAppInfo.shipping_type);
  appData.append('description_en', shippingAppInfo.description_en);
  appData.append('description_ar', shippingAppInfo.description_ar);
  appData.append('services', shippingAppInfo.services);
  if (shippingAppInfo.icon) appData.append('icon', shippingAppInfo.icon);
  if (shippingAppInfo.coverPhoto) appData.append('cover_photo', shippingAppInfo.coverPhoto);
  return httpClient
    .post(ENDPOINT_URL, appData)
    .then((response) => new ShippingAppUpdateResponseModel(response))
    .catch((error) => error);
};
