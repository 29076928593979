import Vue from 'vue';
import { computed, onBeforeMount, reactive, ref, SetupContext } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { helpers, minLength, maxLength, required, requiredIf } from '@vuelidate/validators';
import PlanModel from '@/domains/applications/models/PlanModel';
import {
  AppPlanStateInterface,
  PlanInfoType,
  PlanInputPropertiesEnum,
  PlanSelectionPropertiesEnum,
  PlansInvoicePeriodEnum,
  PlanTypes,
  PlanOfferType,
  PlanOfferTypeLabelsEnum,
  PlanOfferTypeValuesEnum,
} from '@/domains/pd-admin/types/administration/apps-management/app-details/app-plans.type';
import { ARABIC_TEXT, ARABIC_TEXT_WITH_NEW_LINE, ENGLISH_TEXT, ENGLISH_TEXT_WITH_NEW_LINE } from '@/helpers/regex/text';
import { editApplicationPlan } from '@/domains/pd-admin/api/administration/apps-management/app-plan/app-plan';
import ErrorModel from '@/api/models/ErrorModel';

const useAppPlans = (plan: PlanModel, showModal: boolean, ctx: SetupContext) => {
  const showShowAddPlanModal = ref(showModal);
  const loadingDeletePlan = ref(false);
  const currentlySelectedPlanID = ref(plan.id);
  const currentlySelectedPurchasableID = ref(plan.purchasable_id);
  const currentlySelectedPlanOneTimeFeePurchasableID = ref('');
  const isOfferApplied = ref(false);
  const currentlySelectedPlanOfferID = ref(0);
  const currentlySelectedPlanOfferPurchasableID = ref('');
  const selectedPlanOfferType = ref(PlanOfferTypeValuesEnum.BuyXGetYOffer);
  const MIN_TRIAL_DAYS = 0;
  const MAX_TRIAL_DAYS = 90;
  const toggleShowAddPlanModal = () => (showShowAddPlanModal.value = !showShowAddPlanModal.value);

  const appPlanState = reactive<AppPlanStateInterface>({
    id: -1,
    planName: '',
    planNameArabic: '',
    planDuration: {
      label: '',
      value: '',
    },
    planDescription: '',
    planOfferDuration: {
      label: '',
      value: '',
    },
    planDescriptionArabic: '',
    pricePlan: '',
    trialDays: {
      label: '',
      value: '',
    },
    hasOneTimeFees: false,
    oneTimeFees: '',
    planFeatures: '',
    planFeaturesArabic: '',
    planDiscountOffer: '',
  });

  const trialDaysList = [];

  for (let i = MIN_TRIAL_DAYS; i <= MAX_TRIAL_DAYS; i++) {
    trialDaysList.push({
      label: `${i}`,
      value: `${i}`,
    });
  }

  const validatePlanFeaturesEntry = () => {
    const lines = appPlanState.planFeatures.split('\n');
    if (
      lines.length > 5 ||
      lines.some((line) => line.length > 50) ||
      lines.some((line) => !ENGLISH_TEXT.test(line)) ||
      appPlanState.planFeatures === ''
    )
      return false;
    else return true;
  };

  const validateArabicPlanFeaturesEntry = () => {
    const lines = appPlanState.planFeaturesArabic.split('\n');
    if (
      lines.length > 5 ||
      lines.some((line) => line.length > 50) ||
      lines.some((line) => !ARABIC_TEXT.test(line)) ||
      appPlanState.planFeaturesArabic === ''
    )
      return false;
    else return true;
  };

  const rules = {
    planName: {
      required,
      planName: helpers.withMessage('Plan name is required in both English and Arabic', (value: string) => {
        return ENGLISH_TEXT.test(value);
      }),
    },
    planNameArabic: {
      required,
      planNameArabic: helpers.withMessage('Plan name is required in both Arabic and English', (value: string) => {
        return ARABIC_TEXT.test(value);
      }),
    },
    planDuration: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    planDescription: {
      required,
      planDescription: helpers.withMessage(
        'Minimum lenght: 50 charachters, Maximum length: 300 characters',
        (value: string) => {
          return ENGLISH_TEXT_WITH_NEW_LINE.test(value);
        },
      ),
      maxLength: maxLength(300),
      minLength: minLength(50),
    },
    planOfferDuration: {
      label: {
        required: helpers.withMessage(
          'Select a valid duration for offer',
          requiredIf(
            () => isOfferApplied.value && selectedPlanOfferType.value === PlanOfferTypeValuesEnum.BuyXGetYOffer,
          ),
        ),
      },
      value: {
        required: helpers.withMessage(
          'Select a valid duration for offer',
          requiredIf(
            () => isOfferApplied.value && selectedPlanOfferType.value === PlanOfferTypeValuesEnum.BuyXGetYOffer,
          ),
        ),
      },
    },
    planDescriptionArabic: {
      required,
      planDescriptionArabic: helpers.withMessage(
        'Minimum length: 50 charachters, Maximum length: 300 characters',
        (value: string) => {
          return ARABIC_TEXT_WITH_NEW_LINE.test(value);
        },
      ),
      maxLength: maxLength(300),
      minLength: minLength(50),
    },
    pricePlan: {
      pricePlan: helpers.withMessage('Minimum price: 1 SAR, Maximum price: 120000 SAR', () => {
        return parseFloat(appPlanState.pricePlan) >= 1 && parseFloat(appPlanState.pricePlan) <= 120000;
      }),
    },
    trialDays: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    hasOneTimeFees: {},
    oneTimeFees: {
      required: helpers.withMessage(
        'Please enter the one time fees in SAR',
        requiredIf(() => appPlanState.hasOneTimeFees),
      ),
      valid: helpers.withMessage('Please enter a valid fee value', () => {
        return (
          (appPlanState.hasOneTimeFees && parseFloat(appPlanState.oneTimeFees) > 0) || !appPlanState.hasOneTimeFees
        );
      }),
    },
    planFeatures: {
      planFeatures: helpers.withMessage(
        'Only maximum of 5 lines allowed with 50 characters in each line.',
        validatePlanFeaturesEntry,
      ),
    },
    planFeaturesArabic: {
      planFeaturesArabic: helpers.withMessage(
        'Only maximum of 5 lines allowed with 50 characters in each line.',
        validateArabicPlanFeaturesEntry,
      ),
    },
    planDiscountOffer: {
      required: helpers.withMessage(
        'Provide a valid discount offer for plan',
        requiredIf(() => isOfferApplied.value && selectedPlanOfferType.value === PlanOfferTypeValuesEnum.DiscountOffer),
      ),
      planDiscountOffer: helpers.withMessage('Minimum discount: 1%, Maximum discount: 60%', (value: string) => {
        return (
          (parseFloat(appPlanState.planDiscountOffer) >= 1 && parseFloat(appPlanState.planDiscountOffer) <= 60) ||
          value === ''
        );
      }),
    },
  };

  const v$ = useVuelidate(rules, appPlanState);

  const durationsList = [
    { label: 'Free', value: PlanTypes.free },
    { label: '1 Month', value: '30' },
    { label: '2 Months', value: '60' },
    { label: '3 Months', value: '90' },
    { label: '4 Months', value: '120' },
    { label: '5 Months', value: '150' },
    { label: '6 Months', value: '180' },
    { label: '7 Months', value: '210' },
    { label: '8 Months', value: '240' },
    { label: '9 Months', value: '270' },
    { label: '10 Months', value: '300' },
    { label: '11 Months', value: '330' },
    { label: 'Yearly', value: '365' },
  ];

  const offerableDurationsList = computed(() => {
    return durationsList
      .filter((duration) => Number(duration.value) <= Number(appPlanState.planDuration.value))
      .slice(1);
  });

  const isAddOrEditPlanModalButtonDisabled = computed((): boolean => {
    if (appPlanState.planDuration.value === PlanTypes.free) {
      return (
        v$.value.planName.$invalid ||
        v$.value.planNameArabic.$invalid ||
        v$.value.planDuration.$invalid ||
        v$.value.planDescription.$invalid ||
        v$.value.planFeatures.$invalid
      );
    } else return v$.value.$invalid;
  });

  const togglePlanOffer = () => {
    isOfferApplied.value = !isOfferApplied.value;
    currentlySelectedPlanOfferID.value = 0;
    currentlySelectedPlanOfferPurchasableID.value = '';
    appPlanState.planOfferDuration = { label: '', value: '' };
    appPlanState.planDiscountOffer = '';
  };

  const onPlanOfferRadioClick = (plan: Record<string, PlanOfferTypeValuesEnum>) =>
    (selectedPlanOfferType.value = plan.value);

  const resetData = () => {
    isOfferApplied.value = false;
    currentlySelectedPlanID.value = 0;
    currentlySelectedPurchasableID.value = '';
    currentlySelectedPlanOfferID.value = 0;
    currentlySelectedPlanOfferPurchasableID.value = '';
    currentlySelectedPlanOneTimeFeePurchasableID.value = '';
    (appPlanState.planName = ''),
      (appPlanState.planNameArabic = ''),
      (appPlanState.planDuration = {
        label: '',
        value: '',
      });
    appPlanState.planDescription = '';
    appPlanState.pricePlan = '';
    appPlanState.trialDays = {
      label: '',
      value: '',
    };
    appPlanState.hasOneTimeFees = false;
    appPlanState.oneTimeFees = '';
    appPlanState.planFeatures = '';
  };

  const onInputDataChange = (changedValue: Record<string, string>, dataProperty: PlanInputPropertiesEnum) => {
    v$.value[dataProperty].$touch();
    appPlanState[dataProperty] = changedValue.value;
  };

  const onSelectionDataChange = (changedValue: Record<string, string>, property: PlanSelectionPropertiesEnum) => {
    v$.value[property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    appPlanState[property].label = selectedValue.label;
    appPlanState[property].value = selectedValue.value;
    if (property === PlanSelectionPropertiesEnum.PlanDuration && selectedValue.value === PlanTypes.free) {
      appPlanState.pricePlan = '0';
      appPlanState.trialDays = { label: '0', value: '0' };
    }
  };

  onBeforeMount(() => {
    if (!showShowAddPlanModal.value) toggleShowAddPlanModal();

    currentlySelectedPlanID.value = plan.id;
    currentlySelectedPurchasableID.value = plan.purchasable_id;

    const selectedPlan = plan;
    currentlySelectedPlanOfferID.value = selectedPlan?.offer?.id ?? 0;
    currentlySelectedPlanOfferPurchasableID.value = selectedPlan?.offer?.offer_purchasable_id ?? '';

    const selectedPlanDuration = () => {
      if (selectedPlan?.price === 0) return { label: 'Free', value: PlanTypes.free };
      else if (selectedPlan && selectedPlan.invoice_period % PlansInvoicePeriodEnum.monthly == 0)
        return {
          label: new String(selectedPlan.invoice_period / PlansInvoicePeriodEnum.monthly).concat(' Months'),
          value: selectedPlan.invoice_period.toString(),
        };
      else if (selectedPlan && selectedPlan.invoice_period === PlansInvoicePeriodEnum.yearly)
        return { label: 'Yearly', value: selectedPlan.invoice_period.toString() };
    };
    appPlanState.planName = selectedPlan?.name_en || '';
    appPlanState.planNameArabic = selectedPlan?.name_ar || '';
    appPlanState.planDuration = selectedPlanDuration() || { label: '', value: '' };
    appPlanState.planDescription = selectedPlan?.description_en || '';
    appPlanState.planDescriptionArabic = selectedPlan?.description_ar || '';
    appPlanState.pricePlan = `${selectedPlan?.price}` || '';
    isOfferApplied.value = !!selectedPlan?.offer?.offer_period;
    selectedPlanOfferType.value = selectedPlan?.plan_discount
      ? PlanOfferTypeValuesEnum.DiscountOffer
      : PlanOfferTypeValuesEnum.BuyXGetYOffer;
    appPlanState.planDiscountOffer = selectedPlan?.plan_discount || '';
    appPlanState.planOfferDuration = {
      label: durationsList.find((duration) => duration.value == selectedPlan?.offer?.offer_period)?.label ?? '',
      value: selectedPlan?.offer?.offer_period ?? '',
    };
    appPlanState.trialDays = {
      label: `${selectedPlan?.trial_period}`,
      value: `${selectedPlan?.trial_period}`,
    };
    currentlySelectedPlanOneTimeFeePurchasableID.value = selectedPlan?.one_time_fees_purchasable_id || '';
    appPlanState.hasOneTimeFees = selectedPlan?.has_one_time_fees || false;
    appPlanState.oneTimeFees = `${selectedPlan?.one_time_fees}` || '';
    appPlanState.planFeatures = selectedPlan?.features_en || '';
    appPlanState.planFeaturesArabic = selectedPlan?.features_ar || '';
  });

  const onSavePlansAndContinue = () => {
    if (showShowAddPlanModal) toggleShowAddPlanModal();
    resetData();
    v$.value.$reset();
  };

  const onAddOrEditPlanClicked = async () => {
    const oneLineFeaturesContainer = appPlanState.planFeatures.split('\n').join(' \n ');
    const oneLineArabicFeaturesContainer = appPlanState.planFeaturesArabic.split('\n').join(' \n ');
    const planInfo: Record<string, PlanInfoType> = {
      pricingPlans: {
        id: plan.id,
        purchasable_id: plan.purchasable_id,
        name_en: appPlanState.planName,
        name_ar: appPlanState.planNameArabic,
        description: appPlanState.planDescriptionArabic,
        description_en: appPlanState.planDescription,
        features: oneLineArabicFeaturesContainer,
        features_en: oneLineFeaturesContainer,
        price: `${appPlanState.pricePlan}`,
        duration: appPlanState.planDuration.value,
        currency: 'SAR',
        trial_period: Number(appPlanState.trialDays.value),
        one_time_fees_purchasable_id: currentlySelectedPlanOneTimeFeePurchasableID.value || null,
        has_one_time_fees: appPlanState.planDuration.value !== PlanTypes.free && appPlanState.hasOneTimeFees,
        ...(appPlanState.hasOneTimeFees &&
          appPlanState.planDuration.value !== PlanTypes.free && { one_time_fees: appPlanState.oneTimeFees }),
        offer: null,
      },
    };
    const offerData: PlanOfferType = {
      id: currentlySelectedPlanOfferID.value !== 0 ? currentlySelectedPlanOfferID.value : null,
      offer_purchasable_id:
        currentlySelectedPlanOfferPurchasableID.value !== '' ? currentlySelectedPlanOfferPurchasableID.value : null,
    };

    if (isOfferApplied.value) {
      if (selectedPlanOfferType.value === PlanOfferTypeValuesEnum.BuyXGetYOffer) {
        offerData.offer_period = appPlanState.planOfferDuration.value;
        offerData.offer_type = PlanOfferTypeLabelsEnum.BuyXGetY;
      } else {
        offerData.offer_period = '30';
        offerData.offer_discount = +appPlanState.planDiscountOffer;
        offerData.offer_type = PlanOfferTypeLabelsEnum.Discount;
      }
      planInfo.pricingPlans.offer = offerData;
    } else {
      offerData.offer_period = null;
      planInfo.pricingPlans.offer = offerData;
    }

    const response = await editApplicationPlan(planInfo, plan.app_id);
    if (response instanceof ErrorModel || !response.plan) {
      resetData();
      v$.value.$reset();
      Vue.$toast.error('Failed to add plan');
      return;
    }
    Vue.$toast.success('Plan has been edited successfully');
    resetData();
    v$.value.$reset();
    ctx.emit('onPlanEdited');
    toggleShowAddPlanModal();
  };

  const onCloseAddOrEditPlanModal = () => {
    resetData();
    v$.value.$reset();
    toggleShowAddPlanModal();
    ctx.emit('onClosePlanModal');
  };

  const toggleOneTimeFeesSwitch = () => {
    appPlanState.hasOneTimeFees = !appPlanState.hasOneTimeFees;
    if (!appPlanState.hasOneTimeFees) appPlanState.oneTimeFees = '';
  };

  const showMessagePlanEnArField = computed(() => {
    return (
      (v$.value.planName.$dirty || v$.value.planNameArabic.$dirty) &&
      (v$.value.planName.$invalid || v$.value.planNameArabic.$invalid)
    );
  });

  const showMessagePlanDescriptionEnArField = computed(() => {
    return (
      (v$.value.planDescription.$dirty || v$.value.planDescriptionArabic.$dirty) &&
      (v$.value.planDescription.$invalid || v$.value.planDescriptionArabic.$invalid)
    );
  });

  const showMessagePlanFeaturesEnArField = computed(() => {
    return (
      (v$.value.planFeatures.$dirty || v$.value.planFeaturesArabic.$dirty) &&
      (v$.value.planFeatures.$invalid || v$.value.planFeaturesArabic.$invalid)
    );
  });

  return {
    PlanTypes,
    appPlanState,
    durationsList,
    trialDaysList,
    isOfferApplied,
    offerableDurationsList,
    PlanInputPropertiesEnum,
    PlanSelectionPropertiesEnum,
    v$,
    isAddOrEditPlanModalButtonDisabled,
    showShowAddPlanModal,
    loadingDeletePlan,
    currentlySelectedPurchasableID,
    showMessagePlanEnArField,
    showMessagePlanDescriptionEnArField,
    PlansInvoicePeriodEnum,
    showMessagePlanFeaturesEnArField,
    selectedPlanOfferType,
    PlanOfferTypeValuesEnum,
    togglePlanOffer,
    onSelectionDataChange,
    onInputDataChange,
    toggleShowAddPlanModal,
    onSavePlansAndContinue,
    onAddOrEditPlanClicked,
    onPlanOfferRadioClick,
    validatePlanFeaturesEntry,
    onCloseAddOrEditPlanModal,
    toggleOneTimeFeesSwitch,
  };
};

export default useAppPlans;
