import Vue from 'vue';
import { reactive, computed, onBeforeMount, ref, watch } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { required, helpers, email, maxLength, minLength } from '@vuelidate/validators';
import { isValidURL } from '@/helpers/regex/URLs';
import { APP_CUSTOM_LOCATION_SCRIPTS, APP_LANGUAGES } from './dropdownInfo';
import {
  AppCustomeLocationEnum,
  AppCustomeLocationValuesEnum,
  AppLanguageEnum,
  AppLanguageValuesEnum,
  CategoryInterface,
  HeadscriptInterface,
  JSAppInputPropertiesEnum,
  JSAppSelectPropertiesEnum,
  JSAppStateInterface,
} from '@/domains/pd-admin/types/administration/apps-management/app-details/js-app-details.type';
import { ApplicationTypeProperty } from '@/api/models/ApplicationModel';
import { ARABIC_TEXT, ENGLISH_TEXT } from '@/helpers/regex/text';
import { getFileFromURL } from '@/api/top-level-apis/helpers/fileFromURL';
import { getAppDetails } from '@/domains/pd-admin/api/administration/apps-management/app-details-js/app-details-js';
import PlanModel from '@/domains/applications/models/PlanModel';
import {
  WebhookGroup,
  WebhookResponse,
} from '@/domains/pd-admin/types/administration/apps-management/app-details/app-webhooks';
import { PlansInvoicePeriodEnum } from '@/domains/pd-admin/types/administration/apps-management/app-details/oauth-app-details.type';
import router from '@/router';
import { RoutingRouteEnum } from '@/router/routes.enum';

const useJSAppDetails = (appID: number) => {
  const isInitialArabicScreenshotsLoading = ref(false);
  const isInitialEnglishScreenshotsLoading = ref(false);
  const isIconSpecsValid = ref(true);
  const isValidCoverPhoto = ref(true);
  const iconFileNameFromAPI = ref('');
  const coverPhotoFileNameFromAPI = ref('');
  const MAX_SCREENSHOTS_NUMBER = 10;
  const MIN_SCREENSHOTS_NUMBER = 1;
  const isArScreenshotsInvalid = ref(false);
  const isEnScreenshotsInvalid = ref(false);
  const showDeletePlanModal = ref(false);
  const loadingDeletePlan = ref(false);

  const originalArabicScreenshots = reactive<Record<string, File[]>>({ list: [] });
  const originalEnglishScreenshots = reactive<Record<string, File[]>>({ list: [] });
  const appPlans = reactive<Record<string, PlanModel[]>>({ list: [] });
  let existingWebhookGroups: Record<string, WebhookResponse[]> | null = null;
  const webhookSubscribtionList: Record<string, WebhookGroup[]> = reactive({ subscirbtionList: [] });

  const toggleDeletePlanModal = () => (showDeletePlanModal.value = !showDeletePlanModal.value);

  const jsAppState = reactive<JSAppStateInterface>({
    appNameArabic: '',
    appNameEnglish: '',
    appURL: '',
    appCategory: {
      label: '',
      value: '',
    },
    appLanguage: {
      label: '',
      value: '',
    },
    maintainerEmail: '',
    appType: ApplicationTypeProperty.Javascript,
    description: '',
    descriptionArabic: '',
    shortDesciption: '',
    shortDesciptionArabic: '',
    developerName: '',
    developerNameArabic: '',
    videoURL: '',
    appScreenshotsAr: [],
    appScreenshotsEn: [],
    iconFile: null,
    coverPhoto: null,
    isHTMLEnabled: false,
    // scripts
    customCode: '',
    updateCode: '',
    customLocation: {
      label: '',
      value: '',
    },
  });

  const rules = {
    appNameArabic: {
      required,
      appNameArabic: helpers.withMessage('من فضلك ادخل اسم التطبيق باللغة العربية والانجليزية', required),
      arabicContent: (value: string) => {
        return ARABIC_TEXT.test(value) || ENGLISH_TEXT.test(value);
      },
    },
    appNameEnglish: {
      required,
      appNameEnglish: helpers.withMessage('Application name is required in Arabic and English', required),
      englishContent: (value: string) => {
        return ARABIC_TEXT.test(value) || ENGLISH_TEXT.test(value);
      },
    },
    appURL: {
      required,
      appURL: helpers.withMessage('Application URL is required', (value: string) => {
        return isValidURL(value);
      }),
    },
    appCategory: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    appLanguage: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    maintainerEmail: {
      required,
      email,
      maintainerEmail: helpers.withMessage('E-mail is not valid', required),
    },
    appType: {},
    description: {
      required,
      description: helpers.withMessage('Description is required', maxLength(4000)),
    },
    descriptionArabic: {
      required,
      descriptionArabic: helpers.withMessage('من فضلك, اوصف التطبيق', maxLength(4000)),
    },
    shortDesciption: {
      required,
      shortDesciption: helpers.withMessage(
        'Short description exceeds the allowed size',
        maxLength(100) && minLength(1),
      ),
    },
    shortDesciptionArabic: {
      required,
      shortDesciptionArabic: helpers.withMessage(
        'الوصف المختصر يتخطى المساحة المسموحة',
        maxLength(100) && minLength(1),
      ),
    },
    developerName: {
      required,
      developerName: helpers.withMessage('Please developer name in English', (value: string) => {
        return ARABIC_TEXT.test(value) || ENGLISH_TEXT.test(value);
      }),
    },
    developerNameArabic: {
      required,
      developerNameArabic: helpers.withMessage('من فضلك, ادخل اسم المطور باللغة العربية', (value: string) => {
        return ARABIC_TEXT.test(value) || ENGLISH_TEXT.test(value);
      }),
    },
    videoURL: {
      videoURL: helpers.withMessage('Video URL is not valid', (value: string) => isValidURL(value) || value === ''),
    },
    appScreenshotsAr: {},
    appScreenshotsEn: {},
    iconFile: {
      required,
    },
    isHTMLEnabled: {},
    // scripts
    customCode: {},
    updateCode: {},
    customLocation: {
      label: {},
      value: {},
    },
  };

  const initialArabicScreenshots = reactive({
    list: jsAppState.appScreenshotsAr.map((screen: string | File) => {
      if (typeof screen === 'string') {
        return new File([], screen, {
          type: 'image/jpg',
        });
      } else return screen;
    }),
  });

  const initialEnglishScreenshots = reactive({
    list: jsAppState.appScreenshotsEn.map((screen: string | File) => {
      if (typeof screen === 'string') {
        return new File([], screen, {
          type: 'image/jpg',
        });
      } else return screen;
    }),
  });

  const appLanguages = reactive({
    options: APP_LANGUAGES,
  });

  const appCustomLocationScripts = reactive({
    options: APP_CUSTOM_LOCATION_SCRIPTS,
  });

  const appCategories = reactive<Record<string, { label: string; value: number }[]>>({
    options: [],
  });

  const headScripts = reactive<Record<string, HeadscriptInterface[]>>({
    list: [],
  });

  const v$ = useVuelidate(rules, jsAppState);

  onBeforeMount(async () => {
    const appDetailsRes = await getAppDetails(appID);

    if (!appDetailsRes.payload) {
      Vue.$toast.error('Failed to load app details');
    } else {
      if (appDetailsRes.payload.app.type !== ApplicationTypeProperty.Javascript) {
        Vue.$toast.error('Invalid app requested');
        router.push({ name: RoutingRouteEnum.Admin_AppsManagement }).catch(() => {
          //
        });
        return;
      }
      appCategories.options = [];
      const { categories } = appDetailsRes.payload;
      if (categories.length > 0) {
        categories.forEach((category: CategoryInterface) => {
          appCategories.options.push({ label: category.name_en, value: category.id });
        });
      } else Vue.$toast.error('Categories are empty');

      jsAppState.appNameEnglish = appDetailsRes.payload.app.name ?? '';
      jsAppState.appNameArabic = appDetailsRes.payload.app.name_ar ?? '';
      jsAppState.appURL = appDetailsRes.payload.app.app_url ?? '';

      jsAppState.appCategory = {
        ...appCategories.options.find((category) => category.value === appDetailsRes.payload.app.app_category_id),
      } ?? { label: '', value: '' };

      if (appDetailsRes.payload.app.language === AppLanguageValuesEnum.Arabic) {
        jsAppState.appLanguage = { label: AppLanguageEnum.Arabic, value: AppLanguageValuesEnum.Arabic };
      } else {
        jsAppState.appLanguage = { label: AppLanguageEnum.English, value: AppLanguageValuesEnum.English };
      }

      jsAppState.maintainerEmail = appDetailsRes.payload.app.developer.email ?? '';
      jsAppState.appType = appDetailsRes.payload.app.type ?? '';
      jsAppState.description = appDetailsRes.payload.app.description ?? '';
      jsAppState.descriptionArabic = appDetailsRes.payload.app.description_ar ?? '';
      jsAppState.shortDesciption = appDetailsRes.payload.app.short_description ?? '';
      jsAppState.shortDesciptionArabic = appDetailsRes.payload.app.short_description_ar ?? '';
      jsAppState.developerName = appDetailsRes.payload.app.developer_name_en ?? '';
      jsAppState.developerNameArabic = appDetailsRes.payload.app.developer_name_ar ?? '';
      jsAppState.videoURL = appDetailsRes.payload.app.video ?? '';

      jsAppState.appScreenshotsAr = appDetailsRes.payload.app.screen_shots_ar?.length
        ? appDetailsRes.payload.app.screen_shots_ar.split(',')
        : [];
      jsAppState.appScreenshotsEn = appDetailsRes.payload.app.screen_shots_en?.length
        ? appDetailsRes.payload.app.screen_shots_en.split(',')
        : [];
      jsAppState.iconFile = appDetailsRes.payload.app.icon ?? null;
      iconFileNameFromAPI.value =
        appDetailsRes.payload.app.icon instanceof File
          ? URL.createObjectURL(appDetailsRes.payload.app.icon)
          : appDetailsRes.payload.app.icon;
      jsAppState.coverPhoto = appDetailsRes.payload.app.cover_photo ?? null;
      coverPhotoFileNameFromAPI.value =
        appDetailsRes.payload.app.cover_photo instanceof File
          ? URL.createObjectURL(appDetailsRes.payload.app.cover_photo)
          : appDetailsRes.payload.app.cover_photo;
      jsAppState.isHTMLEnabled = appDetailsRes.payload.app.is_html_enabled;
      // scripts
      jsAppState.customCode = `${appDetailsRes.payload.app.unique_id}_params`;
      headScripts.list = [...appDetailsRes.payload.headScripts];
      jsAppState.updateCode = appDetailsRes.payload.headScripts.length
        ? appDetailsRes.payload.headScripts[0].head_scripts_code
        : '';
      if (
        !appDetailsRes.payload.headScripts ||
        (appDetailsRes.payload.headScripts.length && appDetailsRes.payload.headScripts[0].location === null)
      ) {
        jsAppState.customLocation = {
          label: AppCustomeLocationEnum.Global,
          value: AppCustomeLocationValuesEnum.Global,
        };
      } else {
        if (appDetailsRes.payload.headScripts[0]) {
          jsAppState.customLocation = {
            ...appCustomLocationScripts.options.find(
              (location) => location.value === appDetailsRes.payload.headScripts[0].location,
            ),
          } ?? { label: '', value: '' };
        } else {
          jsAppState.customLocation = { label: '', value: '' };
        }
      }

      existingWebhookGroups = appDetailsRes.payload.webhooks ?? null;
      if (existingWebhookGroups) {
        webhookSubscribtionList.subscirbtionList = [];
        updateCurrentSupscriptionList();
      }
      appPlans.list = appDetailsRes.payload.plans ?? [];

      v$.value.$validate();
    }

    isInitialArabicScreenshotsLoading.value = true;
    originalArabicScreenshots.list = await getArabicScreenshotsFiles();
    (initialArabicScreenshots.list = jsAppState.appScreenshotsAr.map((screen: string | File) => {
      if (typeof screen === 'string') {
        return new File([], screen, {
          type: 'image/jpg',
        });
      } else return screen;
    })),
      (isInitialArabicScreenshotsLoading.value = false);

    isInitialEnglishScreenshotsLoading.value = true;
    originalEnglishScreenshots.list = await getEnglishScreenshotsFiles();
    (initialEnglishScreenshots.list = jsAppState.appScreenshotsEn.map((screen: string | File) => {
      if (typeof screen === 'string') {
        return new File([], screen, {
          type: 'image/jpg',
        });
      } else return screen;
    })),
      (isInitialEnglishScreenshotsLoading.value = false);
  });

  const updateCurrentSupscriptionList = () => {
    if (!existingWebhookGroups) return;
    for (const [key, value] of Object.entries(existingWebhookGroups)) {
      webhookSubscribtionList.subscirbtionList.push({
        group_id: key,
        webhooks: value,
      });
    }
  };

  const onInputDataChange = (changedValue: Record<string, string>, dataProperty: JSAppInputPropertiesEnum) => {
    v$.value[dataProperty].$touch();
    jsAppState[dataProperty] = changedValue.value;
  };

  const onSelectionDataChange = (changedValue: Record<string, string>, property: JSAppSelectPropertiesEnum) => {
    v$.value[property].$touch();
    const selectedValue = { ...changedValue };
    jsAppState[property].label = selectedValue.label;
    jsAppState[property].value = selectedValue.value;
  };

  const onDescriptionChange = (description: string) => {
    jsAppState.description = description;
  };

  const onDescriptionArabicChange = (descriptionArabic: string) => {
    jsAppState.descriptionArabic = descriptionArabic;
  };

  watch(
    () => jsAppState.customLocation,
    (newCustomLocation) => {
      const emptyScript: HeadscriptInterface = {
        head_scripts_code: '',
        id: -1,
        location: AppCustomeLocationValuesEnum.Global,
      };
      let selectedScript: HeadscriptInterface;
      if (newCustomLocation.value === AppCustomeLocationValuesEnum.Global) {
        selectedScript = headScripts.list.find((script) => script.location === null) ?? emptyScript;
      } else {
        selectedScript = headScripts.list.find((script) => script.location === newCustomLocation.value) ?? emptyScript;
      }
      jsAppState.updateCode = selectedScript?.head_scripts_code ?? '';
    },
    { deep: true },
  );

  const onIconSelection = (fileInputEvent: Event) => {
    const input = fileInputEvent.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }
    const appIcon = new Image();
    appIcon.src = URL.createObjectURL(input.files[0]);

    appIcon.onerror = () => {
      isIconSpecsValid.value = false;
      return;
    };

    appIcon.onload = () => {
      if (appIcon.width !== appIcon.height || appIcon.width > 250 || appIcon.height > 250) {
        isIconSpecsValid.value = false;
        return;
      }
      if (input.files) {
        jsAppState.iconFile = input.files[0];
        iconFileNameFromAPI.value = URL.createObjectURL(input.files[0]);
        isIconSpecsValid.value = true;
      }
    };
  };

  const onCoverPhotoSelection = (fileInputEvent: Event) => {
    const input = fileInputEvent.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }
    const coverPhoto = new Image();
    coverPhoto.src = URL.createObjectURL(input.files[0]);

    coverPhoto.onerror = () => {
      isValidCoverPhoto.value = false;
      return;
    };

    coverPhoto.onload = () => {
      if (Number((coverPhoto.width / coverPhoto.height).toFixed(1)) != 0.9) {
        isValidCoverPhoto.value = false;
        return;
      }
      if (input.files) {
        jsAppState.coverPhoto = input.files[0];
        coverPhotoFileNameFromAPI.value = URL.createObjectURL(input.files[0]);
        isValidCoverPhoto.value = true;
      }
    };
  };

  const getArabicScreenshotsFiles = async () => {
    return Promise.all(
      jsAppState.appScreenshotsAr.map(async (screen) => {
        if (typeof screen === 'string') {
          const sc = await getFileFromURL(screen, screen, 'image/jpg');
          return sc;
        } else return screen;
      }),
    )
      .then((values) => {
        return values;
      })
      .catch(() => {
        initialArabicScreenshots.list = [];
        return [];
      });
  };

  const getEnglishScreenshotsFiles = async () => {
    return Promise.all(
      jsAppState.appScreenshotsEn.map(async (screen) => {
        if (typeof screen === 'string') {
          const sc = await getFileFromURL(screen, screen, 'image/jpg');
          return sc;
        } else return screen;
      }),
    )
      .then((values) => {
        return values;
      })
      .catch(() => {
        initialEnglishScreenshots.list = [];
        return [];
      });
  };

  const onArabicScreenshotsChange = (images: File[]) => {
    const newImages = images.filter(
      (screen) =>
        originalArabicScreenshots.list.findIndex((originalScreen) => originalScreen.name === screen.name) == -1,
    );

    originalArabicScreenshots.list = [...originalArabicScreenshots.list, ...newImages];

    const areFiles = originalArabicScreenshots.list.every((file) => file.type.includes('image/'));

    if (images.length > MAX_SCREENSHOTS_NUMBER || images.length < MIN_SCREENSHOTS_NUMBER || !areFiles) {
      isArScreenshotsInvalid.value = true;
    } else {
      isArScreenshotsInvalid.value = false;
    }
  };

  const onArabicScreenshotsRemove = (image: File) => {
    const screenIndex = originalArabicScreenshots.list.findIndex((screen) => {
      return screen.name === image.name;
    });

    if (screenIndex !== -1) {
      originalArabicScreenshots.list.splice(screenIndex, 1);
      const areFiles = originalArabicScreenshots.list.every((file) => file.type.includes('image/'));

      if (
        originalArabicScreenshots.list.length > MAX_SCREENSHOTS_NUMBER ||
        originalArabicScreenshots.list.length < MIN_SCREENSHOTS_NUMBER ||
        !areFiles
      ) {
        isArScreenshotsInvalid.value = true;
      } else {
        isArScreenshotsInvalid.value = false;
      }
    }
  };

  const onEnglishScreenshotsChange = (images: File[]) => {
    const newImages = images.filter(
      (screen) =>
        originalEnglishScreenshots.list.findIndex((originalScreen) => originalScreen.name === screen.name) == -1,
    );

    originalEnglishScreenshots.list = [...originalEnglishScreenshots.list, ...newImages];
    const areFiles = originalEnglishScreenshots.list.every((file) => file.type.includes('image/'));

    if (images.length > MAX_SCREENSHOTS_NUMBER || !areFiles) {
      isEnScreenshotsInvalid.value = true;
    } else {
      isEnScreenshotsInvalid.value = false;
    }
    return [];
  };

  const onEnglishScreenshotsRemove = (image: File) => {
    const screenIndex = originalEnglishScreenshots.list.findIndex((screen) => {
      return screen.name === image.name;
    });

    if (screenIndex !== -1) {
      originalEnglishScreenshots.list.splice(screenIndex, 1);
      const areFiles = originalEnglishScreenshots.list.every((file) => file.type.includes('image/'));

      if (originalEnglishScreenshots.list.length > MAX_SCREENSHOTS_NUMBER || !areFiles) {
        isEnScreenshotsInvalid.value = true;
      } else {
        isEnScreenshotsInvalid.value = false;
      }
    }
  };

  const onRemoveIconClicked = () => {
    jsAppState.iconFile = null;
    iconFileNameFromAPI.value = '';
  };

  const onRemoveCoverPhotoClicked = () => {
    jsAppState.coverPhoto = null;
    coverPhotoFileNameFromAPI.value = '';
  };

  const planDuration = (plan: PlanModel) => {
    if (plan?.price === 0) return '-';
    else if (plan && plan.invoice_period % PlansInvoicePeriodEnum.monthly == 0)
      return new String(plan.invoice_period / PlansInvoicePeriodEnum.monthly).concat(' Months');
    else if (plan && plan.invoice_period === PlansInvoicePeriodEnum.yearly) return plan.invoice_period.toString();
  };

  const showMessageForArabicEnglishField = computed(() => {
    return (
      (v$.value.appNameArabic.$dirty || v$.value.appNameEnglish.$dirty) &&
      (v$.value.appNameArabic.$invalid || v$.value.appNameEnglish.$invalid)
    );
  });

  const appTypeProprety = computed(() => {
    if (jsAppState.appType === ApplicationTypeProperty.Javascript) return 'Javascript Application';
    else return 'OAuth Application';
  });

  const showMessageForDescriptionArEnField = computed(() => {
    return (
      v$.value.descriptionArabic.$invalid ||
      v$.value.description.$invalid ||
      ((v$.value.shortDesciptionArabic.$dirty || v$.value.shortDesciption.$dirty) &&
        v$.value.shortDesciptionArabic.$invalid) ||
      v$.value.shortDesciption.$invalid
    );
  });

  const showMessageDeveloperNameField = computed(() => {
    return (
      (v$.value.developerName.$dirty || v$.value.developerNameArabic.$dirty) &&
      (v$.value.developerName.$invalid || v$.value.developerNameArabic.$invalid)
    );
  });

  const areArabicFilesValid = computed(() => originalArabicScreenshots.list.length > 0);

  const isSaveDisabled = computed(() => {
    const isIconValid = () => jsAppState.iconFile instanceof File || typeof jsAppState.iconFile === 'string';
    return (
      v$.value.$invalid ||
      !isIconValid() ||
      isArScreenshotsInvalid.value ||
      isEnScreenshotsInvalid.value ||
      !isValidCoverPhoto.value
    );
  });

  const isSubmitScriptsDisabled = computed(() => {
    return v$.value.customCode.$invalid || v$.value.updateCode.$invalid || v$.value.customLocation.$invalid;
  });

  return {
    jsAppState,
    appLanguages,
    v$,
    appPlans,
    showMessageForArabicEnglishField,
    JSAppInputPropertiesEnum,
    JSAppSelectPropertiesEnum,
    appTypeProprety,
    appCategories,
    appCustomLocationScripts,
    showMessageForDescriptionArEnField,
    showMessageDeveloperNameField,
    areArabicFilesValid,
    isSaveDisabled,
    isInitialArabicScreenshotsLoading,
    isInitialEnglishScreenshotsLoading,
    isArScreenshotsInvalid,
    isEnScreenshotsInvalid,
    initialArabicScreenshots,
    initialEnglishScreenshots,
    isIconSpecsValid,
    isValidCoverPhoto,
    iconFileNameFromAPI,
    coverPhotoFileNameFromAPI,
    isSubmitScriptsDisabled,
    showDeletePlanModal,
    loadingDeletePlan,
    originalArabicScreenshots,
    originalEnglishScreenshots,
    headScripts,
    webhookSubscribtionList,
    onArabicScreenshotsChange,
    onArabicScreenshotsRemove,
    onEnglishScreenshotsChange,
    onEnglishScreenshotsRemove,
    planDuration,
    onInputDataChange,
    onSelectionDataChange,
    onDescriptionChange,
    onDescriptionArabicChange,
    onIconSelection,
    onCoverPhotoSelection,
    onRemoveIconClicked,
    onRemoveCoverPhotoClicked,
    toggleDeletePlanModal,
  };
};

export default useJSAppDetails;
