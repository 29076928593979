import Vue from 'vue';
import { defineComponent, ref, onBeforeMount } from '@vue/composition-api';
import {
  ZidBreadcrumb,
  ZidBreadcrumbItem,
  ZidInput,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidTextarea,
  ZidButton,
  ZidCheckbox,
  ZidLoader,
} from '@zidsa/ui';
import BaseText from '@/components/text/BaseText.vue';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import GeneralData from './shipping-app-sections/general-data/GeneralData.vue';
import TermsAndCoverage from './shipping-app-sections/terms-and-coverage/TermsAndCoverage.vue';
import ShippingOptions from './shipping-app-sections/shipping-options/ShippingOptions.vue';
import WebhooksManagement from './shipping-app-sections/webhooks-management/WebhooksManagement.vue';
import OAuthDetails from './shipping-app-sections/oauth-details/OAuthDetails.vue';
import AppManagement from '../../app-management/AppManagement.vue';

import {
  apiKeysInterface,
  ShippingAppDetailsRoutesEnum,
  ShippingAppGeneralDataInterface,
  TermsAndCoverageDataInterface,
} from '@/domains/pd-admin/types/administration/apps-management/app-details/shipping-app-details.type';
import { ApplicationTypeProperty } from '@/api/models/ApplicationModel';
import { getShippingApplicationData } from '@/domains/pd-admin/api/administration/apps-management/app-details-shipping/app-details-shipping';
import ShippingApplicationModel from '@/api/models/ShippingApplicationModel';
import ErrorModel from '@/api/models/ErrorModel';
import { COMPANY_TYPES } from '@/domains/shipping/helper/dropdownInfo';
export default defineComponent({
  components: {
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidTextarea,
    ZidButton,
    ZidCheckbox,
    ZidLoader,
    BaseText,
    TabMultiLanguage,
    GeneralData,
    TermsAndCoverage,
    ShippingOptions,
    WebhooksManagement,
    OAuthDetails,
    AppManagement,
  },
  setup(_, ctx) {
    const appId = Number(ctx.root.$route.params.id);
    const savedPricingModel = ref(1);
    const contractLink = ref('');
    const activeSection = ref('app_details');
    const partnerId = ref(-1);

    const shippingAppTermsAndCoverage: TermsAndCoverageDataInterface = {
      pickup: {
        countries: null,
        cities: null,
      },
      delivery: {
        countries: null,
        cities: null,
      },
      paymentDueDateEnglish: '',
      paymentDueDateArabic: '',
      developerNameEnglish: '',
      developerNameArabic: '',
      shortDescriptionEnglish: '',
      shortDescriptionArabic: '',
      receivingOrdersTimesEnglish: '',
      receivingOrdersTimesArabic: '',
      orderDeliveryTimesEnglish: '',
      orderDeliveryTimesArabic: '',
      shippingWeightEnglish: '',
      shippingWeightArabic: '',
      appScreenshotsAr: [],
      appScreenshotsEn: [],
    };
    const isLoadingAppDetails = ref(false);

    const shippingAppGeneralData: ShippingAppGeneralDataInterface = {
      appCompanyNameArabic: '',
      appCompanyNameEnglish: '',
      companyURL: '',
      technicalSupportEmail: '',
      mobileNumber: '',
      typeOfCompany: {
        label: COMPANY_TYPES[0].label,
        value: COMPANY_TYPES[0].value,
      },
      aboutCompanyEnglish: '',
      aboutCompanyArabic: '',
      companyLogo: '',
      coverPhoto: '',
      services: '',
    };

    const shippingAppApiKeys: apiKeysInterface = {
      clientId: '',
      clientSecret: '',
      callbackUrl: '',
      redirectUrl: '',
    };

    onBeforeMount(async () => {
      await loadShippingAppDetails();
    });

    const loadShippingAppDetails = async () => {
      isLoadingAppDetails.value = true;
      if (Number.isNaN(appId)) {
        Vue.$toast.error('Invalid app id supplied');
        isLoadingAppDetails.value = false;
        return;
      }

      const shippingAppDetailsResponse = await getShippingApplicationData(appId);
      if (shippingAppDetailsResponse instanceof ErrorModel || !shippingAppDetailsResponse.payload) {
        Vue.$toast.error('Failed to load App details');
        isLoadingAppDetails.value = false;
        return;
      }
      const shippingAppDetails = shippingAppDetailsResponse.payload.app;
      shippingAppGeneralData.appCompanyNameArabic = shippingAppDetails.name_ar;
      shippingAppGeneralData.appCompanyNameEnglish = shippingAppDetails.name_en;
      shippingAppGeneralData.companyURL = shippingAppDetails.url;
      shippingAppGeneralData.technicalSupportEmail = shippingAppDetails.email;
      shippingAppGeneralData.mobileNumber = shippingAppDetails.mobile;
      shippingAppGeneralData.aboutCompanyArabic = shippingAppDetails.description_ar ?? '';
      shippingAppGeneralData.aboutCompanyEnglish = shippingAppDetails.description_en ?? '';
      shippingAppGeneralData.companyLogo = shippingAppDetails.icon;
      shippingAppGeneralData.coverPhoto = shippingAppDetails.cover_photo;
      shippingAppGeneralData.typeOfCompany.label =
        COMPANY_TYPES[
          shippingAppDetails.shipping_type != undefined
            ? COMPANY_TYPES.findIndex((x) => x.value == `${shippingAppDetails?.shipping_type}`)
            : 0
        ].label;
      shippingAppGeneralData.typeOfCompany.value =
        COMPANY_TYPES[
          shippingAppDetails.shipping_type != undefined
            ? COMPANY_TYPES.findIndex((x) => x.value == `${shippingAppDetails?.shipping_type}`)
            : 0
        ].value;
      // Note: services property comes as an array from the backend, however it is declared as
      // a string in ShippingApplicationModel
      shippingAppGeneralData.services = shippingAppDetails.services?.toString() ?? '';
      shippingAppApiKeys.clientSecret = shippingAppDetails.oauth_keys?.client_secret ?? '';
      shippingAppApiKeys.clientId = shippingAppDetails.oauth_keys?.client_id ?? '';
      shippingAppApiKeys.callbackUrl = shippingAppDetails.callback_url ?? '';
      shippingAppApiKeys.redirectUrl = shippingAppDetails.redirect_url ?? '';

      fillTermsAndCoverageState(shippingAppDetails);
      savedPricingModel.value = Number(shippingAppDetails.pricing_model);
      contractLink.value = shippingAppDetails.contract_link ?? '';
      partnerId.value = shippingAppDetails.partner_id;
      isLoadingAppDetails.value = false;
    };

    const fillTermsAndCoverageState = (app: ShippingApplicationModel) => {
      if (app.coverage?.pickup_countries) {
        shippingAppTermsAndCoverage.pickup.countries = [];
        Object.entries(app.coverage.pickup_countries).forEach((country: any) => {
          shippingAppTermsAndCoverage.pickup.countries?.push({
            label: `${country[1].name.en} - ${country[1].name.ar}`,
            value: Number(country[0]),
          });
        });
      }
      if (app.coverage?.pickup_cities) {
        shippingAppTermsAndCoverage.pickup.cities = [];
        Object.entries(app.coverage.pickup_cities).forEach((city: any) => {
          shippingAppTermsAndCoverage.pickup.cities?.push({
            label: `${city[1].name.en} - ${city[1].name.ar}`,
            value: Number(city[0]),
            country_id: Number(city[1].country_id),
          });
        });
      }
      if (app.coverage?.delivery_countries) {
        shippingAppTermsAndCoverage.delivery.countries = [];
        Object.entries(app.coverage.delivery_countries).forEach((country: any) => {
          shippingAppTermsAndCoverage.delivery.countries?.push({
            label: `${country[1].name.en} - ${country[1].name.ar}`,
            value: Number(country[0]),
          });
        });
      }
      if (app.coverage?.delivery_cities) {
        shippingAppTermsAndCoverage.delivery.cities = [];
        Object.entries(app.coverage.delivery_cities).forEach((city: any) => {
          shippingAppTermsAndCoverage.delivery.cities?.push({
            label: `${city[1].name.en} - ${city[1].name.ar}`,
            value: Number(city[0]),
            country_id: Number(city[1].country_id),
          });
        });
      }
      shippingAppTermsAndCoverage.receivingOrdersTimesArabic = app.times_to_pickup_ar ?? '';
      shippingAppTermsAndCoverage.receivingOrdersTimesEnglish = app.times_to_pickup_en ?? '';
      shippingAppTermsAndCoverage.orderDeliveryTimesArabic = app.times_to_deliver_ar ?? '';
      shippingAppTermsAndCoverage.orderDeliveryTimesEnglish = app.times_to_deliver_en ?? '';
      shippingAppTermsAndCoverage.shippingWeightArabic = app.weight_details_ar ?? '';
      shippingAppTermsAndCoverage.shippingWeightEnglish = app.weight_details_en ?? '';
      shippingAppTermsAndCoverage.paymentDueDateArabic = app.payment_due_ar ?? '';
      shippingAppTermsAndCoverage.paymentDueDateEnglish = app.payment_due_en ?? '';
      shippingAppTermsAndCoverage.developerNameArabic = app.developer_name_ar ?? '';
      shippingAppTermsAndCoverage.developerNameEnglish = app.developer_name_en ?? '';
      shippingAppTermsAndCoverage.shortDescriptionArabic = app.short_description_ar ?? '';
      shippingAppTermsAndCoverage.shortDescriptionEnglish = app.short_description_en ?? '';
      shippingAppTermsAndCoverage.appScreenshotsAr = app.screen_shots_ar ? app.screen_shots_ar.split(',') : [];
      shippingAppTermsAndCoverage.appScreenshotsEn = app.screen_shots_en ? app.screen_shots_en.split(',') : [];
    };

    const showSection = (section: 'app_details' | 'app_management') => {
      activeSection.value = section;
    };

    return {
      appId,
      ShippingAppDetailsRoutesEnum,
      ApplicationTypeProperty,
      isLoadingAppDetails,
      shippingAppApiKeys,
      shippingAppGeneralData,
      shippingAppTermsAndCoverage,
      savedPricingModel,
      contractLink,
      activeSection,
      partnerId,
      showSection,
    };
  },
});
