import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import AppPlanUpdateResponseModel from '@/domains/pd-admin/models/administration/apps-management/app-plan/app-plan-update-response-model';
import DeleteAppPlanResponseModel from '@/domains/pd-admin/models/administration/apps-management/app-plan/delete-app-plan-response-model';
import { PlanInfoType } from '@/domains/pd-admin/types/administration/apps-management/app-details/app-plans.type';

const ENDPOINT_URL = '/market';

export const editApplicationPlan = (
  planInfo: Record<string, PlanInfoType>,
  appID: number,
): Promise<AppPlanUpdateResponseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/update/${appID}/plan`, planInfo)
    .then((response) => new AppPlanUpdateResponseModel(response))
    .catch((error) => error);
};

export const deletePlan = (
  planPurchasableID: string,
  appID: number,
): Promise<DeleteAppPlanResponseModel | ErrorModel> => {
  return httpClient
    .delete(`${ENDPOINT_URL}/delete/${appID}/plan`, {
      data: {
        pricingPlans: {
          purchasable_id: planPurchasableID,
        },
      },
    })
    .then((response) => new DeleteAppPlanResponseModel(response))
    .catch((error) => error);
};
