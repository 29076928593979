import {
  RevenueModelEnum,
  RevenueModelValuesEnum,
  VATEnum,
  VATValuesEnum,
} from '@/domains/pd-admin/types/administration/apps-management/app-management/partnership';

export const REVENUE_MODEL = [
  { label: RevenueModelEnum.FixedPrice, value: RevenueModelValuesEnum.FixedPrice },
  { label: RevenueModelEnum.PercentageOfRevenue, value: RevenueModelValuesEnum.PercentageOfRevenue },
  { label: RevenueModelEnum.Slap, value: RevenueModelValuesEnum.Slap },
];

export const VAT = [
  { label: VATEnum.Include, value: VATValuesEnum.Include },
  { label: VATEnum.Exclude, value: VATValuesEnum.Exclude },
];
