import BaseModel from '@/api/models/BaseModel';
import { PackageDataInterface } from '@/domains/pd-admin/types/administration/apps-management/app-management/packages';

class AppPackagesResponseModel extends BaseModel {
  public packages: PackageDataInterface[];

  constructor(arg: any) {
    super(arg);
    this.packages = arg.packages;
  }
}

export default AppPackagesResponseModel;
