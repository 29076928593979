import { defineComponent } from '@vue/composition-api';
import {
  ZidButton,
  ZidCheckbox,
  ZidFileSelector,
  ZidInput,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidTextarea,
  ZidLoader,
} from '@zidsa/ui';
import BaseText from '@/components/text/BaseText.vue';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import Multiselect from 'vue-multiselect';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import useTermsAndCoverage from '@/domains/pd-admin/helpers/administration/apps-management/app-details/shipping-app-details/useTermsAndCoverage';

export default defineComponent({
  components: {
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidTextarea,
    ZidButton,
    ZidCheckbox,
    ZidFileSelector,
    ZidLoader,
    BaseText,
    TabMultiLanguage,
    Multiselect,
    BaseIcon,
    SearchIcon,
  },
  props: {
    appId: {
      type: Number,
      required: true,
    },
    shippingAppModel: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return {
      ...useTermsAndCoverage(props.appId, props.shippingAppModel),
    };
  },
});
