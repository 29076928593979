import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import ShippingCustomizationsResponseModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/ShippingCustomizationsResponseModel';
import SingleShippingCustomizationResponseModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/SingleShippingCustomizationResponseModel';

const ENDPOINT_URL = '/partners/shipping/courier';

export const getShippingCustomizations = (appId: number): Promise<ShippingCustomizationsResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/${appId}/customizations`)
    .then((response) => new ShippingCustomizationsResponseModel(response))
    .catch((error) => error);
};

export const getSingleShippingCustomization = (appId: number, customizationId: number) => {
  return httpClient
    .get(`${ENDPOINT_URL}/${appId}/customizations/${customizationId}`)
    .then((response) => new SingleShippingCustomizationResponseModel(response))
    .catch((error) => error);
};
