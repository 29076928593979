import { render, staticRenderFns } from "./OAuthAppDetails.vue?vue&type=template&id=1c95bd5d&scoped=true&"
import script from "./oAuthAppDetails.js?vue&type=script&lang=ts&"
export * from "./oAuthAppDetails.js?vue&type=script&lang=ts&"
import style0 from "./oAuthAppDetails.scss?vue&type=style&index=0&id=1c95bd5d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c95bd5d",
  null
  
)

export default component.exports