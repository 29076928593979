import BaseModel from '@/api/models/BaseModel';
import PlanModel from '@/domains/applications/models/PlanModel';

class AppPlanUpdateResponseModel extends BaseModel {
  public plan: PlanModel;

  constructor(arg: any) {
    super(arg);
    this.plan = arg.plan;
  }
}

export default AppPlanUpdateResponseModel;
