import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import CoverageCountriesResponseModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/CoverageCountriesResponseModel';
import CoverageCitiesResponseModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/CoverageCitiesResponseModel';

const ENDPOINT_URL = 'partners/shipping/couriers';

export const getShippingCustomizationsCoverageCountries = (
  appId: number,
): Promise<CoverageCountriesResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/${appId}/available-customization-countries`)
    .then((response) => new CoverageCountriesResponseModel(response))
    .catch((error) => error);
};

export const getShippingCustomizationsCoverageCities = (
  appId: number,
  countries: (number | null)[],
): Promise<CoverageCitiesResponseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/${appId}/available-customization-cities`, { countries: countries })
    .then((response) => new CoverageCitiesResponseModel(response))
    .catch((error) => error);
};
