import {
  AppStatusDropdownEnum,
  AppStatusDropdownValuesEnum,
  AppTypeDropdownEnum,
  AppTypeDropdownValuesEnum,
} from '../../../types/administration/apps-management/apps-management.type';

export const APPLICATION_STATUS = [
  { label: AppStatusDropdownEnum.AllStatus, value: AppStatusDropdownValuesEnum.AllStatus },
  { label: AppStatusDropdownEnum.Published, value: AppStatusDropdownValuesEnum.Published },
  { label: AppStatusDropdownEnum.Unpublished, value: AppStatusDropdownValuesEnum.Unpublished },
  { label: AppStatusDropdownEnum.InReview, value: AppStatusDropdownValuesEnum.InReview },
  { label: AppStatusDropdownEnum.InDraft, value: AppStatusDropdownValuesEnum.InDraft },
];

export const APPLICATION_TYPE = [
  { label: AppTypeDropdownEnum.AllTypes, value: AppTypeDropdownValuesEnum.AllTypes },
  { label: AppTypeDropdownEnum.OAuth, value: AppTypeDropdownValuesEnum.OAuth },
  { label: AppTypeDropdownEnum.Shipping, value: AppTypeDropdownValuesEnum.Shipping },
  { label: AppTypeDropdownEnum.Javascript, value: AppTypeDropdownValuesEnum.Javascript },
];
