import {
  AppStatusDropdownEnum,
  AppStatusDropdownValuesEnum,
} from '@/domains/pd-admin/types/administration/apps-management/app-management/visibility';

export const APP_STATUS = [
  { label: AppStatusDropdownEnum.Published, value: AppStatusDropdownValuesEnum.Published },
  { label: AppStatusDropdownEnum.Unpublished, value: AppStatusDropdownValuesEnum.Unpublished },
  { label: AppStatusDropdownEnum.Rejected, value: AppStatusDropdownValuesEnum.Rejected },
];
