import { defineComponent, ref } from '@vue/composition-api';
import usePartnershipControl from '@/domains/pd-admin/helpers/administration/apps-management/app-management/control-partnership/usePartnershipControl';
import BaseText from '@/components/text/BaseText.vue';
import {
  ZidCheckbox,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidInput,
  ZidButton,
  ZidLoader,
} from '@zidsa/ui';
export default defineComponent({
  components: {
    BaseText,
    ZidCheckbox,
    ZidSelect,
    ZidSelectBody,
    ZidSelectHeader,
    ZidSelectOption,
    ZidInput,
    ZidButton,
    ZidLoader,
  },
  props: {
    partnerID: {
      type: Number,
    },
  },
  setup(props, ctx) {
    const appID = ref(Number(ctx.root.$route.params.id));
    const partnerID = ref(Number(props.partnerID) || -1);
    return { ...usePartnershipControl(partnerID.value, appID.value) };
  },
});
