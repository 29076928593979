import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import ShippingAppUpdateResponseModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/ShippingAppUpdateResponseModel';
import ShippingAppTermsAndCoverageModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/ShippingAppTermsAndCoverageModel';

const ENDPOINT_URL = '/market/app/create-shippings';

export const updateAppTermsAndCoverage = (
  shippingAppInfo: ShippingAppTermsAndCoverageModel,
  appId: number,
): Promise<ShippingAppUpdateResponseModel | ErrorModel> => {
  const appData = new FormData();
  appData.append('app_id', `${appId}`);
  appData.append('step', '3');
  appData.append('locations_from', shippingAppInfo.locations_from.toString());
  appData.append('locations_to', shippingAppInfo.locations_to.toString());
  appData.append('payment_due_en', shippingAppInfo.payment_due_en);
  appData.append('payment_due_ar', shippingAppInfo.payment_due_ar);
  appData.append('times_to_pickup_en', shippingAppInfo.times_to_pickup_en);
  appData.append('times_to_pickup_ar', shippingAppInfo.times_to_pickup_ar);
  appData.append('times_to_deliver_en', shippingAppInfo.times_to_deliver_en);
  appData.append('times_to_deliver_ar', shippingAppInfo.times_to_deliver_ar);
  appData.append('weight_details_en', shippingAppInfo.weight_details_en);
  appData.append('weight_details_ar', shippingAppInfo.weight_details_ar);
  appData.append('short_description_en', shippingAppInfo.short_description_en);
  appData.append('short_description_ar', shippingAppInfo.short_description_ar);
  appData.append('developer_name_en', shippingAppInfo.developer_name_en);
  appData.append('developer_name_ar', shippingAppInfo.developer_name_ar);
  if (shippingAppInfo.screen_shots_ar.length) {
    shippingAppInfo.screen_shots_ar.map((screenshot, index) => {
      appData.append(`screen_shots_ar[${index}]`, screenshot);
    });
  } else appData.append(`screen_shots_ar`, '');
  if (shippingAppInfo.screen_shots_en.length) {
    shippingAppInfo.screen_shots_en.map((screenshot, index) => {
      appData.append(`screen_shots_en[${index}]`, screenshot);
    });
  } else appData.append(`screen_shots_en`, '');

  return httpClient
    .post(ENDPOINT_URL, appData)
    .then((response) => new ShippingAppUpdateResponseModel(response))
    .catch((error) => error);
};
