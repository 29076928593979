import BaseModel from '@/api/models/BaseModel';
import {
  WebhookResponse,
  AvailableWebhooksType,
} from '@/domains/pd-admin/types/administration/apps-management/app-details/shipping-app-details.type';

export default class ShippingAppWebhooksResponseModel extends BaseModel {
  public payload: {
    webhooks: Record<string, WebhookResponse[]>;
    webhooksAvailable: AvailableWebhooksType;
  };

  constructor(arg: any) {
    super(arg);
    this.payload = arg.payload;
  }
}
