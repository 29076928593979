var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoadingAppDetails)?_c('div',[_c('div',{staticClass:"flex md:justify-center items-center h-60 md:my-20"},[_c('zid-loader',{attrs:{"size":"large"}})],1)]):_c('div',{staticClass:"shipping-app-details"},[_c('div',{staticClass:"px-4 py-6 my-8 mx-6 bg-white-pureWhite shadow-md rounded-lg"},[_c('div',{staticClass:"flex flex-col"},[_c('zid-breadcrumb',[_c('zid-breadcrumb-item',{attrs:{"route":_vm.ShippingAppDetailsRoutesEnum.AppsManagement}},[_c('base-text',{attrs:{"textStyle":"h4"}},[_vm._v(" Apps Management ")])],1),_c('zid-breadcrumb-item',{attrs:{"route":_vm.ShippingAppDetailsRoutesEnum.AppDetails,"disabled":true}},[_c('base-text',{attrs:{"textStyle":"h4","classes":"capitalize"}},[_vm._v(" "+_vm._s(_vm.shippingAppGeneralData.appCompanyNameEnglish)+" ")])],1)],1)],1),_c('div',{staticClass:"mt-8 flex flex-col"},[_c('nav',{staticClass:"flex"},[_c('div',{class:{
              'cursor-pointer border-b-2': true,
              'border-yellow text-yellow': _vm.activeSection === 'app_details',
              'border-primary-700 text-primary': _vm.activeSection !== 'app_details',
            },on:{"click":function($event){return _vm.showSection('app_details')}}},[_c('base-text',{attrs:{"textStyle":"p1"}},[_vm._v(" App Details ")])],1),_c('div',{class:{
              'cursor-pointer border-b-2 ms-8': true,
              'border-yellow text-yellow': _vm.activeSection === 'app_management',
              'border-primary-700 text-primary': _vm.activeSection !== 'app_management',
            },on:{"click":function($event){return _vm.showSection('app_management')}}},[_c('base-text',{attrs:{"textStyle":"p1"}},[_vm._v(" App Management ")])],1)])])]),(_vm.activeSection === 'app_details')?_c('section',{staticClass:"app-details-section mx-6"},[_c('general-data',{attrs:{"app-id":_vm.appId,"shipping-app-general-data":_vm.shippingAppGeneralData}}),_c('o-auth-details',{attrs:{"shipping-app-api-keys":_vm.shippingAppApiKeys,"app-id":_vm.appId}}),_c('terms-and-coverage',{attrs:{"app-id":_vm.appId,"shipping-app-model":_vm.shippingAppTermsAndCoverage}}),_c('shipping-options',{attrs:{"app-id":_vm.appId,"saved-pricing-model":_vm.savedPricingModel,"contract-link":_vm.contractLink}}),_c('webhooks-management',{attrs:{"app-id":_vm.appId}})],1):_vm._e(),(_vm.activeSection === 'app_management')?_c('section',{staticClass:"app-management-section mx-6"},[_c('app-management',{attrs:{"app-type":_vm.ApplicationTypeProperty.Shipping,"app-i-d":_vm.appId,"partner-i-d":_vm.partnerId}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }