import router from '@/router';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup(_, ctx) {
    if (ctx.root.$route.params.id && ctx.root.$route.name) {
      router.push({ name: ctx.root.$route.name }).catch((er) => {
        //
      });
    } else router.back();
    return {};
  },
});
