import { defineComponent, reactive, ref, watch } from '@vue/composition-api';
import {
  ZidIcon,
  ZidButton,
  ZidModal,
  ZidInput,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidTextarea,
  ZidLoader,
  ZidSwitch,
  ZidRadio,
} from '@zidsa/ui';
import BaseText from '@/components/text/BaseText.vue';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import PlanModel from '@/domains/applications/models/PlanModel';
import useAppPlans from '../../../../../helpers/administration/apps-management/app-details/app-plans/useAppPlans';

export default defineComponent({
  components: {
    ZidIcon,
    ZidButton,
    ZidModal,
    ZidInput,
    ZidSelect,
    ZidSelectBody,
    ZidSelectHeader,
    ZidSelectOption,
    ZidTextarea,
    ZidLoader,
    BaseText,
    ZidSwitch,
    ZidRadio,
    TabMultiLanguage,
  },
  props: ['plan', 'showPlanModal'],
  emits: ['onClosePlanModal', 'onPlanEdited'],
  setup(props, ctx) {
    let plan = reactive<PlanModel>(props.plan);
    const showPlanModal = ref(props.showPlanModal);

    watch(
      () => props,
      () => {
        plan = { ...props.plan };
        showPlanModal.value = props.showPlanModal;
      },
      { deep: true },
    );

    return {
      ...useAppPlans(plan, showPlanModal, ctx),
    };
  },
});
