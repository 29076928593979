import { defineComponent, reactive, ref } from '@vue/composition-api';
import Vue from 'vue';
import { ZidInput, ZidButton } from '@zidsa/ui';
import BaseText from '@/components/text/BaseText.vue';
import {
  apiKeysInterface,
  OAuthDetailsInputPropertiesEnum,
} from '@/domains/pd-admin/types/administration/apps-management/app-details/shipping-app-details.type';
import { helpers, required } from '@vuelidate/validators';
import { isValidURLWithLocalhost } from '@/helpers/regex/URLs';
import useVuelidate from '@vuelidate/core';
import ErrorModel from '@/api/models/ErrorModel';
import { updateShippingAppOAuthDetails } from '@/domains/pd-admin/api/administration/apps-management/app-details-shipping/updateShippingAppOAuthDetails';
export default defineComponent({
  components: {
    ZidInput,
    ZidButton,
    BaseText,
  },
  props: {
    appId: {
      type: Number,
      required: true,
    },
    shippingAppApiKeys: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isUpdatingOAuthDetails = ref(false);
    const shippingAppApiKeysState = reactive<apiKeysInterface>({
      clientId: props.shippingAppApiKeys.clientId ?? '',
      clientSecret: props.shippingAppApiKeys.clientSecret ?? '',
      callbackUrl: props.shippingAppApiKeys.callbackUrl ?? '',
      redirectUrl: props.shippingAppApiKeys.redirectUrl ?? '',
    });

    const rules = {
      clientId: {
        required,
      },
      clientSecret: {
        required,
      },
      redirectUrl: {
        required,
        redirectUrl: helpers.withMessage('Please enter a valid URL.', (value: string) => {
          return isValidURLWithLocalhost(value);
        }),
      },
      callbackUrl: {
        required,
        callbackUrl: helpers.withMessage('Please enter a valid URL.', (value: string) => {
          return isValidURLWithLocalhost(value);
        }),
      },
    };

    const validator = useVuelidate(rules, shippingAppApiKeysState);

    const onInputDataChange = (changedValue: Record<string, string>, dataProperty: OAuthDetailsInputPropertiesEnum) => {
      validator.value[dataProperty].$touch();
      shippingAppApiKeysState[dataProperty] = changedValue.value;
    };

    const onUpdateOAuthDetails = async () => {
      isUpdatingOAuthDetails.value = true;
      const shippingAppOAuthDetails = {
        step: 2, // static
        app_id: props.appId,
        callback_url: `${shippingAppApiKeysState.callbackUrl}`,
        redirect_url: `${shippingAppApiKeysState.redirectUrl}`,
      };
      const response = await updateShippingAppOAuthDetails(shippingAppOAuthDetails);
      if (response instanceof ErrorModel || !response.payload) {
        Vue.$toast.error('Failed to update application OAuth details');
        isUpdatingOAuthDetails.value = false;
        return;
      }
      Vue.$toast.success('Application OAuth details has been updated successfully');
      isUpdatingOAuthDetails.value = false;
    };

    return {
      validator,
      shippingAppApiKeysState,
      OAuthDetailsInputPropertiesEnum,
      isUpdatingOAuthDetails,
      onInputDataChange,
      onUpdateOAuthDetails,
    };
  },
});
