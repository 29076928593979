import { ApplicationListingStatusProperty } from '@/api/models/ApplicationModel';

export interface VisibilityControlState {
  storesIDs: string;
  instructions: string;
  appOrder: string;
  appStatus: Record<string, string>;
  adminNotes: string;
}

export enum VisibilityControlInputPropertiesEnum {
  StoresIDs = 'storesIDs',
  Instructions = 'instructions',
  AppOrder = 'appOrder',
  AdminNotes = 'adminNotes',
}

export enum VisibilityControlSelectPropertiesEnum {
  AppStatus = 'appStatus',
}

export enum AppStatusDropdownEnum {
  Published = 'Published',
  Unpublished = 'Unpublished',
  Rejected = 'Rejected',
}

export enum AppStatusDropdownValuesEnum {
  Published = 3,
  Unpublished = 4,
  Rejected = 2,
}

export interface VisbilityDataInterface {
  store_installs: StoreInstallDataInterface[];
  install_instructions: null | string;
  order: number;
  listing_status: ApplicationListingStatusProperty | null;
}

export interface StoreInstallDataInterface {
  store_id: number;
  private_app_id: number;
  name: string;
  ssl: string;
}
