import { defineComponent, ref } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import { ZidCheckbox, ZidLoader, ZidButton } from '@zidsa/ui';
import usePackagesControl from '@/domains/pd-admin/helpers/administration/apps-management/app-management/control-packages/usePackagesControl';

export default defineComponent({
  components: {
    BaseText,
    ZidCheckbox,
    ZidLoader,
    ZidButton,
  },
  setup(_, ctx) {
    const appID = ref(Number(ctx.root.$route.params.id));

    return {
      ...usePackagesControl(appID.value),
    };
  },
});
