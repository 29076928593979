import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import { WebhookGroup } from '@/domains/pd-admin/types/administration/apps-management/app-details/shipping-app-details.type';
import ShippingAppWebhooksResponseModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/ShippingAppWebhooksResponseModel';
import AddWebhookGroupResponseModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/AddWebhookGroupResponseModel';
import DeleteWebhookResponseModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/DeleteWebhookResponseModel';

const ENDPOINT_URL = '/market';

export const getAppWebhooksData = (appId: number): Promise<ShippingAppWebhooksResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/admin/shipping/webhooks/${appId}`)
    .then((response) => new ShippingAppWebhooksResponseModel(response))
    .catch((error) => error);
};

export const updateWebhookGroupData = (
  webhookGroupInfo: WebhookGroup,
  shippingAppId: number,
): Promise<AddWebhookGroupResponseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/update/multiple/${shippingAppId}/webhook`, webhookGroupInfo)
    .then((response) => new AddWebhookGroupResponseModel(response))
    .catch((error) => error);
};

export const deleteWebhookGroup = (
  webhookGroupID: string,
  shippingAppId: number,
): Promise<DeleteWebhookResponseModel | ErrorModel> => {
  return httpClient
    .delete(`${ENDPOINT_URL}/delete/${shippingAppId}/webhook/group`, {
      data: {
        webhook: {
          group_id: webhookGroupID,
        },
      },
    })
    .then((response) => new DeleteWebhookResponseModel(response))
    .catch((error) => error);
};
