import Vue from 'vue';
import {
  getAppPackages,
  updateAppPackages,
} from '@/domains/pd-admin/api/administration/apps-management/app-management/packages';
import {
  PackageDataInterface,
  PackagesControlState,
} from '@/domains/pd-admin/types/administration/apps-management/app-management/packages';
import { onBeforeMount, reactive, ref } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';

const usePackagesControl = (appID: number) => {
  const isLoading = ref(false);

  const packagesControlState = reactive<PackagesControlState>({
    selectedPackages: [],
  });

  const rules = {
    selectedPackages: {
      required,
      minLength: minLength(1),
    },
  };

  const v$ = useVuelidate(rules, packagesControlState);

  const packages = reactive<Record<string, PackageDataInterface[]>>({
    list: [],
  });

  onBeforeMount(async () => {
    isLoading.value = true;
    const packagesRes = await getAppPackages(appID);
    if (!packagesRes.packages) {
      isLoading.value = false;
      return;
    }
    packages.list = [...packagesRes.packages];
    packagesControlState.selectedPackages = packages.list
      .filter((appPackage) => appPackage.active)
      .map((appPackage) => appPackage.value);

    isLoading.value = false;
  });

  const onPackageCheckboxChange = (value: Record<string, boolean>, appPackage: string) => {
    if (value.isChecked) {
      if (!packagesControlState.selectedPackages.includes(appPackage)) {
        packagesControlState.selectedPackages.push(appPackage);
      }
      const packageIndex = packages.list.findIndex((onePackage) => onePackage.value === appPackage);
      if (packageIndex !== -1) {
        packages.list[packageIndex].active = true;
      }
    } else {
      if (packagesControlState.selectedPackages.includes(appPackage)) {
        const selectePackageIndex = packagesControlState.selectedPackages.findIndex(
          (selected) => selected === appPackage,
        );
        packagesControlState.selectedPackages.splice(selectePackageIndex, 1);
      }

      const packageIndex = packages.list.findIndex((onePackage) => onePackage.value === appPackage);
      if (packageIndex !== -1) {
        packages.list[packageIndex].active = false;
      }
    }
  };

  const onUpdatePackages = async () => {
    const updateRes = await updateAppPackages(appID, packagesControlState.selectedPackages);
    if (updateRes.status !== 'success') {
      Vue.$toast.error('Could not update application packages');
    }
    Vue.$toast.success('Application packages have been updated');
  };

  return {
    packagesControlState,
    v$,
    packages,
    isLoading,
    onPackageCheckboxChange,
    onUpdatePackages,
  };
};

export default usePackagesControl;
