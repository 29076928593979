import { render, staticRenderFns } from "./JSAppDetails.vue?vue&type=template&id=25729e0e&scoped=true&"
import script from "./jsAppDetails.js?vue&type=script&lang=ts&"
export * from "./jsAppDetails.js?vue&type=script&lang=ts&"
import style0 from "./jsAppDetails.scss?vue&type=style&index=0&id=25729e0e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25729e0e",
  null
  
)

export default component.exports