import { computed, onBeforeMount, reactive, ref } from '@vue/composition-api';
import {
  AvailableWebhooksOptionsType,
  SubscriptionType,
  SubscriptionTypesEnum,
  WebhookGroup,
  WebhookResponse,
  WebhooksFromSaveBtnType,
  WebhooksManagementInputPropertiesEnum,
  WebhookType,
} from '@/domains/pd-admin/types/administration/apps-management/app-details/shipping-app-details.type';
import { helpers, required } from '@vuelidate/validators';
import { isValidURL } from '@/helpers/regex/URLs';
import useVuelidate from '@vuelidate/core';
import {
  deleteWebhookGroup,
  getAppWebhooksData,
  updateWebhookGroupData,
} from '@/domains/pd-admin/api/administration/apps-management/app-details-shipping/webhooks-management';
import ErrorModel from '@/api/models/ErrorModel';
import Vue from 'vue';

const useWebhooksManagement = (appId: number) => {
  const showAddWebhook = ref(false);
  const loadingExistingWebhooks = ref(false);
  const loadingAddOrEditWebhook = ref(false);
  const showDeleteWebhookModal = ref(false);
  const isDeletingWebhookGroup = ref(false);
  const selectedWebhookGroupID = ref('');
  const webhooksOptions: AvailableWebhooksOptionsType = reactive({
    options: {
      orders: [],
      subscription: [],
      products: [],
      sync: [],
    },
  });

  const webhooksFormSaveBtnState: WebhooksFromSaveBtnType = reactive({
    orders: false,
    subscription: false,
    products: false,
    sync: false,
  });
  let existingWebhookGroups: Record<string, WebhookResponse[]> | null = null;

  const createShippingAppWebhooksState: SubscriptionType = reactive({
    orders: {
      id: -1,
      webhookSelection: [],
      targetURL: '',
      headers: '',
    },
    subscription: {
      id: -1,
      webhookSelection: [],
      targetURL: '',
      headers: '',
    },
    products: {
      id: -1,
      webhookSelection: [],
      targetURL: '',
      headers: '',
    },
    sync: {
      id: -1,
      webhookSelection: [],
      targetURL: '',
      headers: '',
    },
  });

  const webhookSubscriptionList: Record<string, WebhookGroup[]> = reactive({ subscriptionList: [] });

  const isValidJsonString = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const rules = {
    orders: {
      webhookSelection: {
        required,
        webhookSelection: helpers.withMessage('Please select application webhooks', required),
      },
      targetURL: {
        required,
        targetURL: helpers.withMessage('URL is not valid', (value: string) => {
          return isValidURL(value);
        }),
      },
      headers: {
        headers: helpers.withMessage(
          'JSON format is not valid',
          (value: string) => isValidJsonString(value) || value === '',
        ),
      },
    },
    subscription: {
      webhookSelection: {
        required,
        webhookSelection: helpers.withMessage('Please select application webhooks', required),
      },
      targetURL: {
        required,
        targetURL: helpers.withMessage('URL is not valid', (value: string) => {
          return isValidURL(value);
        }),
      },
      headers: {
        headers: helpers.withMessage(
          'JSON format is not valid',
          (value: string) => isValidJsonString(value) || value === '',
        ),
      },
    },
    products: {
      webhookSelection: {
        required,
        webhookSelection: helpers.withMessage('Please select application webhooks', required),
      },
      targetURL: {
        required,
        targetURL: helpers.withMessage('URL is not valid', (value: string) => {
          return isValidURL(value);
        }),
      },
      headers: {
        headers: helpers.withMessage(
          'JSON format is not valid',
          (value: string) => isValidJsonString(value) || value === '',
        ),
      },
    },
    sync: {
      webhookSelection: {
        required,
        webhookSelection: helpers.withMessage('Please select application webhooks', required),
      },
      targetURL: {
        required,
        targetURL: helpers.withMessage('URL is not valid', (value: string) => {
          return isValidURL(value);
        }),
      },
      headers: {
        headers: helpers.withMessage(
          'JSON format is not valid',
          (value: string) => isValidJsonString(value) || value === '',
        ),
      },
    },
  };

  const validator = useVuelidate(rules, createShippingAppWebhooksState);

  onBeforeMount(async () => {
    loadWebhooksData();
  });

  const toggleAddWebhooksForm = () => (showAddWebhook.value = !showAddWebhook.value);
  const toggleDeleteWebhookModal = () => (showDeleteWebhookModal.value = !showDeleteWebhookModal.value);

  const onCancelClicked = (dataProperty: SubscriptionTypesEnum) => {
    resetWebhooksFormData(dataProperty);
  };

  const onInputDataChange = (
    changedValue: Record<string, string>,
    dataProperty: WebhooksManagementInputPropertiesEnum,
    formState: SubscriptionTypesEnum,
  ) => {
    validator.value[formState][dataProperty].$touch();
    createShippingAppWebhooksState[formState][dataProperty] = changedValue.value;
  };

  const resetWebhooksFormData = (dataProperty: SubscriptionTypesEnum) => {
    createShippingAppWebhooksState[dataProperty].webhookSelection = [];
    createShippingAppWebhooksState[dataProperty].targetURL = '';
    createShippingAppWebhooksState[dataProperty].headers = '';
    selectedWebhookGroupID.value = '';
    validator.value.$reset();
  };

  const onWebhookGroupEdit = (webhookGroupID: string, dataProperty: SubscriptionTypesEnum) => {
    selectedWebhookGroupID.value = webhookGroupID;
    if (!showAddWebhook.value) toggleAddWebhooksForm();
    const webhooks = webhookSubscriptionList.subscriptionList?.find(
      (webhookGroup: WebhookGroup) => webhookGroup.group_id === selectedWebhookGroupID.value,
    )?.webhooks;
    if (webhooks?.length) {
      createShippingAppWebhooksState[dataProperty].webhookSelection = webhooks?.map((webhook) => webhook.name);
      createShippingAppWebhooksState[dataProperty].targetURL = webhooks[0].target_url;
      createShippingAppWebhooksState[dataProperty].headers = webhooks[0].headers ?? '{}';
    }
  };

  const onWebhookGroupDelete = async (webhookGroupID: string) => {
    selectedWebhookGroupID.value = webhookGroupID;
    showDeleteWebhookModal.value = true;
  };

  const onDeleteConfirmationClicked = async () => {
    if (!appId || !selectedWebhookGroupID) {
      return;
    }
    isDeletingWebhookGroup.value = true;
    const response = await deleteWebhookGroup(selectedWebhookGroupID.value, appId);
    toggleDeleteWebhookModal();
    isDeletingWebhookGroup.value = false;
    if (response instanceof ErrorModel || !response.deleted) {
      Vue.$toast.error('Failed to delete webhook group');
      return;
    }
    Vue.$toast.success('Webhook group has been deleted');
    loadWebhooksData();
  };

  const prepareWebhookGroupData = (dataProperty: SubscriptionTypesEnum) => {
    const webhooks: WebhookType[] = [];
    let webhookGroupID = null;
    let selectedWebhookGroup: WebhookGroup | null = null;
    if (selectedWebhookGroupID.value) {
      webhookGroupID = selectedWebhookGroupID.value;
      selectedWebhookGroup =
        webhookSubscriptionList.subscriptionList.find((webhook) => webhook.group_id === selectedWebhookGroupID.value) ||
        null;
    }
    createShippingAppWebhooksState[dataProperty].webhookSelection.map((selection) => {
      webhooks.push({
        id: selectedWebhookGroup?.webhooks.find((webhook) => webhook.name === selection)?.id || null,
        name: selection,
        target_url: createShippingAppWebhooksState[dataProperty].targetURL,
        headers: createShippingAppWebhooksState[dataProperty].headers
          ? JSON.parse(createShippingAppWebhooksState[dataProperty].headers)
          : null,
      });
    });

    const newWebhookGroup: WebhookGroup = {
      group_id: webhookGroupID,
      webhooks,
    };
    return newWebhookGroup;
  };

  const onSaveOrEditWebhookClicked = async (dataProperty: SubscriptionTypesEnum) => {
    if (appId) {
      webhooksFormSaveBtnState[dataProperty] = true;
      const newWebhookGroup = prepareWebhookGroupData(dataProperty);
      const response = await updateWebhookGroupData(newWebhookGroup, appId);
      if (response instanceof ErrorModel || !response.webhooks) {
        webhooksFormSaveBtnState[dataProperty] = false;
        if (selectedWebhookGroupID.value !== '') Vue.$toast.error('Failed to update webhook group');
        else Vue.$toast.error('Failed to Add new webhook group');
        return;
      }
      if (selectedWebhookGroupID.value !== '') Vue.$toast.success('Updated webhook group successfully');
      else Vue.$toast.success('Added new webhook group successfully');
      loadWebhooksData();
    }
    webhooksFormSaveBtnState[dataProperty] = false;
    selectedWebhookGroupID.value = '';
    resetWebhooksFormData(dataProperty);
  };

  const updateCurrentSubscriptionList = () => {
    if (!existingWebhookGroups) return;
    webhookSubscriptionList.subscriptionList = [];
    for (const [key, value] of Object.entries(existingWebhookGroups)) {
      webhookSubscriptionList.subscriptionList.push({
        group_id: key,
        webhooks: value,
      });
    }
  };

  const loadWebhooksData = async () => {
    if (appId) {
      loadingExistingWebhooks.value = true;
      const response = await getAppWebhooksData(appId);
      if (response instanceof ErrorModel || !response.payload) {
        loadingExistingWebhooks.value = false;
        return;
      }
      existingWebhookGroups = response.payload.webhooks;
      webhooksOptions.options = response.payload.webhooksAvailable;
      updateCurrentSubscriptionList();
      loadingExistingWebhooks.value = false;
    } else {
      Vue.$toast.error('Failed to Find the App');
    }
  };

  const allWebhooksAreAdded = computed(() => {
    return Object.values(webhooksOptions.options).every((option) => option.length === 0);
  });

  return {
    validator,
    createShippingAppWebhooksState,
    SubscriptionTypesEnum,
    WebhooksManagementInputPropertiesEnum,
    webhooksOptions,
    webhookSubscriptionList,
    showAddWebhook,
    loadingAddOrEditWebhook,
    loadingExistingWebhooks,
    showDeleteWebhookModal,
    isDeletingWebhookGroup,
    selectedWebhookGroupID,
    webhooksFormSaveBtnState,
    allWebhooksAreAdded,
    onInputDataChange,
    toggleAddWebhooksForm,
    onCancelClicked,
    toggleDeleteWebhookModal,
    onSaveOrEditWebhookClicked,
    onWebhookGroupEdit,
    onWebhookGroupDelete,
    onDeleteConfirmationClicked,
  };
};

export default useWebhooksManagement;
