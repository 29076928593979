import httpClient from '@/api/httpClient';
import { ApplicationTypeProperty } from '@/api/models/ApplicationModel';
import BaseModel from '@/api/models/BaseModel';
import {
  OAuthAppStateInterface,
  ScopesInfoInterface,
} from '@/domains/pd-admin/types/administration/apps-management/app-details/oauth-app-details.type';

const ENDPOINT_URL = '/market';

export const updateOAuthAppDetails = (
  appID: number,
  appInfo: OAuthAppStateInterface,
  arabicScreenshots: Record<string, File[]>,
  englishScreenshots: Record<string, File[]>,
): Promise<BaseModel | Error> => {
  const formData = new FormData();

  formData.append('name', appInfo.appNameEnglish);
  formData.append('name_ar', appInfo.appNameArabic);
  formData.append('app_url', appInfo.appURL);
  formData.append('installation_url', appInfo.redirectURL);
  formData.append('redirect_uri', appInfo.callbackURL);
  formData.append('is_embedded', appInfo.isEmbedded ? '1' : '0');
  formData.append('app_category_id', `${appInfo.appCategory.value}`);
  formData.append('language', appInfo.appLanguage.value);
  formData.append('contact_email', appInfo.maintainerEmail);
  formData.append('type', `${ApplicationTypeProperty.OAuth}`);
  formData.append('description', appInfo.description);
  formData.append('description_ar', appInfo.descriptionArabic);
  formData.append('short_description', appInfo.shortDesciption);
  formData.append('short_description_ar', appInfo.shortDesciptionArabic);
  formData.append('developer_name_en', appInfo.developerName);
  formData.append('developer_name_ar', appInfo.developerNameArabic);
  formData.append('video', appInfo.videoURL);
  formData.append('is_html_enabled', appInfo.isHTMLEnabled ? '1' : '0');

  if (arabicScreenshots.list.length) {
    arabicScreenshots.list.forEach((screenshot, index) => {
      formData.append(`screen_shots_ar[${index}]`, screenshot);
    });
  } else formData.append(`screen_shots_ar`, '');

  if (englishScreenshots.list.length) {
    englishScreenshots.list.forEach((screenshot, index) => {
      formData.append(`screen_shots_en[${index}]`, screenshot);
    });
  } else formData.append(`screen_shots_en`, '');

  if (appInfo.iconFile instanceof File) {
    formData.append('icon', appInfo.iconFile);
  }

  if (appInfo.coverPhoto instanceof File) {
    formData.append('cover_photo', appInfo.coverPhoto);
  }

  return httpClient
    .post(`${ENDPOINT_URL}/admin/update/${appID}`, formData)
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};

export const updateScopesInformation = (
  appID: number | string,
  scopesInfo: ScopesInfoInterface,
): Promise<BaseModel | Error> => {
  return httpClient
    .post(`${ENDPOINT_URL}/admin/update/${appID}/scopes`, scopesInfo)
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
