import BaseModel from '@/api/models/BaseModel';
import { VisbilityDataInterface } from '@/domains/pd-admin/types/administration/apps-management/app-management/visibility';

class AppVisibilityResponseModel extends BaseModel {
  public 'visibility-control': VisbilityDataInterface;

  constructor(arg: any) {
    super(arg);
    this['visibility-control'] = arg['visibility-control'];
  }
}

export default AppVisibilityResponseModel;
