import ApplicationModel from '@/api/models/ApplicationModel';
import BaseModel from '@/api/models/BaseModel';
import CategoryResponseModel from '@/domains/applications/models/CategoryResponseModel';
import PlanModel from '@/domains/applications/models/PlanModel';
import ScopeResponseModel from '@/domains/applications/models/ScopeResponseModel';
import { HeadscriptInterface } from '@/domains/pd-admin/types/administration/apps-management/app-details/js-app-details.type';

class JSAppDetailsResponseModel extends BaseModel {
  public payload: {
    app: ApplicationModel;
    categories: CategoryResponseModel[];
    headScripts: HeadscriptInterface[];
    plans: PlanModel[];
    scopeGroups: ScopeResponseModel[];
    webhooks: [];
  };

  constructor(arg: any) {
    super(arg);
    this.payload = arg.payload;
  }
}

export default JSAppDetailsResponseModel;
