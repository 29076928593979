import Vue from 'vue';
import { defineComponent, ref } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import {
  ZidInput,
  ZidButton,
  ZidTextarea,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidLoader,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import useVisibilityControl from '@/domains/pd-admin/helpers/administration/apps-management/app-management/control-visibility/useVisibilityControl';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import DeleteIcon from '@/assets/icons/applications/DeleteIcon.vue';
import {
  deletePrivateStore,
  updateAppListing,
  updateAppOrder,
  updateInstallInstructions,
  updatePrivateStores,
} from '@/domains/pd-admin/api/administration/apps-management/app-management/visibility';
import { StoreInstallDataInterface } from '@/domains/pd-admin/types/administration/apps-management/app-management/visibility';

export default defineComponent({
  components: {
    BaseText,
    ZidInput,
    BaseIcon,
    DeleteIcon,
    ZidButton,
    ZidTextarea,
    ZidSelect,
    ZidSelectBody,
    ZidSelectHeader,
    ZidSelectOption,
    ZidLoader,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  setup(_, ctx) {
    const appID = ref(Number(ctx.root.$route.params.id));

    const {
      visibilityControlState,
      VisibilityControlInputPropertiesEnum,
      VisibilityControlSelectPropertiesEnum,
      v$,
      stores,
      appStatus,
      isLoading,
      onInputDataChange,
      onSelectionDataChange,
      fetchAndUpdateFields,
    } = useVisibilityControl(appID.value);

    const onUpdatePrivateStores = async () => {
      const res = await updatePrivateStores(appID.value, visibilityControlState.storesIDs);
      if (res.status !== 'success') {
        Vue.$toast.error('Could not update stores');
        return;
      }
      fetchAndUpdateFields();
      Vue.$toast.success('Stores have been updated');
      visibilityControlState.storesIDs = '';
    };

    const onDeleteStore = async (store: StoreInstallDataInterface) => {
      const res = await deletePrivateStore(appID.value, store.private_app_id);
      if (res.status !== 'success') {
        Vue.$toast.error('Could not delete store');
        return;
      }
      fetchAndUpdateFields();
      Vue.$toast.success('Store have been deleted');
    };

    const onUpdateInstallationInstructions = async () => {
      const res = await updateInstallInstructions(appID.value, visibilityControlState.instructions);
      if (res.status !== 'success') {
        Vue.$toast.error('Could not update instructions');
        return;
      }
      fetchAndUpdateFields();
      Vue.$toast.success('Instructions have been updated');
    };

    const onUpdateApplicationOrder = async () => {
      const res = await updateAppOrder(appID.value, Number(visibilityControlState.appOrder));
      if (res.status !== 'success') {
        Vue.$toast.error('Could not update application order');
        return;
      }
      fetchAndUpdateFields();
      Vue.$toast.success('application order have been updated');
    };

    const onUpdateApplicationStatus = async () => {
      const res = await updateAppListing(
        appID.value,
        visibilityControlState.appStatus.value,
        visibilityControlState.adminNotes,
      );
      if (res.status !== 'success') {
        Vue.$toast.error('Could not update application listing status');
        return;
      }
      fetchAndUpdateFields();
      Vue.$toast.success('application listing status have been updated');
      visibilityControlState.adminNotes = '';
    };

    return {
      visibilityControlState,
      VisibilityControlInputPropertiesEnum,
      VisibilityControlSelectPropertiesEnum,
      v$,
      stores,
      appStatus,
      isLoading,
      onInputDataChange,
      onSelectionDataChange,
      onUpdatePrivateStores,
      onDeleteStore,
      onUpdateInstallationInstructions,
      onUpdateApplicationOrder,
      onUpdateApplicationStatus,
    };
  },
});
