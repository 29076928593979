import httpClient from '@/api/httpClient';
import { CustomizationDetailsType } from '@/domains/pd-admin/types/administration/apps-management/app-details/shipping-app-details.type';
import BaseModel from '@/api/models/BaseModel';
import ErrorModel from '@/api/models/ErrorModel';

const ENDPOINT_URL = '/partners/shipping/courier';

export const updateShippingOptionsCustomizations = (
  appId: number,
  customizationsDetails: CustomizationDetailsType,
): Promise<BaseModel | ErrorModel> => {
  // update case
  if (customizationsDetails.id) {
    return httpClient
      .put(`${ENDPOINT_URL}/${appId}/customizations/${customizationsDetails.id}`, customizationsDetails)
      .then((response) => response)
      .catch((error) => error);
  }
  // create case
  else {
    return httpClient
      .post(`${ENDPOINT_URL}/${appId}/customizations`, customizationsDetails)
      .then((response) => response)
      .catch((error) => error);
  }
};
