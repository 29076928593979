import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import ShippingAppUpdateResponseModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/ShippingAppUpdateResponseModel';

const ENDPOINT_URL = '/market/app/create-shippings';

export const updateShippingOptions = (
  appId: number,
  contractLink: string,
): Promise<ShippingAppUpdateResponseModel | ErrorModel> => {
  const appData = new FormData();
  appData.append('step', '4'); // static
  appData.append('app_id', `${appId}`);
  if (contractLink) {
    appData.append('contract_link', contractLink);
  }
  return httpClient
    .post(ENDPOINT_URL, appData)
    .then((response) => new ShippingAppUpdateResponseModel(response))
    .catch((error) => error);
};
