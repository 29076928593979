import httpClient from '@/api/httpClient';
import BaseModel from '@/api/models/BaseModel';
import AppVisibilityResponseModel from '@/domains/pd-admin/models/administration/apps-management/app-management/visibility-control/app-visibility-response-model';

const ENDPOINT_URL = '/market';

export const getAppVisibilityDetails = (appID: number) => {
  return httpClient
    .get(`${ENDPOINT_URL}/admin/visibility/${appID}`)
    .then((response) => new AppVisibilityResponseModel(response))
    .catch((error) => error);
};

export const updatePrivateStores = (appID: number, privateStores: string) => {
  return httpClient
    .post(`${ENDPOINT_URL}/admin/update/${appID}/private`, {
      stores: privateStores,
    })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};

export const deletePrivateStore = (appID: number, privateID: number) => {
  return httpClient
    .delete(`${ENDPOINT_URL}/admin/delete/${privateID}/${appID}/private`)
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};

export const updateInstallInstructions = (appID: number, installInstructions: string) => {
  return httpClient
    .put(`${ENDPOINT_URL}/admin/update/${appID}/installInstructions`, {
      install_instructions: installInstructions,
    })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};

export const updateAppOrder = (appID: number, appOrder: number) => {
  return httpClient
    .put(`${ENDPOINT_URL}/admin/update/${appID}/updateOrder`, {
      order: appOrder,
    })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};

export const updateAppListing = (appID: number, listingStatus: string, adminNotes: string) => {
  return httpClient
    .put(`${ENDPOINT_URL}/admin/update/${appID}/listing`, {
      listing_status: listingStatus,
      admin_notes: adminNotes,
    })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
