import BaseModel from '@/api/models/BaseModel';
import CoverageCityModel from './CoverageCityModel';

class CoverageCitiesResponseModel extends BaseModel {
  public cities: CoverageCityModel[];

  constructor(arg: any) {
    super(arg);
    this.cities = arg.cities;
  }
}

export default CoverageCitiesResponseModel;
