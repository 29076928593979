import httpClient from '@/api/httpClient';
import BaseModel from '@/api/models/BaseModel';
import PartnerDetailsResponseModel from '@/domains/partnerships-admin/models/PartnerDetailsResponseModel';
import {
  AppInformationToUpdateType,
  ContractStatusEnum,
  RevenueModelValuesEnum,
} from '@/domains/pd-admin/types/administration/apps-management/app-management/partnership';

const ENDPOINT_URL = '/market';

export const getPartnershipDetails = (partnerID: number, appID: number) => {
  return httpClient
    .get(`${ENDPOINT_URL}/partnership-admin/${partnerID}/${appID}`)
    .then((response) => new PartnerDetailsResponseModel(response))
    .catch((error) => error);
};

export const updatePartnershipDetails = (appInfo: AppInformationToUpdateType) => {
  const appData = new FormData();
  appData.append('app_id', appInfo.app_id);
  appData.append('pricing_model', appInfo.pricing_model);

  if (appInfo.contract_doc) {
    appData.append('contract_doc', appInfo.contract_doc);
    appData.append('contract_status', ContractStatusEnum.Signed);
  } else {
    appData.append('contract_status', ContractStatusEnum.Unsigned);
  }
  if (appInfo.vat_percentage) appData.append('vat_percentage', appInfo.vat_percentage);

  switch (appInfo.pricing_model) {
    case RevenueModelValuesEnum.FixedPrice:
      appData.append('fixed_price', appInfo.fixed_price ?? '');
      appData.append('activation_fees', appInfo.activation_fees ?? '');
      break;
    case RevenueModelValuesEnum.PercentageOfRevenue:
      appData.append('zid_percentage', appInfo.zid_percentage ?? '');
      appData.append('partner_percentage', appInfo.partner_percentage ?? '');
      break;
    case RevenueModelValuesEnum.Slap:
      appData.append('slaps_details', JSON.stringify(appInfo.slaps_details ?? ''));
      appData.append('activation_fees', appInfo.activation_fees ?? '');
      break;

    default:
      break;
  }

  return httpClient
    .post(`${ENDPOINT_URL}/partnership-admin/app/update`, appData)
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
