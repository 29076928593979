import BaseModel from '@/api/models/BaseModel';
import { WebhookResponse } from '@/domains/pd-admin/types/administration/apps-management/app-details/app-webhooks';

class AppWebhooksResponseModel extends BaseModel {
  public webhooks: Record<string, WebhookResponse[]>;
  public webhooksAvailable: string[];

  constructor(arg: any) {
    super(arg);
    this.webhooks = arg.webhooks;
    this.webhooksAvailable = arg.webhooksAvailable;
  }
}

export default AppWebhooksResponseModel;
