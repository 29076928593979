import { onBeforeMount, reactive, ref } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import {
  AppStatusDropdownEnum,
  AppStatusDropdownValuesEnum,
  StoreInstallDataInterface,
  VisibilityControlInputPropertiesEnum,
  VisibilityControlSelectPropertiesEnum,
  VisibilityControlState,
} from '@/domains/pd-admin/types/administration/apps-management/app-management/visibility';
import { COMMA_SEPARATED_NUMBERS, POSITIVE_NUMBER } from '@/helpers/regex/numbers';
import { APP_STATUS } from './dropdownInfo';
import { getAppVisibilityDetails } from '@/domains/pd-admin/api/administration/apps-management/app-management/visibility';

const useVisibilityControl = (appID: number) => {
  const isLoading = ref(false);

  const stores = reactive<Record<string, StoreInstallDataInterface[]>>({
    list: [],
  });

  const appStatus = reactive<Record<string, { label: string; value: number }[]>>({
    options: APP_STATUS,
  });

  const visibilityControlState = reactive<VisibilityControlState>({
    storesIDs: '',
    instructions: '',
    appOrder: '',
    appStatus: {
      label: '',
      value: '',
    },
    adminNotes: '',
  });

  const rules = {
    storesIDs: {
      storesIDs: helpers.withMessage('Stores IDs are invalid', (value: string) => {
        return COMMA_SEPARATED_NUMBERS.test(value);
      }),
    },
    instructions: {},
    appOrder: {
      appOrder: helpers.withMessage('App order is invalid', (value: string) => {
        return POSITIVE_NUMBER.test(value);
      }),
    },
    appStatus: {
      label: {},
      value: {},
    },
    adminNotes: {
      required,
    },
  };

  const v$ = useVuelidate(rules, visibilityControlState);

  onBeforeMount(async () => {
    await fetchAndUpdateFields();
  });

  const fetchAndUpdateFields = async () => {
    isLoading.value = true;
    const response = await getAppVisibilityDetails(appID);
    if (!response['visibility-control']) {
      isLoading.value = false;
      return;
    }
    stores.list = [...response['visibility-control'].store_installs];
    visibilityControlState.instructions = response['visibility-control'].install_instructions ?? '';
    visibilityControlState.appOrder = `${response['visibility-control'].order}` ?? '';
    switch (response['visibility-control'].listing_status) {
      case Number(AppStatusDropdownValuesEnum.Published):
        visibilityControlState.appStatus.label = AppStatusDropdownEnum.Published;
        visibilityControlState.appStatus.value = `${AppStatusDropdownValuesEnum.Published}`;
        break;
      case Number(AppStatusDropdownValuesEnum.Unpublished):
        visibilityControlState.appStatus.label = AppStatusDropdownEnum.Unpublished;
        visibilityControlState.appStatus.value = `${AppStatusDropdownValuesEnum.Unpublished}`;
        break;
      case Number(AppStatusDropdownValuesEnum.Rejected):
        visibilityControlState.appStatus.label = AppStatusDropdownEnum.Rejected;
        visibilityControlState.appStatus.value = `${AppStatusDropdownValuesEnum.Rejected}`;
        break;
      default:
        visibilityControlState.appStatus.label = '';
        visibilityControlState.appStatus.value = '';
        break;
    }
    isLoading.value = false;
  };

  const onInputDataChange = (
    changedValue: Record<string, string>,
    dataProperty: VisibilityControlInputPropertiesEnum,
  ) => {
    v$.value[dataProperty].$touch();
    visibilityControlState[dataProperty] = changedValue.value;
  };

  const onSelectionDataChange = (
    changedValue: Record<string, string>,
    property: VisibilityControlSelectPropertiesEnum,
  ) => {
    v$.value[property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    visibilityControlState[property].label = selectedValue.label;
    visibilityControlState[property].value = selectedValue.value;
  };

  return {
    visibilityControlState,
    VisibilityControlInputPropertiesEnum,
    VisibilityControlSelectPropertiesEnum,
    v$,
    stores,
    appStatus,
    isLoading,
    onInputDataChange,
    onSelectionDataChange,
    fetchAndUpdateFields,
  };
};

export default useVisibilityControl;
