import Vue from 'vue';
import { ref, reactive, computed, onBeforeMount } from '@vue/composition-api';
import { updateShippingAppGeneralData } from '@/domains/pd-admin/api/administration/apps-management/app-details-shipping/update-general-app-data';
import useVuelidate from '@vuelidate/core';
import { email, helpers, maxLength, minLength, required } from '@vuelidate/validators';
import {
  servicesListType,
  ShippingAppDetailsRoutesEnum,
  ShippingAppGeneraDataInputsEnum,
  ShippingAppGeneralDataInterface,
} from '@/domains/pd-admin/types/administration/apps-management/app-details/shipping-app-details.type';
import {
  ARABIC_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS,
  ARABIC_TEXT_WITH_SPECIAL_CHARS,
  ENGLISH_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS,
  ENGLISH_TEXT_WITH_SPECIAL_CHARS,
} from '@/helpers/regex/text';
import { isValidURL } from '@/helpers/regex/URLs';
import { PHONE_NUMBER } from '@/helpers/regex/numbers';
import { COMPANY_TYPES } from '@/domains/shipping/helper/dropdownInfo';
import { SHIPPING_SERVICES } from '@/domains/shipping/helper/checkBoxesInfo';
import ShippingAppGeneralDataModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/ShippingAppGeneralDataModel';
import ErrorModel from '@/api/models/ErrorModel';

const useGeneralData = (appId: number, appGeneralData: any) => {
  const LOGO_MAX_WIDTH = 250;
  const LOGO_MAX_HEIGHT = 250;
  const rules = {
    appCompanyNameArabic: {
      required,
      appCompanyNameArabic: helpers.withMessage(
        'Company name is required in both English and Arabic',
        (value: string) => {
          return ENGLISH_TEXT_WITH_SPECIAL_CHARS.test(value) || ARABIC_TEXT_WITH_SPECIAL_CHARS.test(value);
        },
      ),
    },
    appCompanyNameEnglish: {
      required,
      appCompanyNameEnglish: helpers.withMessage(
        'Company name is required in both English and Arabic',
        (value: string) => {
          return ENGLISH_TEXT_WITH_SPECIAL_CHARS.test(value) || ARABIC_TEXT_WITH_SPECIAL_CHARS.test(value);
        },
      ),
    },
    companyURL: {
      required,
      companyURL: helpers.withMessage('Please enter a valid URL of Company.', (value: string) => {
        return isValidURL(value);
      }),
    },
    aboutCompanyArabic: {
      required,
      maxLength: maxLength(300),
      aboutCompanyArabic: helpers.withMessage(
        'Company description is required in both English and Arabic',
        (value: string) => {
          return ARABIC_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS.test(value);
        },
      ),
    },
    aboutCompanyEnglish: {
      required,
      maxLength: maxLength(300),
      aboutCompanyEnglish: helpers.withMessage(
        'Company description is required in both English and Arabic',
        (value: string) => {
          return ENGLISH_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS.test(value);
        },
      ),
    },
    typeOfCompany: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    companyLogo: {
      required,
    },
    mobileNumber: {
      required,
      mobileNumber: helpers.withMessage('Please enter a valid Mobile number', required),
      minLength: minLength(8),
      maxLength: maxLength(14),
      numberValue: (value: string) => {
        return PHONE_NUMBER.test(value);
      },
    },
    technicalSupportEmail: {
      required,
      email,
      technicalSupportEmail: helpers.withMessage('Please enter a valid email for Technical Support.', required),
    },
    services: {},
  };
  const isLogoValid = ref(true);
  const isValidCoverPhoto = ref(true);
  const logoUrl = ref(appGeneralData.companyLogo);
  const coverPhotoFileNameFromAPI = ref(appGeneralData.coverPhoto);
  const isUpdatingGeneralData = ref(false);
  const tempServicesArray = reactive<servicesListType>({ services: [] });
  const companyTypes = reactive({
    options: COMPANY_TYPES,
  });

  const companyServices = reactive({
    options: SHIPPING_SERVICES,
  });
  const shippingAppGeneralDataState = reactive<ShippingAppGeneralDataInterface>({
    appCompanyNameArabic: appGeneralData.appCompanyNameArabic ?? '',
    appCompanyNameEnglish: appGeneralData.appCompanyNameEnglish ?? '',
    companyURL: appGeneralData.companyURL ?? '',
    technicalSupportEmail: appGeneralData.technicalSupportEmail ?? '',
    mobileNumber: appGeneralData.mobileNumber ?? '',
    typeOfCompany: appGeneralData.typeOfCompany,
    aboutCompanyEnglish: appGeneralData.aboutCompanyEnglish ?? '',
    aboutCompanyArabic: appGeneralData.aboutCompanyArabic ?? '',
    companyLogo: appGeneralData.companyLogo ?? '',
    coverPhoto: appGeneralData.coverPhoto ?? '',
    services: appGeneralData.services ? appGeneralData.services.toString() : '',
  });

  const validator = useVuelidate(rules, shippingAppGeneralDataState);

  onBeforeMount(async () => {
    fillTempServicesArray();
  });

  const onInputDataChange = (changedValue: Record<string, string>, dataProperty: ShippingAppGeneraDataInputsEnum) => {
    validator.value[dataProperty].$touch();
    shippingAppGeneralDataState[dataProperty] = changedValue.value;
  };

  const onLogoSelection = (fileInputEvent: Event) => {
    const input = fileInputEvent.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }
    const appLogo = new Image();
    appLogo.src = URL.createObjectURL(input.files[0]);

    appLogo.onerror = () => {
      isLogoValid.value = false;
      return;
    };

    appLogo.onload = () => {
      if (appLogo.width !== appLogo.height || appLogo.width > LOGO_MAX_WIDTH || appLogo.height > LOGO_MAX_HEIGHT) {
        isLogoValid.value = false;
        return;
      }
      if (input.files) {
        shippingAppGeneralDataState.companyLogo = input.files[0];
        logoUrl.value = URL.createObjectURL(input.files[0]);
        validator.value.$touch();
        isLogoValid.value = true;
      }
    };
  };

  const onCoverPhotoSelection = (fileInputEvent: Event) => {
    const input = fileInputEvent.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }
    const coverPhoto = new Image();
    coverPhoto.src = URL.createObjectURL(input.files[0]);

    coverPhoto.onerror = () => {
      isValidCoverPhoto.value = false;
      return;
    };

    coverPhoto.onload = () => {
      if (Number((coverPhoto.width / coverPhoto.height).toFixed(1)) != 0.9) {
        isValidCoverPhoto.value = false;
        return;
      }
      if (input.files) {
        shippingAppGeneralDataState.coverPhoto = input.files[0];
        coverPhotoFileNameFromAPI.value = URL.createObjectURL(input.files[0]);
        validator.value.$touch();
        isValidCoverPhoto.value = true;
      }
    };
  };

  const onRemoveLogoClicked = () => {
    shippingAppGeneralDataState.companyLogo = null;
    logoUrl.value = '';
    validator.value.$touch();
  };

  const onRemoveCoverPhotoClicked = () => {
    shippingAppGeneralDataState.coverPhoto = null;
    coverPhotoFileNameFromAPI.value = '';
    validator.value.$touch();
  };

  const isCheckboxChecked = (label: string) => {
    return tempServicesArray.services.includes(label);
  };

  const onCheckBoxValueChange = (item: string) => {
    validator.value.services.$touch();
    if (tempServicesArray.services.indexOf(item) === -1) tempServicesArray.services.push(item);
    else tempServicesArray.services.splice(tempServicesArray.services.indexOf(item), 1);
    shippingAppGeneralDataState.services = tempServicesArray.services.toString();
  };

  const onUpdateGeneralData = async () => {
    isUpdatingGeneralData.value = true;
    const appGeneralDataInfo = new ShippingAppGeneralDataModel({
      app_id: appId,
      name_en: shippingAppGeneralDataState.appCompanyNameEnglish,
      name_ar: shippingAppGeneralDataState.appCompanyNameArabic,
      url: shippingAppGeneralDataState.companyURL,
      icon: shippingAppGeneralDataState.companyLogo instanceof File ? shippingAppGeneralDataState.companyLogo : null,
      coverPhoto:
        shippingAppGeneralDataState.coverPhoto instanceof File ? shippingAppGeneralDataState.coverPhoto : null,
      mobile: shippingAppGeneralDataState.mobileNumber,
      email: shippingAppGeneralDataState.technicalSupportEmail,
      shipping_type: shippingAppGeneralDataState.typeOfCompany.value,
      description_en: shippingAppGeneralDataState.aboutCompanyEnglish,
      description_ar: shippingAppGeneralDataState.aboutCompanyArabic,
      services: shippingAppGeneralDataState.services,
    });
    const response = await updateShippingAppGeneralData(appGeneralDataInfo);
    if (response instanceof ErrorModel || !response.payload) {
      Vue.$toast.error('Failed to update Application general data');
      isUpdatingGeneralData.value = false;
      return;
    }
    Vue.$toast.success('App General data updated successfully');
    isUpdatingGeneralData.value = false;
  };

  const fillTempServicesArray = () => {
    if (!shippingAppGeneralDataState.services) {
      return;
    }
    shippingAppGeneralDataState.services.split(',').forEach(function (item) {
      tempServicesArray.services.push(item);
    });
  };

  const showMessageForAboutArEnField = computed(() => {
    return (
      (validator.value.aboutCompanyEnglish.$dirty || validator.value.aboutCompanyArabic.$dirty) &&
      (validator.value.aboutCompanyArabic.$invalid || validator.value.aboutCompanyEnglish.$invalid)
    );
  });

  const showMessageForCompanyNameArEnField = computed(() => {
    return (
      (validator.value.appCompanyNameArabic.$dirty || validator.value.appCompanyNameEnglish.$dirty) &&
      (validator.value.appCompanyNameArabic.$invalid || validator.value.appCompanyNameEnglish.$invalid)
    );
  });

  return {
    validator,
    isLogoValid,
    isValidCoverPhoto,
    isUpdatingGeneralData,
    logoUrl,
    coverPhotoFileNameFromAPI,
    ShippingAppDetailsRoutesEnum,
    ShippingAppGeneraDataInputsEnum,
    companyTypes,
    companyServices,
    showMessageForCompanyNameArEnField,
    showMessageForAboutArEnField,
    tempServicesArray,
    onInputDataChange,
    onLogoSelection,
    onCoverPhotoSelection,
    onRemoveLogoClicked,
    onRemoveCoverPhotoClicked,
    isCheckboxChecked,
    onCheckBoxValueChange,
    onUpdateGeneralData,
  };
};

export default useGeneralData;
