import BaseModel from '@/api/models/BaseModel';
import CustomizationModel from './CustomizationModel';

class ShippingCustomizationsResponseModel extends BaseModel {
  public customizations: CustomizationModel[];

  constructor(arg: any) {
    super(arg);
    this.customizations = arg.customizations;
  }
}

export default ShippingCustomizationsResponseModel;
