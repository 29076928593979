import { defineComponent } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import {
  ZidButton,
  ZidIcon,
  ZidInput,
  ZidTextarea,
  ZidModal,
  ZidLoader,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import Multiselect from 'vue-multiselect';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import DeleteIcon from '@/assets/icons/applications/DeleteIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import useWebhooksManagement from '@/domains/pd-admin/helpers/administration/apps-management/app-details/shipping-app-details/useWebhooksManagement';

export default defineComponent({
  components: {
    BaseText,
    ZidButton,
    ZidIcon,
    ZidInput,
    ZidTextarea,
    ZidModal,
    ZidLoader,
    Multiselect,
    BaseIcon,
    DeleteIcon,
    EditIcon,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  props: {
    appId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    return {
      ...useWebhooksManagement(props.appId),
    };
  },
});
