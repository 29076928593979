import { defineComponent } from '@vue/composition-api';
import {
  ZidButton,
  ZidCheckbox,
  ZidFileSelector,
  ZidInput,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidTextarea,
  ZidLoader,
  ZidRadio,
  ZidModal,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import BaseText from '@/components/text/BaseText.vue';
import Multiselect from 'vue-multiselect';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import DeleteIcon from '@/assets/icons/applications/DeleteIcon.vue';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import useShippingOptions from '@/domains/pd-admin/helpers/administration/apps-management/app-details/shipping-app-details/useShippingOptions';

export default defineComponent({
  components: {
    ZidButton,
    ZidCheckbox,
    ZidFileSelector,
    ZidInput,
    ZidSelect,
    ZidSelectBody,
    ZidSelectHeader,
    ZidSelectOption,
    ZidTextarea,
    ZidLoader,
    ZidRadio,
    ZidModal,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
    BaseText,
    Multiselect,
    BaseIcon,
    SearchIcon,
    EditIcon,
    DeleteIcon,
    TabMultiLanguage,
  },
  props: {
    appId: {
      type: Number,
      required: true,
    },
    savedPricingModel: {
      type: Number,
      required: true,
    },
    contractLink: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return {
      ...useShippingOptions(props.appId, props.savedPricingModel, props.contractLink),
    };
  },
});
