import { helpers, required } from '@vuelidate/validators';
import { ARABIC_TEXT_WITH_SPECIAL_CHARS, ENGLISH_TEXT_WITH_SPECIAL_CHARS } from '@/helpers/regex/text';
import { computed, onBeforeMount, reactive, ref, watch } from '@vue/composition-api';
import {
  CustomizationDetailsType,
  CustomizationOptionLabelsEnum,
  PricingTypesEnum,
  SelectedPriceEnum,
  ShippingCitiesSelectionType,
  ShippingCountriesSelectionType,
  ShippingOptionDetailsInterface,
  ShippingOptionsInputPropertiesEnum,
  ShippingPricingModelEnum,
} from '@/domains/pd-admin/types/administration/apps-management/app-details/shipping-app-details.type';
import CustomizationModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/CustomizationModel';
import {
  SUPPORTED_CURRENCIES_FOR_CUSTOMIZATION,
  SUPPORTED_WEIGHTS_FOR_CUSTOMIZATION,
} from '@/domains/shipping/helper/dropdownInfo';
import useVuelidate from '@vuelidate/core';
import {
  getShippingCustomizationsCoverageCities,
  getShippingCustomizationsCoverageCountries,
} from '@/domains/pd-admin/api/administration/apps-management/app-details-shipping/get-shipping-customizations-coverage-locations';
import ErrorModel from '@/api/models/ErrorModel';
import Vue from 'vue';
import CoverageCountryModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/CoverageCountryModel';
import { changeShippingPricingModel } from '@/domains/pd-admin/api/administration/apps-management/app-details-shipping/change-shipping-pricing-model';
import {
  getShippingCustomizations,
  getSingleShippingCustomization,
} from '@/domains/pd-admin/api/administration/apps-management/app-details-shipping/get-shipping-customizations';
import { deleteSingleShippingCustomization } from '@/domains/pd-admin/api/administration/apps-management/app-details-shipping/delete-single-shipping-customization';
import { updateShippingOptionsCustomizations } from '@/domains/pd-admin/api/administration/apps-management/app-details-shipping/update-shipping-options-customizations';
import CoverageCityModel from '@/domains/pd-admin/models/administration/apps-management/app-details-shipping/CoverageCityModel';
import { updateShippingOptions } from '@/domains/pd-admin/api/administration/apps-management/app-details-shipping/update-shipping-options';
import { isValidURL } from '@/helpers/regex/URLs';
import BaseModel from '@/api/models/BaseModel';

const useShippingOptions = (appId: number, savedPricingModel: number, savedContractLink: string) => {
  const rules = {
    activationURL: {},
    customizationNameEn: {
      customizationNameEn: helpers.withMessage(
        'Customization name is required in both English & Arabic',
        (value: string) => {
          return ENGLISH_TEXT_WITH_SPECIAL_CHARS.test(value);
        },
      ),
    },
    customizationNameAr: {
      customizationNameAr: helpers.withMessage(
        'Customization name is required in both English & Arabic',
        (value: string) => {
          return ARABIC_TEXT_WITH_SPECIAL_CHARS.test(value);
        },
      ),
    },
    locations: {
      countries: {
        required,
      },
      cities: {
        required,
      },
    },
    shippingPriceType: {
      required,
    },
    cashOnDelivery: {},
    CodCost: {},
    shippingCost: {},
    baseWeight: {},
    baseWeightCost: {},
    additionalWeight: {},
    additionalWeightCost: {},
  };

  const pricingModel = ref(ShippingPricingModelEnum.RequestActivation);
  const selectedCODPricingType = ref('free');
  const selectedFixedShippingPricingType = ref('free');
  const contractLink = ref(savedContractLink);
  const showPricingModelChangeModal = ref(false);
  const showDeleteCustomizationModal = ref(false);
  const isLoadingChangePricingModel = ref(false);
  const isLoadingCustomizationsList = ref(false);
  const isLoadingDeleteCustomization = ref(false);
  const isAddNewCustomizationBtnDisable = ref(false);
  const isUpdatingShippingOptions = ref(false);
  const isSavingCustomization = ref(false);
  const selectedCustomizationId = ref();
  const showAddCustomizationForm = ref(false);
  const showAddCustomizationMsg = ref(true);
  const loadCustomizationDataInForm = ref(false);
  const isLoadingCustomizationCountries = ref(true);
  const isLoadingCustomizationCities = ref(false);

  const shippingOptionsFormState = reactive<ShippingOptionDetailsInterface>({
    activationURL: contractLink.value,
    customizationNameEn: '',
    customizationNameAr: '',
    locations: {
      countries: null,
      cities: null,
    },
    cashOnDelivery: false,
    shippingPriceType: '',
    CodCost: '0',
    shippingCost: '0',
    baseWeight: '0',
    baseWeightCost: '0',
    additionalWeight: '0',
    additionalWeightCost: '0',
  });

  const customizationList: Record<string, CustomizationModel[]> = reactive({ items: [] });

  const partnerCustomizationCountries = reactive<ShippingCountriesSelectionType>({
    selectAll: 'All',
    options: [],
  });

  const partnerCustomizationTempCities = reactive<ShippingCitiesSelectionType>({
    selectAll: 'All',
    options: [],
  });

  const partnerCustomizationCities = reactive<ShippingCitiesSelectionType>({
    selectAll: 'All',
    options: [],
  });

  const preSelectedCustomizationCountries = reactive<ShippingCountriesSelectionType>({
    selectAll: 'All',
    options: [],
  });

  const preSelectedCustomizationCities = reactive<ShippingCitiesSelectionType>({
    selectAll: 'All',
    options: [],
  });

  const currencies = reactive({
    options: SUPPORTED_CURRENCIES_FOR_CUSTOMIZATION,
  });

  const weightUnits = reactive({
    options: SUPPORTED_WEIGHTS_FOR_CUSTOMIZATION,
  });

  const validator = useVuelidate(rules, shippingOptionsFormState);

  onBeforeMount(async () => {
    if (savedPricingModel === 2) {
      pricingModel.value = ShippingPricingModelEnum.ActivateImmediately;
    }
    const response = await getShippingCustomizationsCoverageCountries(appId);
    if (response instanceof ErrorModel || !response.countries) {
      Vue.$toast.error('Failed to load Countries For Customizations');
      return;
    }
    partnerCustomizationCountries.options = [];
    const { countries } = response;
    isLoadingCustomizationCountries.value = true;
    if (countries.length > 0) {
      countries.forEach((country: CoverageCountryModel) => {
        partnerCustomizationCountries.options.push({
          label: `${country.name.en} - ${country.name.ar}`,
          value: country.id,
        });
      });
    } else Vue.$toast.error('No Country is available For Customizations');
    isLoadingCustomizationCountries.value = false;
    // customizations
    loadSavedCustomizations();
  });

  watch(
    () => shippingOptionsFormState.locations.countries,
    (countries) => {
      if (countries?.length !== 0) {
        getCitiesForSelectedCountries();
      } else {
        partnerCustomizationCities.options = [];
      }
    },
    { deep: true },
  );

  const onPricingMechanismChange = (e: Event) => {
    e.preventDefault();
    if (!shippingOptionsFormState.activationURL && customizationList.items.length === 0) {
      switchSelectedPricingMechanism();
      return;
    }
    togglePricingModalChangeVisibility();
  };

  const switchSelectedPricingMechanism = () => {
    if (pricingModel.value === ShippingPricingModelEnum.ActivateImmediately) {
      pricingModel.value = ShippingPricingModelEnum.RequestActivation;
    } else {
      pricingModel.value = ShippingPricingModelEnum.ActivateImmediately;
    }
  };

  const togglePricingModalChangeVisibility = () => {
    showPricingModelChangeModal.value = !showPricingModelChangeModal.value;
  };

  const onInputDataChange = (
    changedValue: Record<string, string>,
    dataProperty: ShippingOptionsInputPropertiesEnum,
  ) => {
    validator.value[dataProperty].$touch();
    shippingOptionsFormState[dataProperty] = changedValue.value;
  };

  const onChangePricingModelConfirmationClick = async () => {
    isLoadingChangePricingModel.value = true;
    shippingOptionsFormState.activationURL = '';
    const response = await changeShippingPricingModel(appId);
    if (response instanceof ErrorModel || !response.payload) {
      Vue.$toast.error('Failed to update application details');
      return;
    }
    customizationList.items = [];
    switchSelectedPricingMechanism();
    isLoadingChangePricingModel.value = false;
    // delete all previously selected configurations.
    togglePricingModalChangeVisibility();
  };

  const loadSavedCustomizations = async () => {
    isLoadingCustomizationsList.value = true;
    const customizationsResponse = await getShippingCustomizations(appId);
    if (customizationsResponse instanceof ErrorModel || !customizationsResponse.customizations) {
      Vue.$toast.error('Failed to load Customizations');
      return;
    }
    customizationList.items = [];
    customizationList.items = customizationsResponse.customizations;
    isLoadingCustomizationsList.value = false;
  };

  const onCheckBoxValueChange = () => {
    shippingOptionsFormState.cashOnDelivery = !shippingOptionsFormState.cashOnDelivery;
  };

  const onShippingPricingTypeRadioClick = (changedValue: Record<string, string>) => {
    shippingOptionsFormState.shippingPriceType = changedValue.value;
  };

  const onCODPricingTypeRadioClick = (changedValue: Record<string, string>) => {
    selectedCODPricingType.value = changedValue.value;
  };

  const onShippingFixedPricingRadioClick = (changedValue: Record<string, string>) => {
    selectedFixedShippingPricingType.value = changedValue.value;
  };

  const onResetCustomizationForm = () => {
    shippingOptionsFormState.customizationNameEn = '';
    shippingOptionsFormState.customizationNameAr = '';
    shippingOptionsFormState.locations.countries = null;
    shippingOptionsFormState.locations.cities = null;
    shippingOptionsFormState.cashOnDelivery = false;
    shippingOptionsFormState.shippingPriceType = '';
    shippingOptionsFormState.CodCost = '0';
    shippingOptionsFormState.shippingCost = '0';
    shippingOptionsFormState.baseWeight = '0';
    shippingOptionsFormState.baseWeightCost = '0';
    shippingOptionsFormState.additionalWeight = '0';
    shippingOptionsFormState.additionalWeightCost = '0';
    validator.value.$reset();
    // reset temp values
    selectedCustomizationId.value = null;
    preSelectedCustomizationCountries.options = [];
    preSelectedCustomizationCities.options = [];
    // allow user to add new customizations
    isAddNewCustomizationBtnDisable.value = false;
    toggleAddCustomizationForm();
  };

  const toggleAddCustomizationForm = () => (showAddCustomizationForm.value = !showAddCustomizationForm.value);

  const onEditCustomizationClick = async (customizationId: number) => {
    isAddNewCustomizationBtnDisable.value = true;
    showAddCustomizationForm.value = true;
    loadCustomizationDataInForm.value = true;
    selectedCustomizationId.value = customizationId;
    const response = await getSingleShippingCustomization(appId, customizationId);
    loadCustomizationDataInForm.value = false;
    if (response.customization) {
      preSelectedCustomizationCities.options = [];
      preSelectedCustomizationCountries.options = [];
      Object.entries(response.customization.countries).forEach((country: any) => {
        preSelectedCustomizationCountries.options.push({
          label: `${country[1].en} - ${country[1].ar}`,
          value: Number(country[0]),
        });
      });
      Object.entries(response.customization.cities).forEach((city: any) => {
        preSelectedCustomizationCities.options.push({
          label: `${city[1].name.en} - ${city[1].name.ar}`,
          value: Number(city[0]),
          country_id: Number(city[1].country_id),
        });
      });
      shippingOptionsFormState.customizationNameEn = response.customization.name.en;
      shippingOptionsFormState.customizationNameAr = response.customization.name.ar;
      shippingOptionsFormState.cashOnDelivery = response.customization.is_cod_allowed;
      shippingOptionsFormState.shippingPriceType = response.customization.pricing_type;
      shippingOptionsFormState.CodCost = response.customization.rate.cod_cost;
      shippingOptionsFormState.shippingCost = response.customization.rate.cost;
      shippingOptionsFormState.baseWeight = `${response.customization.rate.base_weight}`;
      shippingOptionsFormState.baseWeightCost = response.customization.rate.base_weight_cost;
      shippingOptionsFormState.additionalWeight = `${response.customization.rate.additional_weight}`;
      shippingOptionsFormState.additionalWeightCost = response.customization.rate.additional_weight_cost;
      response.customization.rate.cod_cost > 0
        ? (selectedCODPricingType.value = SelectedPriceEnum.Paid)
        : (selectedCODPricingType.value = SelectedPriceEnum.Free);
      response.customization.rate.cost > 0
        ? (selectedFixedShippingPricingType.value = SelectedPriceEnum.Paid)
        : (selectedFixedShippingPricingType.value = SelectedPriceEnum.Free);

      isLoadingCustomizationCountries.value = true;
      const preSelectedCountries:
        | {
            label: string;
            value: number | null;
          }[]
        | null = [];
      preSelectedCustomizationCountries.options.forEach((preSelectedPickupCountry) => {
        const country = partnerCustomizationCountries.options.find(
          (countryOption) => preSelectedPickupCountry.value === countryOption.value,
        );
        if (country) preSelectedCountries.push(country);
      });
      shippingOptionsFormState.locations.countries = [...preSelectedCountries];
      isLoadingCustomizationCountries.value = false;

      isLoadingCustomizationCities.value = true;
      const preSelectedCities: {
        label: string;
        value: number | null;
        country_id: number | null;
      }[] = [];

      preSelectedCustomizationCities.options.forEach((preSelectedCity) => {
        const city = partnerCustomizationCities.options.find(
          (cityOption) => cityOption.value === preSelectedCity.value,
        );
        if (city) preSelectedCities.push(city);
      });
      shippingOptionsFormState.locations.cities = [...preSelectedCities];
      isLoadingCustomizationCities.value = false;
    }
  };

  const onDeleteCustomizationClick = (customizationId: number) => {
    selectedCustomizationId.value = customizationId;
    toggleDeleteCustomizationModal();
  };

  const onDeleteSingleCustomizationConfirmationClicked = async () => {
    isLoadingDeleteCustomization.value = true;
    const response = await deleteSingleShippingCustomization(appId, selectedCustomizationId.value);
    if (response.status === 'success') {
      toggleDeleteCustomizationModal();
      // get all customizations.
      loadSavedCustomizations();
      // reset the selected customization after deletion
      selectedCustomizationId.value = null;
    }
    isLoadingDeleteCustomization.value = false;
  };

  const toggleDeleteCustomizationModal = () => {
    showDeleteCustomizationModal.value = !showDeleteCustomizationModal.value;
  };

  const onSaveCustomization = async () => {
    isSavingCustomization.value = true;
    const customizationsDetails: CustomizationDetailsType = {
      id: selectedCustomizationId.value,
      name: {
        ar: shippingOptionsFormState.customizationNameAr,
        en: shippingOptionsFormState.customizationNameEn,
      },
      cities:
        shippingOptionsFormState.locations.cities?.map((c) => {
          return c.value;
        }) ?? [],
      is_cod_allowed: shippingOptionsFormState.cashOnDelivery,
      pricing_type: shippingOptionsFormState.shippingPriceType,
      rate: {
        cod_cost: shippingOptionsFormState.CodCost,
      },
    };
    if (shippingOptionsFormState.shippingPriceType === PricingTypesEnum.Fixed) {
      Object.assign(customizationsDetails.rate, { cost: shippingOptionsFormState.shippingCost });
    } else if (shippingOptionsFormState.shippingPriceType === PricingTypesEnum.ByWeight) {
      Object.assign(customizationsDetails.rate, {
        base_weight: shippingOptionsFormState.baseWeight,
        base_weight_cost: shippingOptionsFormState.baseWeightCost,
        additional_weight: shippingOptionsFormState.additionalWeight,
        additional_weight_cost: shippingOptionsFormState.additionalWeightCost,
      });
    }
    const response = await updateShippingOptionsCustomizations(appId, customizationsDetails);
    if (response.status === 'success') {
      Vue.$toast.success(getSaveSuccessMessage(response));
      onResetCustomizationForm();
      loadSavedCustomizations();
    } else if (response instanceof ErrorModel) {
      Vue.$toast.error(getSaveErrorMsg(response));
    } else {
      Vue.$toast.error('Failed to save the customization');
    }
    isSavingCustomization.value = false;
  };

  const getSaveSuccessMessage = (response: BaseModel) => {
    let msg = 'Customization Added Successfully';
    if (typeof response.message === 'string' && response.message) {
      msg = response.message;
    } else if (typeof response.message !== 'string') {
      msg = response.message?.description ?? msg;
    }
    return msg;
  };

  const getSaveErrorMsg = (response: ErrorModel) => {
    let msg = 'Failed to create the customization';
    if (response.validations) {
      msg = response.validations[0].errors[0];
    } else if (typeof response.message === 'string' && response.message) {
      msg = response.message;
    }
    return msg;
  };

  const onSelectedCountryRemove = (removedCountry: { label: string; value: number }) => {
    let newCitiesAfterRemoval = null;
    if (shippingOptionsFormState.locations.countries) {
      isLoadingCustomizationCities.value = true;
      // remove cities
      if (shippingOptionsFormState.locations.cities) {
        newCitiesAfterRemoval = shippingOptionsFormState.locations.cities?.filter((c) => {
          if (Array.isArray(removedCountry)) {
            shippingOptionsFormState.locations.countries = [];
            return !removedCountry.some(({ value }) => value === c.country_id);
          } else {
            return c.country_id != removedCountry.value;
          }
        });
        shippingOptionsFormState.locations.cities = newCitiesAfterRemoval ?? null;
        preSelectedCustomizationCities.options = [];
        const newPreSelectedCitiesAfterRemoval = preSelectedCustomizationCities.options?.filter((c) => {
          if (Array.isArray(removedCountry)) {
            shippingOptionsFormState.locations.countries = [];
            return !removedCountry.some(({ value }) => value === c.country_id);
          } else {
            return c.country_id != removedCountry.value;
          }
        });
        preSelectedCustomizationCities.options = newPreSelectedCitiesAfterRemoval ?? [];
        partnerCustomizationCities.options = partnerCustomizationCities.options.filter((c) => {
          if (Array.isArray(removedCountry)) {
            return !removedCountry.some(({ value }) => value === c.country_id);
          } else {
            return c.country_id != removedCountry.value;
          }
        });
      }
      isLoadingCustomizationCities.value = false;
    }
  };

  const getCitiesForSelectedCountries = async () => {
    if (shippingOptionsFormState.locations.countries) {
      const countries = shippingOptionsFormState.locations.countries.map(({ value }) => value);
      const response = await getShippingCustomizationsCoverageCities(appId, countries);
      if (response instanceof ErrorModel || !response.cities) {
        Vue.$toast.error('Failed to load Cities For Selected Countries');
        return;
      }
      partnerCustomizationTempCities.options = [];
      const { cities } = response;
      if (cities.length > 0) {
        cities.forEach((city: CoverageCityModel) => {
          partnerCustomizationTempCities.options.push({
            label: `${city.name.en} - ${city.name.ar}`,
            value: city.id,
            country_id: city.country_id,
          });
        });
        partnerCustomizationCities.options = [...partnerCustomizationTempCities.options];

        isLoadingCustomizationCities.value = true;
        const preSelectedCities: {
          label: string;
          value: number | null;
          country_id: number | null;
        }[] = [];

        preSelectedCustomizationCities.options.forEach((preSelectedCity) => {
          const city = partnerCustomizationCities.options.find(
            (cityOption) => cityOption.value === preSelectedCity.value,
          );
          if (city) preSelectedCities.push(city);
        });
        shippingOptionsFormState.locations.cities = [...preSelectedCities];
        isLoadingCustomizationCities.value = false;
      }
    }
  };

  const onUpdateBtnClick = async () => {
    isUpdatingShippingOptions.value = true;
    const contractLink = shippingOptionsFormState.activationURL;
    const response = await updateShippingOptions(appId, contractLink);
    if (response instanceof ErrorModel || !response.payload) {
      Vue.$toast.error('Failed to Update Application Details');
      isUpdatingShippingOptions.value = false;
      return;
    }
    Vue.$toast.success('Application Details are Updated Successfully');
    isUpdatingShippingOptions.value = false;
  };

  const isAValidUrl = computed(() => {
    if (
      !customizationList.items.length &&
      !shippingOptionsFormState.activationURL &&
      pricingModel.value === ShippingPricingModelEnum.RequestActivation
    ) {
      return false;
    }
    return shippingOptionsFormState.activationURL ? isValidURL(shippingOptionsFormState.activationURL) : true;
  });

  const showMessageForCustomizationNameArEnField = computed(() => {
    return (
      (validator.value.customizationNameAr.$dirty || validator.value.customizationNameEn.$dirty) &&
      (validator.value.customizationNameAr.$invalid || validator.value.customizationNameEn.$invalid)
    );
  });

  const isSaveCustomizationBtnDisable = computed(() => {
    return validator.value.$invalid;
  });

  return {
    validator,
    ShippingPricingModelEnum,
    ShippingOptionsInputPropertiesEnum,
    CustomizationOptionLabelsEnum,
    SelectedPriceEnum,
    PricingTypesEnum,
    pricingModel,
    isAValidUrl,
    showMessageForCustomizationNameArEnField,
    showPricingModelChangeModal,
    showDeleteCustomizationModal,
    isLoadingChangePricingModel,
    isLoadingCustomizationsList,
    isLoadingDeleteCustomization,
    isAddNewCustomizationBtnDisable,
    showAddCustomizationForm,
    isLoadingCustomizationCountries,
    isLoadingCustomizationCities,
    showAddCustomizationMsg,
    loadCustomizationDataInForm,
    isSaveCustomizationBtnDisable,
    isUpdatingShippingOptions,
    isSavingCustomization,
    selectedCODPricingType,
    selectedFixedShippingPricingType,
    customizationList,
    shippingOptionsFormState,
    partnerCustomizationCountries,
    partnerCustomizationCities,
    currencies,
    weightUnits,
    onInputDataChange,
    onPricingMechanismChange,
    togglePricingModalChangeVisibility,
    toggleDeleteCustomizationModal,
    onChangePricingModelConfirmationClick,
    toggleAddCustomizationForm,
    onEditCustomizationClick,
    onDeleteCustomizationClick,
    onDeleteSingleCustomizationConfirmationClicked,
    onResetCustomizationForm,
    onCheckBoxValueChange,
    onSaveCustomization,
    onSelectedCountryRemove,
    onCODPricingTypeRadioClick,
    onShippingPricingTypeRadioClick,
    onShippingFixedPricingRadioClick,
    onUpdateBtnClick,
  };
};

export default useShippingOptions;
