import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import CreateShippingApplicationResponseModel from '@/domains/shipping/models/CreateShippingApplicationResponseModel';

const ENDPOINT_URL = '/market/admin/shipping/pricing-model';
export const changeShippingPricingModel = (
  appId: number,
): Promise<CreateShippingApplicationResponseModel | ErrorModel> => {
  return httpClient
    .put(`${ENDPOINT_URL}/${appId}`)
    .then((response) => new CreateShippingApplicationResponseModel(response))
    .catch((error) => error);
};
