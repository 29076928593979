import { ApplicationTypeProperty } from '@/api/models/ApplicationModel';

export interface OAuthAppStateInterface {
  appNameArabic: string;
  appNameEnglish: string;
  appURL: string;
  redirectURL: string;
  callbackURL: string;
  isEmbedded: boolean;
  appCategory: Record<string, string | number>;
  appLanguage: Record<string, string>;
  maintainerEmail: string;
  appType: string | ApplicationTypeProperty;
  description: string;
  descriptionArabic: string;
  shortDesciption: string;
  shortDesciptionArabic: string;
  developerName: string;
  developerNameArabic: string;
  videoURL: string;
  appScreenshotsAr: (string | File)[];
  appScreenshotsEn: (string | File)[];
  iconFile: File | string | null;
  coverPhoto: File | string | null;
  isHTMLEnabled: false;
  // oauth general settings
  clientID: string;
  clientSecret: string;
  scopesDescription: string;
}

export enum OAuthAppInputPropertiesEnum {
  AppNameArabic = 'appNameArabic',
  AppNameEnglish = 'appNameEnglish',
  AppURL = 'appURL',
  RedirectUrl = 'redirectURL',
  CallbackUrl = 'callbackURL',
  MaintainerEmail = 'maintainerEmail',
  ShortDesciption = 'shortDesciption',
  ShortDesciptionArabic = 'shortDesciptionArabic',
  VideoURL = 'videoURL',
  DeveloperName = 'developerName',
  DeveloperNameArabic = 'developerNameArabic',
  // oauth general settings
  ClientID = 'clientID',
  ClientSecret = 'clientSecret',
  ScopesDescription = 'scopesDescription',
}

export enum OAuthSelectPropertiesEnum {
  AppCategory = 'appCategory',
  AppLanguage = 'appLanguage',
}

export interface ScopesInfoInterface {
  scopes: number[];
  scopes_note: string;
}

export type WebhookResponse = {
  id: number | null;
  name: string;
  target_url: string;
  headers: string;
  conditions?: Record<string, string>;
  app_id: number;
};

export type WebhookGroup = {
  group_id: string | null;
  webhooks: WebhookType[];
};

export type WebhookType = {
  id: number | null;
  name: string;
  target_url: string;
  headers: string;
  conditions?: Record<string, string>;
};

export enum PlansInvoicePeriodEnum {
  monthly = 30,
  yearly = 365,
}
